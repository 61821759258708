const SaveModalIcon = () => (
    <svg
        width="72px"
        height="72px"
        viewBox="0 0 72 72"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-362.000000, -233.000000)">
            <g transform="translate(0.000000, 56.000000)">
                <g transform="translate(362.000000, 177.000000)">
                    <rect fill="var(--secondary)" opacity="0.3" x="0" y="8" width="64" height="64" rx="8" />
                    <rect fill="var(--secondary)" x="8" y="0" width="64" height="64" rx="8" />
                    <path
                        d="M28.5,47.95 C25.6333333,47.95 23.1666667,46.9166667 21.1,44.85 C19.0333333,42.7833333 18,40.3166667 18,37.45 C18,34.85 18.825,32.5583333 20.475,30.575 C22.125,28.5916667 24.25,27.4 26.85,27 C27.5166667,23.7666667 29.0833333,21.125 31.55,19.075 C34.0166667,17.025 36.8666667,16 40.1,16 C43.8666667,16 47.025,17.3583333 49.575,20.075 C52.125,22.7916667 53.4,26.05 53.4,29.85 L53.4,31.05 C55.8,30.9833333 57.8333333,31.7583333 59.5,33.375 C61.1666667,34.9916667 62,37.0333333 62,39.5 C62,41.8 61.1666667,43.7833333 59.5,45.45 C57.8333333,47.1166667 55.85,47.95 53.55,47.95 L41.5,47.95 C40.7,47.95 40,47.65 39.4,47.05 C38.8,46.45 38.5,45.75 38.5,44.95 L38.5,32.05 L34.35,36.2 L32.2,34.05 L40,26.25 L47.8,34.05 L45.65,36.2 L41.5,32.05 L41.5,44.95 C41.5,44.95 41.5,44.95 41.5,44.95 C41.5,44.95 41.5,44.95 41.5,44.95 L53.55,44.95 C55.05,44.95 56.3333333,44.4166667 57.4,43.35 C58.4666667,42.2833333 59,41 59,39.5 C59,38 58.4666667,36.7166667 57.4,35.65 C56.3333333,34.5833333 55.05,34.05 53.55,34.05 L50.4,34.05 L50.4,29.85 C50.4,26.8833333 49.3916667,24.3333333 47.375,22.2 C45.3583333,20.0666667 42.8666667,19 39.9,19 C36.9333333,19 34.4333333,20.0666667 32.4,22.2 C30.3666667,24.3333333 29.35,26.8833333 29.35,29.85 L28.4,29.85 C26.3333333,29.85 24.5833333,30.575 23.15,32.025 C21.7166667,33.475 21,35.2666667 21,37.4 C21,39.4666667 21.7333333,41.2416667 23.2,42.725 C24.6666667,44.2083333 26.4333333,44.95 28.5,44.95 L35.5,44.95 L35.5,47.95 L28.5,47.95 Z"
                        fill="rgb(var(--secondaryText))"
                        fill-rule="nonzero"
                    />
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default SaveModalIcon;