/////////////////////////////////////////////////////////////////////////////////
// Params
export const version = '3.30.8';

// force Dev JSON (to use Dev JSON in Prod Env like Preprod)
export const preprod = false;

// force to use Prod JSON in Preprod
export const forceProdJSON = false;

// if not empty, force the subdomain to be used (as client context, ex: test.yunow.app)
export const hostname = '';

// set the default project used in dev / test environment
export const hostnameDev = 'test.preprod.yunow.app'; // biin-import

// set the default app to use in dev / test environment
export const appIdDev = -1;

export const cipherSecret = process.env.PREACT_APP_CIPHER_SECRET;

/////////////////////////////////////////////////////////////////////////////////
// log version of the app
console.log('%c v' + version, 'color: #007f0c; font-weight: bold');

import { Text } from 'preact-i18n';

// set the global ENV
export const ENV = process.env.NODE_ENV; // development | production

// log ENV
export const getAPIEnvDomain = () => API_ENV[ENV];
(ENV !== 'production') && console.warn('ENV :', ENV);

// ENV definition for AWS
const AWS_ENV = {
	development: 'https://medias.yunow.ninja/published/',
	production: 'https://medias.yunow.io/published/'
};

// ENV definition for the API
const API_ENV = {
	development: 'https://api.yunow.ninja/',
	production: 'https://api.beta.yunow.io/' // REPLACE BY https://api.yunow.io/ when definitively in prod
};

// Set the URL for AWS fetch based on ENV
export const getAWSEnvUrl = () => AWS_ENV[ENV];

// Set the URL for Studio direct API calls based on ENV
export const getApiEnvUrl = () => API_ENV[ENV] + 'application/';

// use Preprod
if (preprod) {
	AWS_ENV.production = AWS_ENV.development; // force client using Dev Studio data
	API_ENV.production = API_ENV.development;
}

// Force getting Prod JSON
if (forceProdJSON) {
	AWS_ENV.development = AWS_ENV.production; // force client using Prod Studio data
	API_ENV.development = API_ENV.production;
}

/////////////////////////////////////////////////////////
// URL for getting JSON data for preview (or dev mode) //
/////////////////////////////////////////////////////////

/**
 * URL of the customer app
 * @param {String} type the type of element
 * @param {String} args name of the json file to get 'test.yunow.app.json' OR appId
 * @returns {String} the URL to get desired JSON data
 */
export const getFetchUrl = (type, arg) => {
	if (type === 'app') return getAWSEnvUrl() + arg;
	const base = getApiEnvUrl() + arg;
	if (type === 'api') return base;
	if (type === 'stats') return base + '/stats/';
	if (type === 'form') return base + '/content-form/';
	if (type === 'authenticationCode') return base + '/restreint/by-code/';
	if (type === 'game-info') return base + '/game-info/';
	if (type === 'authenticationMail') return base + '/restreint/by-email/';
	if (type === 'cart') return base + '/share/cart/';
	if (type === 'share-app') return base + '/share/app/';
	if (type === 'send-drawing') return base + '/send-drawing/';
	if (type === 'save-session') return base + '/save-session/';
	if (type === 'get-session') return base + '/get-session/';
	if (type === 'save-device-info') return base + '/stats/save-device-info/';
	if (type === 'log-game-score') return base + '/log-game-score/';
	if (type === 'content-preview') return base + '/content-preview/';
	if (type === 'upload-s3') return base + '/upload-s3/';
	if (type === 'first') return base + 'first/';
	// DON'T FORGET THE ENDING SLASH !!!!!!
};

/////////////////////////////////////////////////////////////////////////////////
export const postForm = (appId, sendAt, contentId, fields, blockId) => {
	const body = new URLSearchParams();
	body.append("contentId", contentId);
	body.append("sendAt", sendAt);
	body.append("fields", fields);
	body.append("blockId", blockId);
	
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body
	};
	return fetch(getFetchUrl('form', appId), requestOptions);
};

export const postStats = (appId, object, openedAt, openedFor, actionType) => {
	const body = {
		object,
		openedAt,
		openedFor,
		actionType
	};

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: objToUrlencodedString(body),
		keepalive: true
	};
	return fetch(getFetchUrl('stats', appId), requestOptions);
};

export const postCart = (appId, date, pageId, emails, content, freeField, senderField, signedUp, preview) => {
	const body = new URLSearchParams();
	body.append("date", date);
	body.append("pageId", pageId);
	body.append("emails", emails);
	body.append("content", content);
	body.append("freeField", freeField);
	body.append("sender", senderField);
	body.append("signedUp", signedUp);
	body.append("preview", preview);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body
	};
	return fetch(getFetchUrl('cart', appId), requestOptions);
};

export const postAuthenticationCode = (appId, authenticationCode) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: `code=${authenticationCode}`,
		keepalive: true
	};
	return fetch(getFetchUrl('authenticationCode', appId), requestOptions);
};

export const postAuthenticationMail = (appId, authenticationMail) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: `email=${authenticationMail}`,
		keepalive: true
	};
	return fetch(getFetchUrl('authenticationMail', appId), requestOptions);
};

export const postGameInfo = (appId, pageId) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: `page=${pageId}`,
		keepalive: true
	};
	return fetch(getFetchUrl('game-info', appId), requestOptions);
};

export const postDrawing = (appId, pageId, drawnImg, email, date) => {
	const body = new URLSearchParams();
	body.append("date", date);
	body.append("pageId", pageId);
	body.append("email", email);
	body.append("drawnImg", drawnImg);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body
	};
	return fetch(getFetchUrl('send-drawing', appId), requestOptions);
};

export const postGameSession = (appId, date, session, email) => {
	const body = new URLSearchParams();
	body.append("date", date);
	body.append("session", session);
	body.append("email", email);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body,
		keepalive: true
	};
	return fetch(getFetchUrl('save-session', appId), requestOptions);
};

export const getGameSession = (appId, code) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: `code=${code}`
	};
	return fetch(getFetchUrl('get-session', appId), requestOptions);
};

export const postDeviceInfo = (appId, deviceType, os, browser, language, newUser, returnUser) => {
	const body = new URLSearchParams();
	body.append("deviceType", deviceType.toLowerCase());
	body.append("os", os.toLowerCase());
	body.append("browser", browser.toLowerCase());
	body.append("language", language.toLowerCase());
	body.append("newUser", newUser);
	body.append("returnUser", returnUser);
	
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body
	};
	return fetch(getFetchUrl('save-device-info', appId), requestOptions);
};

export const postApp = (appId, pageId, date, emails, message, freeField, preview) => {
	const body = new URLSearchParams();
	body.append("pageId", pageId);
	body.append("date", date);
	body.append("emails", emails);
	body.append("message", message);
	body.append("freeField", freeField);
	body.append("preview", preview);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body
	};
	return fetch(getFetchUrl('share-app', appId), requestOptions);
};

export const postGameResults = (appId, pageId, score, medias) => {
	let pseudo = '';
    let avatar = {};
	let uniqueId;
	const storedGameSession = localStorage.getItem('gameSession');
	let gameSession = JSON.parse(storedGameSession) || {};
	if (!gameSession.finishedPages
		|| (gameSession.finishedPages && !gameSession.finishedPages.some(page => page.id === pageId))
		&& gameSession.pseudo
		&& gameSession.avatar
		&& gameSession.uniqueId
	) {
		pseudo = gameSession.pseudo;
		avatar.id = gameSession.avatar;
		uniqueId = gameSession.uniqueId;
		
		if (medias[avatar.id]) {
			avatar.src = medias[avatar.id].url;
		}
		const body = new URLSearchParams();
		body.append("pageId", pageId);
		body.append("score", score);
		body.append("uniqueId", uniqueId);
		body.append("pseudo", pseudo);
		body.append("avatar", avatar.src);
		body.append("date", Math.floor(Date.now() / 1000));
	
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body
		};
		return fetch(getFetchUrl('log-game-score', appId), requestOptions);
	}
	return Promise.resolve();
};

export const getContentPreviewJSON = (appId, contentId) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: `contentId=${contentId}`
	};
	return fetch(getFetchUrl('content-preview', appId), requestOptions);
};

export const postUploadS3 = (appId, directory, filename, content) => {
	const body = new URLSearchParams();
	body.append("directory", directory);
	body.append("filename", filename);
	body.append("content", content);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body
	};
	return fetch(getFetchUrl('upload-s3', appId), requestOptions);
};

/**
 * Replace ':' by '=' and ',' by '&' and remove char '{' and '}' "
 * @param {Object} obj object to be converted in urlencoded string
 * @returns {String} urlencoded string
 */
export const objToUrlencodedString = obj => JSON.stringify(obj).replace(/:/g, '=').replace(/,/g, '&').replace(/[{}"]/g, '');

/////////////////////////////////////////////////////////////////////////////////
// set menu object to make the link between country code in JSON and words in I18N files
export const menu = {
	fr: <Text id="menu.french" />,
	'fr-falc': <Text id="menu.french-falc" />,
	en: <Text id="menu.english" />,
	es: <Text id="menu.spanish" />,
	de: <Text id="menu.german" />,
	it: <Text id="menu.italian" />,
	nl: <Text id="menu.dutch" />,
	ru: <Text id="menu.russian" />,
	zh: <Text id="menu.chinese" />,
	ja: <Text id="menu.japanese" />,
	buc: <Text id="menu.kibushi" />
};

// list of font usable, with WebFontLoad param to download on Google Fonts
// https://fonts.google.com/
export const font = {
	montserrat: {
		family: 'Montserrat, arial, sans-serif',
		param: 'Montserrat:300,300i,400,400i,600,600i, sans-serif'
	},
	nunito: {
		family: 'Nunito, arial, sans-serif',
		param: 'Nunito:300,300i,400,400i,600,600i, sans-serif'
	},
	muli: {
		family: 'Muli, arial, sans-serif',
		param: 'Muli:300,300i,400,400i,600,600i, sans-serif'
	},
	firaSans: {
		family: 'Fira Sans, arial, sans-serif',
		param: 'Fira+Sans:300,300i,400,400i,600,600i, sans-serif'
	},
	ibmPlexMono: {
		family: 'IBM Plex Mono, courrier, monospace',
		param: 'IBM+Plex+Mono:300,300i,400,400i,600,600i, monospace'
	},
	ibmPlexSerif: {
		family: 'IBM Plex Serif, times, serif',
		param: 'IBM+Plex+Serif:300,300i,400,400i,600,600i, serif'
	},
	ubuntu: {
		family: 'Ubuntu, arial, sans-serif',
		param: 'Ubuntu:300,300i,400,400i,700,700i, sans-serif'
	},
	roboto: {
		family: 'Roboto, arial, sans-serif',
		param: 'Roboto:300,300i,400,400i,700,700i, sans-serif'
	},
	lora: {
		family: 'Lora, times, serif',
		param: 'Lora:400,400i,600,600i, serif'
	},
	kumbhSans: {
		family: 'Kumbh Sans, arial, sans-serif',
		param: 'Kumbh+Sans:300,400,700, sans-serif'
	},
	lato: {
		family: 'Lato, arial, sans-serif',
		param: 'Lato:300,300i,400,400i,700,700i, sans-serif'
	},
	yantramanav: {
		family: 'Yantramanav, arial, sans-serif',
		param: 'Yantramanav:300,300i,400,400i,700,700i, sans-serif'
	},
	inter: {
		family: 'Inter, arial, sans-serif',
		param: 'Inter:300,300i,400,400i,600,600i,700,700i, sans-serif'
	},
	poppins: {
		family: 'Poppins, arial, sans-serif',
		param: 'Poppins:300,300i,400,400i,600,600i,700,700i, sans-serif'
	},
	oswald: {
		family: 'Oswald, arial, sans-serif',
		param: 'Oswald:300,300i,400,400i,600,600i,700,700i, sans-serif'
	},
	playfairDisplay: {
		family: 'Playfair Display, arial, serif',
		param: 'Playfair+Display:400,400i,600,600i,700,700i, serif'
	},
	josefinSans: {
		family: 'Josefin Sans, arial, sans-serif',
		param: 'Josefin+Sans:300,300i,400,400i,600,600i,700,700i, sans-serif'
	},
	zillaSlab: {
		family: 'Zilla Slab, arial, serif',
		param: 'Zilla+Slab:300,300i,400,400i,600,600i,700,700i, serif'
	},
	majorMonoDisplay: {
		family: 'Major Mono Display, monospace',
		param: 'Major+Mono+Display:400,400i, monospace'
	},
	caveat: {
		family: 'Caveat, cursive',
		param: 'Caveat:400,400i,600,600i,700,700i, cursive'
	},
	permanentMarker: {
		family: 'Permanent Marker, cursive',
		param: 'Permanent+Marker:400,400i, cursive'
	},
	patrickHand: {
		family: 'Patrick Hand, cursive',
		param: 'Patrick+Hand:400,400i, cursive'
	},
	homemadeApple: {
		family: 'Homemade Apple, cursive',
		param: 'Homemade+Apple:400,400i, cursive'
	},
	shadowsIntoLight: {
		family: 'Shadows Into Light, cursive',
		param: 'Shadows+Into+Light:400,400i, cursive'
	}
};

export const customFonts = {
	louisVuitton: {
		family: 'Louis Vuitton, arial, sans-serif',
		param: 'Louis Vuitton:n3,o3,n4,o4,n5,o5,n6,o6,n7,o7, sans-serif'
	},
	gravity: {
		family: 'Gravity, arial, sans-serif',
		param: 'Gravity:n2,i2,n3,i3,n4,i4,n7,i7, sans-serif'
	},
	akkurat: {
		family: 'Akkurat, arial, sans-serif',
		param: 'Akkurat:n3,i3,n4,i4,n7,i7, sans-serif'
	}
};
