import { Component } from 'preact';
import { log } from 'Components/common';
import style from './style';
import icon from 'Components/font-icon';

export default class ZoomButton extends Component {
	componentDidMount() {
		log('init zoomButton');
	}

	render(props) {
		return (
			props.show &&
				<button class={`${style.zoomButton} ${icon.icon_fullscreen}`} onClick={props.click} />
		);
	}
}
