export const initOrbits = controls => {
	controls.rotateSpeed = 1.0;
	controls.zoomSpeed = 1.2;
	controls.panSpeed = 0.8;
};

export const fitCameraToObject = (THREE, camera, object, controls, forceMinZoom = false) => {
	const box = new THREE.Box3().setFromObject(object);
	const size = box.getSize(new THREE.Vector3()).length();
	const center = box.getCenter(new THREE.Vector3());

	controls.reset();

	object.position.x += (object.position.x - center.x);
	object.position.y += (object.position.y - center.y);
	object.position.z += (object.position.z - center.z);

	controls.maxDistance = size * 10;
	if (forceMinZoom) controls.minDistance = size / 3;
	camera.near = size / 100;
	camera.far = size * 100;
	camera.updateProjectionMatrix();

	camera.position.copy(center);
	camera.position.x += size / 2.0;
	camera.position.y += size / 5.0;
	camera.position.z += size / 2.0;
	camera.lookAt(center);

	const light1  = new THREE.AmbientLight(0xFFFFFF, .3);
	light1.name = 'ambient_light';
	camera.add(light1);

	const light2  = new THREE.DirectionalLight(0xFFFFFF, 1);
	light2.position.set(0.5, 0, 0.866); // ~60°
	light2.name = 'main_light';
	camera.add(light2);

	// define if it's a tiny object
	const isTiny = size < 0.5;

	// change camera fov for tiny object
	if (isTiny) {
		camera.fov = 15;
		// set the offset
		const offset = isTiny
		? 30
		: 5;

		// set fov and Z position
		const fov = camera.fov * (Math.PI / 180);
		let cameraZ = Math.abs(size / 4 * Math.tan(fov * 2));

		cameraZ *= offset; // zoom out a little so that objects don't fill the screen
		camera.position.z = cameraZ;

		const minZ = box.min.z;
		const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;
		camera.far = cameraToFarEdge * 3;
		camera.updateProjectionMatrix();
	}
};
