import { Component } from 'preact';
import { menu } from 'Components/settings';
import { log } from 'Components/common';
import CountryFlag from 'react-country-flag';
import CloseButton from 'Components/elements/buttons/close';
import style from './style';

export default class LanguageFlap extends Component {
	changeLang = lang => () => {
		this.props.setLang(lang)();
		this.props.toggleLangFlap();
	}

	componentDidMount() {
		log('init submenu language');
	}

	render(props) {
		return (
			<div class={props.isLangFlapOpen ? `${style.language} ${style.open}` : style.language}>
				<CloseButton style={style.close} action={props.toggleLangFlap} />
				<div class={style.wrapper}>
					<div class={style.innerWrapper}>
						{props.allLang.map(lang => (
							<button
								key={lang}
								class={props.lang === lang ? `${style.link} ${style.active}` : style.link}
								onClick={this.changeLang(lang)}
							>
								<div className={style.langContainer}>
									{lang === 'buc' && this.props.showLangFlags
										? <img src='/assets/images/BUC@2x.png' style={`width: 3rem; height: 2.2rem; margin-right: 1rem; outline: .1rem solid #ccc;`} />
										: this.props.showLangFlags &&
											<CountryFlag
												countryCode={lang === 'zh' ? 'CN' : lang === 'ja' ? 'JP' : (lang === 'en' || lang === 'en-falc') ? 'GB' : lang.slice(0,2).toUpperCase()}
												style={{
													width: '3rem',
													height: '2.2rem',
													'margin-right': '1rem',
													outline: '0.1rem solid #ccc'
												}}
												title={lang === 'zh' ? 'CN' : lang === 'ja' ? 'JP' : (lang === 'en' || lang === 'en-falc') ? 'GB' : lang.slice(0,2).toUpperCase()}
												svg
											/>
									}
									{this.props.showLangNames && <h2>{menu[lang]}</h2>}
								</div>
							</button>
						))}
					</div>
				</div>
			</div>
		);
	}
}
