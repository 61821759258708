const OpenModalIcon = () => (
    <svg
        width="72px"
        height="72px"
        viewBox="0 0 72 72"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-363.000000, -233.000000)">
                <g transform="translate(0.000000, 56.000000)">
                    <g transform="translate(363.000000, 177.000000)">
                        <rect fill="var(--secondary)" opacity="0.3" x="0" y="8" width="64" height="64" rx="8" />
                        <rect fill="var(--secondary)" x="8" y="0" width="64" height="64" rx="8" />
                        <path
                            d="M38.217417,17.037417 C37.4384914,17.8163426 37.4379262,19.0790561 38.2161541,19.8586787 L47.967417,29.627417 L47.967417,29.627417 L25.627417,29.627417 C24.5228475,29.627417 23.627417,30.5228475 23.627417,31.627417 C23.627417,32.7319865 24.5228475,33.627417 25.627417,33.627417 L47.967417,33.627417 L47.967417,33.627417 L38.2161541,43.3961553 C37.4379262,44.1757779 37.4384914,45.4384914 38.217417,46.217417 C38.9961385,46.9961385 40.2586955,46.9961385 41.037417,46.217417 L55.627417,31.627417 L55.627417,31.627417 L41.037417,17.037417 C40.2586955,16.2586955 38.9961385,16.2586955 38.217417,17.037417 Z"
                            fill="rgb(var(--secondaryText))"
                            fill-rule="nonzero"
                            transform="translate(39.627417, 31.627417) rotate(-45.000000) translate(-39.627417, -31.627417)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default OpenModalIcon;