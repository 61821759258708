import { Component } from 'preact';
import { menu } from 'Components/settings';
import { log, storeItem } from 'Components/common';
import CountryFlag from 'react-country-flag';
import style from './style';

export default class LanguagesList extends Component {

	changeLang = lang => () => {
		this.saveAppLanguage(this.props.appId, lang);
		if (this.props.lang === lang) {
			this.props.close();
		}
		else {
			this.props.setLang(lang)();
			// setTimeout(() => {
			// 	this.props.goTo(null);
			// }, 350);
		}
	}

	/**
	 * Save app id and lang into local storage for 24h of appLang popups
	 * @param {Object} appId application Id
	 */
	 saveAppLanguage = (appId, lang)  => {
		const now = Date.now();
		const oneDay = 24*60*60*1000;
		const storedAppLangPopups = localStorage.getItem('storedAppLangPopups');
		let appLangPopups;
		try {
			appLangPopups = JSON.parse(storedAppLangPopups) || {};
		}
		catch (e) {
			appLangPopups = {};
		}
		appLangPopups[appId] = {
			date: now+oneDay,
			language: lang
		};
		storeItem('local', 'storedAppLangPopups', appLangPopups);
	}

	componentDidMount() {
		log('init appIntro languages list');
	}

	render(props) {
		return (
			<div class={style.languages}>
				<div className={style.wrapper}>
					{props.allLang.map(lang => (
						<button
							key={lang}
							class={style.link}
							onClick={this.changeLang(lang)}
						>
							{lang === 'buc' && this.props.showLangFlags
								? <img src='/assets/images/BUC@2x.png' style={`border-radius: .2rem; width: 3.2rem; height: 2.4rem; margin: 0 .8rem 0 0; outline: .1rem solid #ccc; object-fit: unset;`} />
								: this.props.showLangFlags &&
									<CountryFlag
										countryCode={lang === 'zh' ? 'CN' : lang === 'ja' ? 'JP' : (lang === 'en' || lang === 'en-falc') ? 'GB' : lang.slice(0,2).toUpperCase()}
										style={{
											'border-radius': '.2rem', //TODORADIUS
											width: '3.2rem',
											height: '2.4rem',
											margin: '0 .8rem 0 0',
											outline: '0.1rem solid #ccc',
											'object-fit': 'unset'
										}}
										title={lang === 'zh' ? 'CN' : lang === 'ja' ? 'JP' : (lang === 'en' || lang === 'en-falc') ? 'GB' : lang.slice(0,2).toUpperCase()}
										svg
									/>
							}
							{this.props.showLangNames && <span>{menu[lang]}</span>}
						</button>
					))}
				</div>
			</div>
		);
	}
}
