import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { fitCameraToObject } from './setup';

const onLoad = (c, THREE, loader, dracoLoader) => gltf => {
	c.setState({ objectState: 'loaded' });

	fitCameraToObject(THREE, c.camera, gltf.scene, c.controls);

	c.scene.add(gltf.scene);
	c.renderer.outputColorSpace = THREE.SRGBColorSpace;

	c.setState({ showLoader: false });

	dracoLoader.dispose();
	dracoLoader = null;
	loader = null;
};

const onProgress = c => xhr => {
	const objectLoadingPerc = Math.round(xhr.loaded / xhr.total * 100);
	c.setState({ objectLoadingPerc });
};

const onError = err => {
	console.error('An error happened: ', err);
};

export const loadGLTF = (c, THREE, objectPath) => {
	// create object loader
	const loader = new GLTFLoader();
	
	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
	loader.setDRACOLoader(dracoLoader);
	dracoLoader.setCrossOrigin('anonymous');
	dracoLoader.preload();

	loader.load(
		objectPath,
		onLoad(c, THREE, loader, dracoLoader),
		onProgress(c),
		onError
	);
};