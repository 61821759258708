import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { log } from 'Components/common';
import ProgressBar from '../../progressBar';
import icon from 'Components/font-icon';
import style from './style';

export default class MenuModal extends Component {
	componentDidMount() {
		log('init game session menu modal');
	}

	render(props) {
		return (
			<div class={style.gameSessionMenu}>
				<div class={style.popup}>
					<header className={style.header}>
						{props.isMobile && <button class={`${style.return} ${icon.icon_arrowLeft}`} onClick={props.toggleMenuModal} type="button" />}
						<h2 className={style.title} onClick={props.toggleMenuModal}>
							{props.info.title
								? props.info.title
								: <Text id="gameSession.saveTitle" />
							}
						</h2>
						{!props.isMobile && <button class={`${style.close} ${icon.icon_close}`} onClick={props.toggleMenuModal} type="button" />}
					</header>
					{props.medias[props.info.media] &&
						<img className={style.media} src={props.medias[props.info.media].url} alt={(props.medias[props.info.media].alt && props.medias[props.info.media].alt[props.appInfo.lang])  || ''} />
					}
					<div className={`${style.wrapper} ${props.info.media ? '' : style.noMedia}`}>
						<div className={`${style.container} ${props.info.media ? '' : style.noMedia}`}>
							{props.appInfo.logo &&
								<div className={`${style.logoWrapper} ${props.info.media ? style.withMedia : ''}`}>
									<img src={props.appInfo.logo.url} alt={props.appInfo.logo.alt && props.appInfo.logo.alt[props.appInfo.lang]} />
								</div>
							}
							<h1 className={`${style.title} ${props.info.media ? '' : style.noMedia}`}>{props.appInfo.name}</h1>
							{props.percent !== Infinity && <ProgressBar color={'secondary'} percent={props.percent} />}
						</div>
						<div className={style.menu}>
							<button className={style.full} onClick={props.toggleNewModal}>
								<Text id="gameSession.new" />
							</button>
							<button className={style.full} onClick={props.toggleSaveModal}>
								<Text id="gameSession.save" />
							</button>
							<button className={style.empty} onClick={props.toggleOpenModal}>
								<Text id="gameSession.open" />
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
