import { Component } from 'preact';
import { log } from 'Components/common';
import style from './style';

export default class Snackbar extends Component {
	state = {
		show: false,
		fade: false
		// timeoutIds: {}
		// msg: ''
	}

	/**
	 * Hide snackbar in Timeout and activate Fade
	 */
	fadeOutIds = () => {
		const fadeId = setTimeout(() => {
			this.setState({ fade: false });
		}, 4010);

		const hideId = setTimeout(() => {
			this.setState({ show: false });
		}, 5010);

		return { hideId, fadeId };
	}

	/**
	 * Add Timeout Ids into state and launch fadeOut function
	 */
	addTimeoutIds = () => {
		this.setState({ timeoutIds: this.fadeOutIds() });
	}

	/**
	 * Show the snackbar
	 * @param {String} msg message to show in snackbar
	 */
	show = (msg, direction) => {
		this.setState({ msg, direction });

		if (!this.state.show) {
			this.setState({ show: true });
			this.addTimeoutIds();
			setTimeout(() => {
				this.setState({ fade: true });
			}, 10);
		}
		else {
			clearTimeout(this.state.timeoutIds.hideId);
			clearTimeout(this.state.timeoutIds.fadeId);
			// if show is true, fade has to be true too (prevent bug for showing when fading)
			if (this.state.fade === false) this.setState({ fade: true });
			this.addTimeoutIds();
		}
	}

	componentDidMount() {
		log('init snackbar');
	}

	render(_props, state) {
		return (
			state.show &&
			<div class={state.fade ? `${style.snackbar} ${style.show} ${state.direction ? style[state.direction] : ''}` : `${style.snackbar} ${state.direction ? style[state.direction] : ''}`}>{state.msg}</div>
		);
	}
}