const NewModalIcon = () => (
    <svg
        width="72px"
        height="72px"
        viewBox="0 0 72 72"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-362.000000, -233.000000)">
                <g transform="translate(0.000000, 56.000000)">
                    <g transform="translate(362.000000, 177.000000)">
                        <rect fill="var(--secondary)" opacity="0.3" x="0" y="8" width="64" height="64" rx="8" />
                        <rect fill="var(--secondary)" x="8" y="0" width="64" height="64" rx="8" />
                        <path
                            d="M34.5,48 L34.5,46 L44.1,46 L39.8,36.35 C39.7,36.15 39.625,35.9333333 39.575,35.7 C39.525,35.4666667 39.5,35.2333333 39.5,35 L39.5,30 L41.5,30 L41.5,35.3 L45.55,44.4 C45.6833333,44.6666667 45.7833333,44.925 45.85,45.175 C45.9166667,45.425 45.95,45.7 45.95,46 C45.95,46.6 45.7333333,47.0833333 45.3,47.45 C44.8666667,47.8166667 44.3333333,48 43.7,48 L34.5,48 Z M29.5,35 C28.4,35 27.4583333,34.6083333 26.675,33.825 C25.8916667,33.0416667 25.5,32.1 25.5,31 C25.5,29.9 25.8916667,28.9583333 26.675,28.175 C27.4583333,27.3916667 28.4,27 29.5,27 C30.6,27 31.5416667,27.3916667 32.325,28.175 C33.1083333,28.9583333 33.5,29.9 33.5,31 C33.5,32.1 33.1083333,33.0416667 32.325,33.825 C31.5416667,34.6083333 30.6,35 29.5,35 Z M51.5,35 C50.4,35 49.4583333,34.6083333 48.675,33.825 C47.8916667,33.0416667 47.5,32.1 47.5,31 C47.5,29.9 47.8916667,28.9583333 48.675,28.175 C49.4583333,27.3916667 50.4,27 51.5,27 C52.6,27 53.5416667,27.3916667 54.325,28.175 C55.1083333,28.9583333 55.5,29.9 55.5,31 C55.5,32.1 55.1083333,33.0416667 54.325,33.825 C53.5416667,34.6083333 52.6,35 51.5,35 Z M23.45,20.65 L22,19.25 C22.9333333,18.55 23.9583333,18 25.075,17.6 C26.1916667,17.2 27.3333333,17 28.5,17 C29.6666667,17 30.8083333,17.2 31.925,17.6 C33.0416667,18 34.0666667,18.55 35,19.25 L33.55,20.65 C32.7833333,20.1166667 31.9833333,19.7083333 31.15,19.425 C30.3166667,19.1416667 29.4333333,19 28.5,19 C27.5666667,19 26.6833333,19.1416667 25.85,19.425 C25.0166667,19.7083333 24.2166667,20.1166667 23.45,20.65 Z M47.45,20.65 L46,19.25 C46.9333333,18.55 47.9583333,18 49.075,17.6 C50.1916667,17.2 51.3333333,17 52.5,17 C53.6666667,17 54.8083333,17.2 55.925,17.6 C57.0416667,18 58.0666667,18.55 59,19.25 L57.55,20.65 C56.7833333,20.1166667 55.9833333,19.7083333 55.15,19.425 C54.3166667,19.1416667 53.4333333,19 52.5,19 C51.5666667,19 50.6833333,19.1416667 49.85,19.425 C49.0166667,19.7083333 48.2166667,20.1166667 47.45,20.65 Z"
                            fill="rgb(var(--secondaryText))"
                            fill-rule="nonzero"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default NewModalIcon;