import style from './style';

const PelletLoader = props => {
	if (!props.color) props.color = '#2D2D2D';

	return (
		<div class={style.pelletLoader}>
			<div style={`background-color: ${props.color}`} />
			<div style={`background-color: ${props.color}`} />
			<div style={`background-color: ${props.color}`} />
			<div style={`background-color: ${props.color}`} />
		</div>
	);
};

export default PelletLoader;
