import { Component } from 'preact';
import { log } from 'Components/common';
import 'react-step-progress-bar/styles.css';
import { ProgressBar } from 'react-step-progress-bar';
import style from './style';
import { Text } from 'preact-i18n';

export default class Progress extends Component {
	componentDidMount() {
		log('init game session progress bar');
	}

	render(props) {
		return (
			<div className={style.progressWrapper}>
				<div className={style.textWrapper} style={`color: rgb(var(--${props.color}));`}>
					<h2 className={style.text}>{`${props.percent}%`}</h2>
					<h3 className={`${style.text} ${style.transparent}`}>
						<Text id="gameSession.progress" plural={props.percent} />
					</h3>
				</div>
				<ProgressBar
					percent={props.percent}
					unfilledBackground={`rgba(var(--${props.color}), 0.3)`}
					filledBackground={`rgb(var(--${props.color}))`}
					height={8}
				/>
			</div>
		);
	}
}