import { Component } from 'preact';
import { log, isStudioPreview } from 'Components/common';
import MenuButton from 'Components/elements/ui/menu/button';
import MenuFlap from 'Components/elements/ui/menu/flap';

export default class MenuController extends Component {
	state = {
		isFlapOpen: false,
		isLangFlapOpen: false
	}

	/**
	 * Open/Close the flap
	 */
	toggleFlap = url => {
		if (isStudioPreview() && url) {
			window.parent.postMessage({
				event_id: 'yunow_url_change',
				data: {
					newurl: `${document.location.origin.replace(/\/$/, '')}${url}/${this.props.appInfo.id}`,
				}
			}, '*');
		}
		this.setState({
			isFlapOpen: !this.state.isFlapOpen,
			isLangFlapOpen: false
		});
	};

	toggleLangFlap = () => {
		this.setState({ isLangFlapOpen: !this.state.isLangFlapOpen });
	}

	componentDidMount() {
		log('init menu controller');
	}

	render(props, state) {
		return (
			<>
				<MenuButton
					appInfo={props.appInfo}
					medias={props.medias}
					toggleFlap={this.toggleFlap}
					onRefMenuButton={props.onRefMenuButton}
				/>
				<MenuFlap
					onRefGameSession={props.onRefGameSession}
					isFlapOpen={state.isFlapOpen}
					appInfo={props.appInfo}
					toggleFlap={this.toggleFlap}
					setLang={props.setLang}
					isLangFlapOpen={state.isLangFlapOpen}
					toggleLangFlap={this.toggleLangFlap}
					showModal={props.showModal}
					medias={props.medias}
					isMobile={props.isMobile}
					internetIsDisable={props.internetIsDisable}
					showSnackbar={props.showSnackbar}
					gameSessionPercent={props.gameSessionPercent}
					saveStateOfPage={props.saveStateOfPage}
					newGameSession={props.newGameSession}
					closeCreateModal={this.props.closeCreateModal}
				/>
			</>
		);
	}
}
