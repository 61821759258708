import { Component } from 'preact';
import { log, customRoute } from 'Components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './style';
import icon from 'Components/font-icon';

export default class MenuButton extends Component {

	setMenuInfo = () => {
		let menuObj = {
			customMenuIcon: null,
			style: `${style.menuIconButton} ${icon.icon_menu}`
		};
		if (this.props.appInfo.menu.type === 'home') {
			menuObj.style = `${style.menuIconButton} ${icon.icon_home}`;
		}
		else if (this.props.appInfo.menu.type === 'previous') {
			menuObj.style = `${style.menuIconButton} ${icon.icon_arrowLeft}`;
		}

		const customMedia = this.props.appInfo.menu.media;
		if (customMedia) {
			menuObj.customMenuIcon = this.props.medias[customMedia];
			menuObj.style = style.menuImgButton;
		}

		const customIcon = this.props.appInfo.menu.icon;
		if (customIcon) {
			menuObj.customMenuIcon = customIcon;
			menuObj.style = style.menuIconButton;
		}

		return menuObj;
	}

	triggerMenuAction = () => {
		if (this.props.appInfo.menu.type === 'home') customRoute(`/${this.props.appInfo.lang}`, this.props.appInfo.id);
		else if (this.props.appInfo.menu.type === 'previous') history.back();
		else this.props.toggleFlap();
	}

	componentDidMount() {
		log('init menu button');
		this.props.onRefMenuButton(this); // reference component for app lvl

	}

	render(props) {
		const menuInfo = props.appInfo.menu && this.setMenuInfo();
		return (
			props.appInfo.menu &&
			<div className={menuInfo.style} onClick={this.triggerMenuAction}>
				{menuInfo.customMenuIcon && menuInfo.customMenuIcon.url &&
					<img class={style.buttonIcon} src={menuInfo.customMenuIcon.url} alt={(menuInfo.customMenuIcon.alt && menuInfo.customMenuIcon.alt[props.appInfo.lang]) || ''} />
				}
				{menuInfo.customMenuIcon && !menuInfo.customMenuIcon.url &&
					<FontAwesomeIcon icon={menuInfo.customMenuIcon} className={style.icon} />
				}
			</div>
		);
	}
}
