import { Component } from 'preact';
import { log } from 'Components/common';
import AudioPlayer from 'react-h5-audio-player';

import 'react-h5-audio-player/src/styles.scss';
import icon from 'Components/font-icon';
import style from './style';

export default class Audio extends Component {
	state = {
		url: this.props.url,
		isPlaying: false
	}

	/**
	 * Pause all medias excepts the one to play
	 */
	play = ev => {
		ev.preventDefault();
		if (!this.player && !this.player.audio) return;
		
		const audios = document.getElementsByTagName('audio');
		for (let i = 0, len = audios.length; i < len; i++) {
			if (audios[i] !== ev.target) {
				audios[i].pause();
			}
		}
		const mapExplo = document.getElementById('map-explo');
		const videos = document.getElementsByTagName('video');
		for (let j = 0, len = videos.length; j < len; j++) {
			if (videos[j].id === 'background-video') {
				videos[j].muted = true;
			} else if (mapExplo) {
				const videoExplo = mapExplo.getElementsByTagName('video')[0];
				if (videoExplo) videoExplo.muted = true;
				else videos[j].pause();
			}
			else videos[j].pause();
		}
		this.setState({ isPlaying: true });
	}

	pause = () => {
		this.player && this.setState({ isPlaying: false });
	}

	playPause = () => {
		if (!this.player && !this.player.audio) return;

		if (this.player.audio.paused) {
			this.setState({ isPlaying: true });
			return this.player.audio.play();
		}
		this.setState({ isPlaying: false });
		return this.player.audio.pause();
	}

	componentDidMount() {
		log('init audio player');
	}

	componentDidUpdate(prevProps) {
		if (this.props.url !== prevProps.url) {
			/* eslint-disable */
			this.setState({
				url: this.props.url,
				isPlaying: false
			});
			/* eslint-enable */
		}
	}

	render(props, state) {
		const thumbnail = props.media.children && props.media.children[0] && props.media.children[0].url;
		return (
			state.url &&
			<div
				class={`JS_media ${props.style.media} ${style.sound}`}
				style={`background-image: url(${thumbnail})`}
				data-type="AUDIO"
				data-src={state.url}
				data-thumbnail={thumbnail}
				data-legend={props.media.legend && props.media.legend[props.lang]}
				data-alt={(props.media.alt && props.media.alt[props.lang]) || ''}
			>
				<div
					class={state.isPlaying ? `${style.overlay} ${style.play}` : style.overlay}
					onClick={this.playPause}
				>
					<div class={`${style.button} ${icon.icon_play}`} />
				</div>
				<AudioPlayer
					ref={c => (this.player = c)}
					className={!this.props.isMobile ? `${style.audio} ${style.desktop}` : style.audio}
					src={state.url}
					crossOrigin="anonymous"
					onPlay={this.play}
					onPause={this.pause}
					showJumpControls={false}
					showVolumeControl={!this.props.isMobile}
					showLoopControl={false}
					autoPlayAfterSrcChange={false}
					customIcons={{
						play: <div class={`${style.button} ${icon.icon_play}`} />,
						pause: <div class={`${style.button} ${icon.icon_pause}`} />
					}}
				/>
			</div>
		);
	}
}
