import { Text } from 'preact-i18n';
import style from './style';

export default function SessionName(props) {
    let pseudo = props.pseudo;
    let avatar = {
        id: props.avatar
    };
    if (!props.pseudo) {
        const storedGameSession = localStorage.getItem('gameSession');
		let gameSession = JSON.parse(storedGameSession) || {};
        if (gameSession.pseudo && gameSession.avatar) {
            pseudo = gameSession.pseudo;
            avatar.id = gameSession.avatar;
        }
    }

    if (props.medias[avatar.id]) {
        avatar.src = props.medias[avatar.id].url;
        avatar.alt = props.medias[avatar.id].alt && props.medias[avatar.id].alt[props.appInfo.lang] || '';
    }

    return (
        props.show &&
        <div className={`${style.sessionName} ${props.preview ? style.preview : ''}`} onClick={props.toggleMenuModal}>
            <div className={style.label}>
                <h3>{pseudo ? pseudo : <Text id="gameSession.modals.create.placeholder" />}</h3>
            </div>
            <div className={style.avatar}>
            {avatar.src &&
                <img src={avatar.src} alt={avatar.alt} />
            }
            </div>
        </div>
    );
}