import { Component, createRef } from 'preact';
import { log, isInViewport } from 'Components/common';
import { postUploadS3 } from 'Components/settings';
import { Text } from 'preact-i18n';
import icon from 'Components/font-icon';
import style from './style';
import Title from 'Components/elements/ui/title';
import FastShare from 'Components/elements/ui/modal/fastShare';
import MenuController from 'Components/menu/controller';
import BasketController from 'Components/basket/controller';
import LanguageController from 'Components/language/controller';
import AppTimer from 'Components/elements/ui/appTimer';
import TimeoutModal from 'Components/elements/ui/widgets/gameTimer/popup';

export default class TopBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTopbar: false,
			showMenu: this.props.appInfo.menu,
			showTitle: false,
			showShare: false,
			fastShare: null,
			pageId: null,
			title: '',
			showFastShareModal: false,
			fastShareLink: '',
			translate: 0
		}
		this.timeoutModalRef = createRef();
	}

	closeFastShare = () => {
		this.setState({ showFastShareModal: false, fastShareLink: '' });
	}

	openFastCart = () => {
		// Check from Studio if fastShare.currentState or not
		const currentState = this.props.saveStateOfPage(null, false);
		// 2. Send json to aws S3 : create uniqueID for file name / get s3Folder api value ('80/4f3edabc364f/')
		const newFileName = Date.now().toString(16) + Math.random().toString(16).slice(2);
		const url = `${window.location.origin}/${this.props.appInfo.lang}/${this.state.pageId}-${currentState.slug}/state-${newFileName}`;
		postUploadS3(this.props.appInfo.id, `${this.props.appInfo.s3Folder}/savedStates`, `${newFileName}.json`, JSON.stringify(currentState))
		.then(response => {
			if (response.status !== 201) throw new Error(response.stringify());
			if (navigator.share && this.state.fastShare.showNativeModal) {
				navigator.share({
					title: this.state.title,
					url
				});
			}
			else {
				this.setState({ showFastShareModal: true, fastShareLink: url });
			}
		})
		.catch(err => console.error(err));
	}

	setComponents = (showShare, share, fastShare, showTitle, title, pageId) => {
		const showTopbar = this.props.appInfo.menu
			|| this.props.appInfo.logo
			|| (showTitle && title !== '')
			|| showShare
			|| fastShare
			|| this.props.appInfo.accessibility
			|| this.props.appInfo.allLang.length > 1;
		this.setState({ showShare, share, fastShare, showTitle, title, pageId, showTopbar});
	}

	onShowAppTimeoutModal = () => {
		this.timeoutModalRef.current.show();
	}

	componentDidMount() {
		log('init topbar');
	}

	componentDidUpdate(_prevProps, prevState) {
		if (prevState.fastShare !== this.state.fastShare) {
			if (this.state.fastShare) {
				const tooltipEl = document.getElementsByClassName(style.tooltip)[0];
				const distance = tooltipEl ? isInViewport(tooltipEl) : true;
				const translate = distance === true ? 0 : distance.right - 8 - (window.innerWidth || document.documentElement.clientWidth);
				this.setState({ translate }); // eslint-disable-line
			}
		}
	}

	render(props, state) {
		return (
			state.showTopbar &&
			<div id="topbar" className={style.topBar}>
				<div className={style.container}>
					{state.showMenu &&
						<MenuController
							onRefGameSession={props.onRefGameSession}
							onRefMenuButton={props.onRefMenuButton}
							appInfo={props.appInfo}
							medias={props.medias}
							setLang={props.setLang}
							showModal={props.showModal}
							isMobile={props.isMobile}
							internetIsDisable={props.internetIsDisable}
							showSnackbar={props.showSnackbar}
							gameSessionPercent={props.gameSessionPercent}
							saveStateOfPage={props.saveStateOfPage}
							newGameSession={props.newGameSession}
							closeCreateModal={this.props.closeCreateModal}
						/>
					}
					{props.appInfo.logo &&
						<img
							className={style.logo}
							src={props.appInfo.logo.url}
							alt={(props.appInfo.logo.alt && props.appInfo.logo.alt[props.appInfo.lang]) || ''}
						/>
					}
				</div>
				<Title
					title={state.title}
					show={state.showTitle}
				/>
				<div className={style.container}>
					<BasketController
						onRefBasket={props.onRefBasket}
						basketCounter={props.basketCounter}
						cards={props.cards}
						appInfo={props.appInfo}
						internetIsDisable={props.internetIsDisable}
						setBasketCartState={props.setBasketCartState}
						isMobile={props.isMobile}
						isPreview={props.isPreview}
						showShare={state.showShare}
						share={state.share}
						pageId={state.pageId}
					/>
					{state.fastShare &&
						<button className={`${style.fastShare} ${icon.icon_share}`} onClick={this.openFastCart} type="button">
							<div className={style.tooltip} style={`transform: translate(calc(-50% - ${this.state.translate}px));`}>
								{state.fastShare.title
									? state.fastShare.title
									: <Text id="fastShare.title" />
								}
							</div>
						</button>
					}
					{state.fastShare && state.showFastShareModal &&
						<FastShare
							appInfo={props.appInfo}
							pageId={state.pageId}
							fastShare={state.fastShare}
							fastShareLink={state.fastShareLink}	
							isMobile={props.isMobile}
							isPreview={props.isPreview}
							internetIsDisable={props.internetIsDisable}
							toggleFastShare={this.closeFastShare}
						/>
					}
					{props.appInfo.accessibility && props.appInfo.accessibility.textsSize &&
						<div className={`${style.accessibility} ${icon.icon_letter}`} onClick={props.changeAppSize} />
					}
					{this.props.appInfo.appTimer &&
						<>
							<AppTimer
								time={this.props.appInfo.appTimer.time}
								appIntroClosed={this.props.appIntroClosed}
								createModalClosed={this.props.createModalClosed}
								showAppTimeoutModal={this.onShowAppTimeoutModal}
							/>
							<TimeoutModal
								ref={this.timeoutModalRef}
								appInfo={this.props.appInfo}
								gameTimer={this.props.appInfo.appTimer.time}
								medias={this.props.medias}
								modalInfo={this.props.appInfo.appTimer.modal}
								showModal={this.props.showModal}
								saveStateOfPage={this.props.saveStateOfPage}
							/>
						</>
					}
					
					{props.appInfo.allLang.length > 1 &&
						<LanguageController
							onRefLangController={props.onRefLangController}
							appInfo={props.appInfo}
							setLang={props.setLang}
						/>
					}
				</div>
			</div>
		);
	}
}