import { Component } from 'preact';
import { Localizer, Text, MarkupText } from 'preact-i18n';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { log, checkNDD } from 'Components/common';
import icon from 'Components/font-icon';
import style from './style';
import 'react-multi-email/dist/style.css';

export default class CartForm extends Component {
	getCachedMails = type => {
		let storedCachedMails;
		if (type === 'sender') storedCachedMails = localStorage.getItem('cachedSenderMails');
		else storedCachedMails = localStorage.getItem('cachedReceiverMails');
		let cachedMails;
		try {
			cachedMails = JSON.parse(storedCachedMails) || [];
		}
		catch (e) {
			cachedMails = [];
		}
		return cachedMails;
	}

	state = {
		emails: [],
		checked: false,
		isChecked: true,
		isValid: false,
		isWrongDomain: false,
		signedUpChecked: false,
		freeField: '',
		senderField: '',
		cachedSenderMails: this.getCachedMails('sender'),
		cachedReceiverMails: this.getCachedMails('receiver'),
		showSenderEmailsCache: false,
		showReceiverEmailsCache: false
	}

	/**
	 * Handle checkbox checked or not state
	 */
	toggle = () => {
		let checked = !this.state.checked;
		this.setState({ checked }, this.checkValid);
	}

	toggleSignedUp = () => {
		let signedUpChecked = !this.state.signedUpChecked;
		this.setState({ signedUpChecked }, this.checkValid);
	}

	resetEmail = () => {
		this.setState({ isValid : false, emails: [], freeField: '', senderField: '' });
	}

	checkValidSender = e => {
		const senderField = e ? e.target.value : this.state.senderField;
		this.setState({ senderField, showEmailsCache: true });
		this.props.setBasketContent(this.state.emails, this.state.freeField, senderField, this.state.signedUpChecked)();
		let isValid =
			this.state.emails.length > 0
			&& isEmail(senderField)
			&& this.state.emails.every(email => isEmail(email));
		if (this.props.share.restrictedDomains && isValid) {
			const isWrongDomain = this.state.emails.some(email => checkNDD(email, this.props.share.restrictedDomains));
			this.setState({ isWrongDomain });
		}
		if (this.props.share.storeMails && !this.state.checked) {
			this.setState({ isValid, isChecked: false });
		}
		else {
			this.setState({ isValid, isChecked: true });
		}
	}

	/**
	 * Check if email addresses are valid and checkbox is checked if mail storage activated
	 */
	checkValid = mails => {
		const emails = mails ? mails : this.state.emails;
		this.setState({ emails });
		this.props.setBasketContent(emails, this.state.freeField, this.state.senderField, this.state.signedUpChecked)();
		let isValid =
			emails.length > 0
			&& emails.every(email => isEmail(email))
			&& (!this.props.share.senderField || (this.props.share.senderField && isEmail(this.state.senderField)));
		if (this.props.share.restrictedDomains && isValid) {
			const isWrongDomain = emails.some(email => checkNDD(email, this.props.share.restrictedDomains));
			this.setState({ isWrongDomain });
		}

		if (this.props.share.storeMails && !this.state.checked) {
			this.setState({ isValid, isChecked: false });
		}
		else {
			this.setState({ isValid, isChecked: true });
		}
	}

	handleChangeFree = e => {
		this.setState({ freeField: e.target.value });
		this.props.setBasketContent(this.state.emails, e.target.value, this.state.senderField, this.state.signedUpChecked)();
	}

	cacheMails = () => {
		let cachedSenderMails = this.getCachedMails('sender');
		if (!cachedSenderMails.includes(this.state.senderField)) {
			cachedSenderMails.push(this.state.senderField);
			localStorage.setItem('cachedSenderMails', JSON.stringify(cachedSenderMails));
		}
		let cachedReceiverMails = this.getCachedMails('receiver');
		this.state.emails.map(mail => {
			if (!cachedReceiverMails.includes(mail)) {
				cachedReceiverMails.push(mail);
			}
		});
		localStorage.setItem('cachedReceiverMails', JSON.stringify(cachedReceiverMails));
	}

	handleSenderMailFromCache = mail => {
		this.setState({ senderField: mail, showSenderEmailsCache: false }, this.checkValidSender);
	}

	handleReceiverMailFromCache = mail => {
		let emails = [...this.state.emails];
		emails.push(mail);
		this.setState({ emails }, this.checkValid);
	}

	handleSenderFocus = () => {
		this.setState({ showSenderEmailsCache: true });
	}

	handleReceiverFocus = () => {
		this.setState({ showReceiverEmailsCache: true });
	}

	handleSenderBlur = () => {
		setTimeout(() => this.setState({ showSenderEmailsCache: false }), 150); // allows handleSenderMailFromCache call before ui el is hidden
	}

	handleReceiverBlur = () => {
		setTimeout(() => this.setState({ showReceiverEmailsCache: false }), 150); // allows handleReceiverMailFromCache call before ui el is hidden
	}

	submit = e => {
		this.cacheMails();
		this.props.sendBasket(e);
	}

	componentDidUpdate(prevProps) {
		if (this.props.isSent !== prevProps.isSent) {
			const cachedSenderMails = this.getCachedMails('sender');
			const cachedReceiverMails = this.getCachedMails('receiver');
			// eslint-disable-next-line
			this.setState({ checked: false, isChecked: true, cachedSenderMails, cachedReceiverMails });
			this.resetEmail();
		}
	}

	componentDidMount() {
		log('init CartForm');
	}

	render(props, state) {
		return (
			<div class={style.container}>
				<form onsubmit={this.submit}>
					{props.share.senderField &&
						<div className={style.flexContainer}>
							<div className={style.emailInput}>
								<Localizer>
									<input
										class={style.input}
										placeholder={<Text id="fastShare.from" />}
										type="email"
										pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
										onInput={this.checkValidSender}
										onFocus={this.handleSenderFocus}
										onBlur={this.handleSenderBlur}
										value={state.senderField}
										required
									/>
								</Localizer>
							</div>
							{state.showSenderEmailsCache && state.cachedSenderMails.length > 0 &&
								<div className={style.emailsCache}>
									<ul>
										{state.cachedSenderMails.map((mail, index) =>
											<li key={index} onClick={() => this.handleSenderMailFromCache(mail)}>{mail}</li>
										)}
									</ul>
								</div>
							}
						</div>
					}
					{props.share.freeField &&
						<div className={style.freeContainer}>
							<div className={`${style.freeField} ${style.higher}`} ref={r => this.freeInput = r}>
								<Localizer>
									<textarea
										class={style.input}
										type="text"
										onChange={this.handleChangeFree}
										value={state.freeField}
										placeholder={<Text id="basket.message" />}
									/>
								</Localizer>
							</div>
						</div>
					}
					<div className={style.flexContainer}>
						<div className={style.emailInput} ref={d => this.emailInput = d}>
							<Localizer>
								<ReactMultiEmail
									className={state.emails.length > 0 ? style.input : `${style.input} ${style.fullWidth}`}
									placeholder={<Text id="fastShare.to" />}
									emails={state.emails}
									onFocus={this.handleReceiverFocus}
									onBlur={this.handleReceiverBlur}
									onChange={(emails) => {
										this.checkValid(emails);
									}}
									validateEmail={email => {
										return isEmail(email);
									}}
									getLabel={(email, index, removeEmail) => {
										return (
											<div className={style.tag} data-tag key={index}>
												<span className={style.text}>{email}</span>
												<span className={`${style.icon} ${style.iconCloseTag} ${icon.icon_close}`} data-tag-handle onClick={() => removeEmail(index)} />
											</div>
										);
									}}
								/>
							</Localizer>
						</div>
						{state.showReceiverEmailsCache && state.cachedReceiverMails.length > 0 &&
								<div className={style.emailsCache}>
									<ul>
										{state.cachedReceiverMails.map((mail, index) =>
											<li key={index} onClick={() => this.handleReceiverMailFromCache(mail)}>{mail}</li>
										)}
									</ul>
								</div>
							}
					</div>
					{state.isWrongDomain &&
						<div class={`${style.error} ${icon.icon_error}`}>
							<Text id="basket.wrongDomain" />
						</div>
					}
					{props.share.storeMails &&
						<label class={style.checkContainer}>
							<input
								type="checkbox"
								checked={state.checked}
								onClick={this.toggle}
							/>
							<span class={style.checkmark} />
							<small class={style.text}>
								<MarkupText	id="authentication.mail.conditions"	/>
							</small>
						</label>
					}
					{props.share.signedUp &&
						<label class={`${style.checkContainer} ${style.second}`}>
							<input
								type="checkbox"
								checked={state.signedUpChecked}
								onClick={this.toggleSignedUp}
							/>
							<span class={style.checkmark} />
							<small class={style.text}>
								<MarkupText	id="basket.signUp" />
							</small>
						</label>
					}
					{((props.share.storeMails && !state.isChecked) || props.showErrorMsg) &&
						<div class={`${style.error} ${icon.icon_error}`}>
							{props.showErrorMsg
								? <MarkupText id="basket.error" />
								: <Text id="authentication.mail.notAccepted" />
							}
						</div>
					}
					<button
						class={style.submit}
						type="submit"
						value="submit"
						disabled={(props.internetIsDisable || props.sendPending || !state.isValid || !state.isChecked || state.isWrongDomain) && 'disabled'}
					>
						{props.sendPending
							? <div className={style.pending} />
							: !props.isSent
								? <Text id="basket.submit" />
								: <Text id="basket.success" />
						}
					</button>
				</form>
			</div>
		);
	}
}