import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { log } from 'Components/common';
import parse from 'html-react-parser';
import icon from 'Components/font-icon';
import NewModalIcon from 'Assets/icons/NewModalIcon';
import style from './style';

export default class NewModal extends Component {
	closeModal = () => {
		this.props.toggleNewModal();
		this.props.toggleMenuModal();
	}

	componentDidMount() {
		log('init game session NEW modal');
	}

	render(props) {
		return (
			<div className={style.newModal}>
				<div className={style.popup}>
					<header className={style.header}>
						<button class={`${style.return} ${icon.icon_arrowLeft}`} onClick={props.toggleNewModal} type="button" >
							<Text id="menu.back" />
						</button>
						{!props.isMobile && <button class={`${style.close} ${icon.icon_close}`} onClick={this.closeModal} type="button" />}
					</header>
					<div className={style.container}>
						<div className={style.media}>
							{props.medias[props.info.media]
								? <img src={props.medias[props.info.media].url} alt={(props.medias[props.info.media].alt && props.medias[props.info.media].alt[props.appInfo.lang]) || ''} />
								: <NewModalIcon />
							}
						</div>
						<div className={style.wrapper}>
							<h1 className={style.title}>{props.info.title ? props.info.title : <Text id="gameSession.modals.new.title" />}</h1>
							{props.info.description
								? parse(props.info.description)
								: <Text id="gameSession.modals.new.description" />
							}
						</div>
					</div>
					<button className={style.full} onClick={() => props.newSession(false)}>
						<Text id="gameSession.new" />
					</button>
				</div>
			</div>
		);
	}
}
