import { Component } from 'preact';
import { menu } from 'Components/settings';
import { Localizer } from 'preact-i18n';
import PlayIcon from 'Assets/icons/play';
import style from '../audio/style';

export default class Video extends Component {
	state = {
		duration: null,
		play: false
	}

	/**
	 * Pause all medias excepts the one to play
	 */
	play = () => {
		const audios = document.getElementsByTagName('audio');
		for (let i = 0, len = audios.length; i < len; i++) {
			audios[i].pause();
		}
		const mapExplo = document.getElementById('map-explo');
		const videos = document.getElementsByTagName('video');
		for (let j = 0, len = videos.length; j < len; j++) {
			if (videos[j] !== this.player) {
				if (videos[j].id === 'background-video') {
					videos[j].muted = true;
				} else if (mapExplo) {
					const videoExplo = mapExplo.getElementsByTagName('video')[0];
					if (videoExplo) videoExplo.muted = true;
					else videos[j].pause();
				}
				else videos[j].pause();
			}
		}
		this.player.play();
	}

	togglePlay = () => {
		if (!this.player) return;
		
		this.setState({ play: !this.state.play }, () => {
			this.state.play ? this.play() : this.player.pause();
		  });
	}


	pause = () => {
		this.player && this.player.pause();
		this.setState({ play: false });
	}
	
	componentWillUnmount() {
		this.pause();
		this.player.currentTime = 0;
	}

	render(props, state) {
		const thumbnail = props.media.children && props.media.children[0] && props.media.children[0].url !== '%2Fbundles%2Fbiinapi%2Fimg%2Fdefaut-sound-picture.jpg' ? props.media.children[0].url.replace('.webp', '') : '';
		return (
			props.url &&
			<div class={style.player}>
				<video
					poster={thumbnail}
					ref={ref => this.player = ref}
					crossorigin="anonymous"
					onEnded={this.pause}
					onPause={this.pause}
				>
					<source src={props.url} />
					{props.media.subtitles && props.media.subtitles[props.lang] &&
						<Localizer>
							<track label={menu[props.lang]} kind="subtitles" src={props.media.subtitles[props.lang]} srclang={props.lang} default />
						</Localizer>
					}
				</video>
				<div onClick={this.togglePlay} class={state.play ? `${style.overlay} ${style.play}` : style.overlay}>
					<div class={style.iconPlay}>
						<PlayIcon />
					</div>
				</div>
			</div>
		);
	}
}
