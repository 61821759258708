import { Component } from 'preact';
import { log, isInViewport } from 'Components/common';
import { Text } from 'preact-i18n';

import style from './style';
import icon from 'Components/font-icon';

export default class BasketButton extends Component {
	state = {
		translate: 0
	}

	componentDidMount() {
		log('init basket button component');
		const tooltipEl = document.getElementsByClassName(style.tooltip)[0];
		const distance = tooltipEl ? isInViewport(tooltipEl) : true;
		const translate = distance === true ? 0 : distance.right - 8 - (window.innerWidth || document.documentElement.clientWidth);
		this.setState({ translate }); // eslint-disable-line
	}

	render(props) {
		return (
			<div className={props.internetIsDisable ? `${style.basketButton} ${style.disable}` : style.basketButton} onClick={props.internetIsDisable ? void (0) : props.toggleBasket}>
				<div className={style.tooltip} style={`transform: translate(calc(-50% - ${this.state.translate}px));`}>
					{props.share && props.share.title
						? props.share.title
						: <Text id="tooltip.share" />
					}
				</div>
				<h2 className={style.counter}>{props.basketCounter}</h2>
				<div className={`${style.icon} ${icon.icon_save}`} />
			</div>
		);
	}
}
