import { globalVars } from 'Components/globalVars';
import Arrow from 'Components/elements/buttons/arrow';
import Media from 'Components/elements/ui/media';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from './style';

function getSliderSettings() {
    return {
        draggable: true,
        swipe: true,
        dots: true,
        infinite: false,
        arrows: true,
        nextArrow: <Arrow side="next" />,
        prevArrow: <Arrow side="prev" />,
        responsive: [{
            breakpoint: globalVars.breakpoint.md,
            settings: {
                arrows: false,
                draggable: false,
                swipe: true
            }
        }]
    }
}

export function MediaBlock(info) {

    const media = info.logo;
    const modal = info.modalType;
    if (modal && modal.medias.length > 0) {
        return (
            <Slick {...getSliderSettings()} className={`${style.slider} ${info.isMail ? style.mail : '' }`}>
            {modal.medias.map(media => (
                <Media key={media} media={media} lang={info.lang} />
            ))}
            </Slick>
        );
    }
    else if (media) {
        return <img className={style.logo} src={media.url} alt={(media.alt && media.alt[info.lang]) || ''} />;
    }
}
