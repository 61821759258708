import { Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import { log, db } from 'Components/common';
import * as basketController from 'Components/basket/controller';

import style from './style';
import icon from 'Components/font-icon';
import bgImage from 'Assets/images/media-empty.png';

export default class CartContent extends Component {
	/**
	 * Remove item from Db and props
	 * @param {object} db Dexie object
	 * @param {number} id id of the item
	 */
	remove = (db, id) => () => {
		basketController.removeItemFromBasket(db, id)
			.then(() => {
				this.props.setBasketCounter();
				this.props.setBasketCartState(false, id);
			})
			.catch(error => log(error));
	};

	componentDidMount() {
		log('init CartContent');
	}

	render(props) {
		return (
			<div className={style.container}>
				{props.cartCounter > 0
					? props.cards.sort((a, b) => a.timestamp - b.timestamp).map(card => (
						<div key={card.id} className={style.item}>
							<button class={`${style.remove} ${icon.icon_delete}`} onClick={this.remove(db(), card.id)} />
							<img
								className={`${style.img} ${card.title !== '' && style.withTitle}`}
								src={card.media}
								data-id={card.id}
								data-pageId={card.pageId}
								alt={card.alt || ''}
							/>
							{card.title !== '' && <h3 className={style.title}>{card.title}</h3>}
						</div>
					  ))
					: <div class={style.content}>
						<Localizer>
							<img src={bgImage} alt={<Text id="basket.empty" />} />
						</Localizer>
						<h3 class={style.text}>
							<Text id="basket.recorded_doc" />
						</h3>
					  </div>
				}
			</div>
		);
	}
}