import { Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import parse from 'html-react-parser';
import { postAuthenticationCode } from 'Components/settings';
import { log, storeItem } from 'Components/common';
import icon from 'Components/font-icon';
import { MediaBlock } from '../common';

import commonStyle from '../common/style';
import style from './style';

export default class AuthenticationCode extends Component {
	state = {
		show: true,
		authenticationCode: '',
		isLoading: false,
		hasError: false
	}

	input = null

	handleCodeChange = (event) => {
		this.setState({
			authenticationCode: event.target.value,
			hasError: false
		});
	}

	handleSubmit = (e) => {
		if (e) {
			e.preventDefault();
		}
		this.setState({
			isLoading: true
		});
		
		this
			.sendAuthenticationCode(this.state.authenticationCode)
			.then(() => {
				this.setState({
					show: false
				}, () => {
					this.saveAllowedApp();
					this.props.updateAuthentificationCheck("code");
				});
			}).catch(() => {
				this.setState({
					hasError: true
				});
			}).finally(() => {
				this.setState({
					isLoading: false
				});
			});

		if (process.env.NODE_ENV ==='development' && this.state.authenticationCode === '123456') {
			this.setState({
				show: false
			}, () => {
				this.saveAllowedApp();
				this.props.updateAuthentificationCheck("code");
			});
		}
	}

	sendAuthenticationCode = async (authenticationCode) => {
		try {
			const response = await postAuthenticationCode(this.props.appInfo.id, authenticationCode);
			if (response.status !== 200) {
				throw new Error(response.stringify());
			}
			return response;
		}
		catch (error) {
			return Promise.reject(error);
		}
	}
	
	isAppAllowed = ()  => {
		const storedApps = localStorage.getItem('authentication');
		try {
			const apps = JSON.parse(storedApps);
			if (!apps || !apps[this.props.appInfo.id]) {
				return false;
			}
			const now = Date.now();
			return apps[this.props.appInfo.id] >= now;
		}
		catch (e) {
			return false;
		}
	}

	saveAllowedApp = ()  => {
		const now = Date.now();
		const oneDay = 24*60*60*1000;
		const storedApps = localStorage.getItem('authentication');
		let apps;
		try {
			apps = JSON.parse(storedApps) || {};
		}
		catch (e) {
			apps = {};
		}
		apps[this.props.appInfo.id] = now+oneDay;
		storeItem('local', 'authentication', apps);
	}

	componentDidMount() {
		log('init authentication code popup');

		setTimeout(() => {
			if (this.input && this.input.current) {
				this.input.current.focus();
			}
		}, 500);

		if (this.isAppAllowed()) {
			// eslint-disable-next-line react/no-did-mount-set-state
			this.setState({
				show: false

			}, () => {
				this.props.updateAuthentificationCheck("code");
			});
		}
	}


	render(props, state) {
		const modal = props.appInfo.codeAuth.modal;
		return (
			state.show &&
			<div className={commonStyle.authentication}>
				<MediaBlock {...props.appInfo}  modalType={modal} />
				<div className={commonStyle.wrapper}>
					<h1 className={commonStyle.appName}>{props.appInfo.name}</h1>
					<h2 className={commonStyle.title}>
						{modal?.title.trim() !== ''
							? modal.title
							: <Text id="authentication.title" />
						}
					</h2>
					<p className={commonStyle.message}>
						{modal?.description.trim() !== ''
							? parse(modal.description)
							: <Text id="authentication.code.message" />
						}
					</p>
					<form onSubmit={this.handleSubmit} className={style.form}>
						<Localizer>
							<input
								className={style.passwordInput}
								ref={r => {
									this.input = r;
								}}
								type="password"
								value={state.authenticationCode}
								onKeyUp={this.handleCodeChange}
								autoFocus
								placeholder={<Text id="authentication.code.placeholder" />}
							/>
						</Localizer>
						{state.hasError && <div className={`${style.error} ${icon.icon_error}`}><Text id="authentication.code.error" /></div>}
						{state.isLoading
							? <div className={style.spinner} />
							: <button
								disabled={state.authenticationCode.length <= 0}
								type="submit"
								className={style.submit}
							>
								{modal?.button.trim() !== ''
									? modal.button
									: <Text id="authentication.submit" />
								}
							</button>
						}
					</form>
				</div>
			</div>
		);
	}
}
