import { Component } from 'preact';
import { globalVars } from 'Components/globalVars';
import { dynamicColor } from 'Components/common';
import style from './style';

export default class CustomButton extends Component {

	getButtonClass = () => {
		let classes = style.button;

		if (this.props.button.layout === 'standard' || this.props.button.layout === 'simple' || !this.props.button.layout) {
			if (this.props.button.layout === 'simple')	classes += ` ${style.simple}`;
			else classes += ` ${style.default}`;
			switch (this.props.button.iconAnchor) {
				case 'left': 
					classes += ` ${style.leftIcon}`;
					break;
				case 'right':
					classes += ` ${style.rightIcon}`;
					break;
				case 'top':
					classes += ` ${style.topIcon}`;
					break;
				default: break;
			}
		}
		else if (this.props.button.layout === 'image') {
			classes += ` ${style.image}`;
		}
		else if (this.props.button.layout === 'icon') {
			classes += ` ${style.soloIcon}`;
		}
		if (this.props.customStyle) classes += ` ${this.props.customStyle}`;

		if (document.getElementsByTagName('html')[0].style.fontSize === '93.75%') classes += ` ${style.unsetHeight}`
		return classes;
	}

	getColors = () => {
		let colors = {
			background: this.props.button.color || globalVars.color.titleColor,
			color: globalVars.color.titleDynamicColor
		};
		const color = colors.background || `rgba(${globalVars.color.titleDynamicColor},1)`;
		colors.color = dynamicColor(color);

		return colors;
	}

	render(props) {
		const dynamic = this.getButtonClass();
		const colors = this.getColors();
		const media = props.medias && props.button.media ? props.medias[props.button.media] : props.button.media;
		return (
			<button className={dynamic} style={`--background: ${colors.background}; --text: ${colors.color};`} onClick={props.action}>
				{(props.button.layout === 'standard' || props.button.layout === 'simple' || !props.button.layout) &&
					<>
						<h3>{props.button.text || props.button.title}</h3>
						{props.button.media && (props.button.iconAnchor !== 'none' || !props.button.iconAnchor) &&
							<img src={media.url} alt={(media.alt && media.alt[props.appInfo.lang]) || ''} />
						}
					</>
				}
				{(props.button.layout === 'image' || props.button.layout === 'icon') && props.button.media &&
					<img src={media.url} alt={(media.alt && media.alt[props.appInfo.lang]) || ''} />
				}
			</button>
		);
	}
}