const sceneRenderer = (renderer, labelRenderer, scene, camera) => {
	renderer.render(scene, camera);
	labelRenderer && labelRenderer.render(scene, camera);
};

const animate = (frameId, renderer, labelRenderer, scene, camera, controls) => () => {
	controls.update();
	sceneRenderer(renderer, labelRenderer, scene, camera, controls);
	frameId = window.requestAnimationFrame(animate(frameId, renderer, labelRenderer, scene, camera, controls));
};

export const start = (frameId, renderer, labelRenderer, scene, camera, controls) => {
	if (!frameId) {
		controls.update();
		frameId = requestAnimationFrame(animate(frameId, renderer, labelRenderer, scene, camera, controls));
	}
};

export const stop = (frameId, controls) => {
	cancelAnimationFrame(frameId);
	controls.dispose();
};