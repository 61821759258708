import {  Component } from 'preact';
import { log, db, isUrlAnImage, isUrlAnAudio, isUrlAVideo, getJsonData, getJsonName, getIdsFromPreviewUrl, isDevEnv,
	getDefaultMediaSrc, getMediaObject, saveBlobToIndexedDB } from 'Components/common';
import { Text } from 'preact-i18n';
import { version } from 'pdfjs-dist/build/pdf';
import pLimit from 'p-limit';

export default class Offline extends Component {
	state = {
		// pending: false,
		allMediasDownloaded: false,
		message: <Text id="popup.appIntro.download" />
	}

	/**
	 * Cache JSON for every languages of the app
	 */
	cacheLangJSON = (languages, currentLang) => languages.map(lang => lang !== currentLang && getJsonData('app', getJsonName(lang, false)));

	/**
	 * Download an image in cache
	 * @param {String} url url of the image
	 */
	cacheImage = url => new Promise(resolve => {
		const img = new Image();
		img.onload = () => resolve({ ok: true });
		img.onerror = () => {
			log('error with', url);
			resolve({ ok: false });
		};
		img.src = url;
	})

	/**
	 * Download a media in cache.
	 * @param {String} url url of the media
	 */
	cacheMedia = async url => {
		try {
			const res = await fetch(url);
			if (isUrlAnImage(url) === false) {
				const blob = await res.blob();
				const isAVideo = isUrlAVideo(url);
				const isAnAudio = isUrlAnAudio(url);
				if (isAVideo || isAnAudio) {
					const el = isAVideo ? document.createElement('video') : document.createElement('audio');
					el.src = URL.createObjectURL(blob);
				}
				return Promise.resolve({ ok: true });
			}
			return this.cacheImage(url);
		}
		catch (error) {
			log('Error for ', url, '.\n', error);
			return Promise.resolve({ ok: false });
		}
	}

	/**
	 * Use a "status object" and return if there is an error or not
	 * @param {Object} e {ok: true|false}
	 * @returns {Boolean} false|true
	 */
	isError = e => !e.ok

	/**
	 * Triggered after all the media are downloaded, if an error occured while the download
	 */
	errorOccured = () => {
		log('An error occured while downloading medias');
		this.setState({
			message: <Text id="popup.appIntro.tryagain" />,
			pending: false
		});
	}

	/**
	 * Triggered after all the media are downloaded, if no error occured while the download
	 */
	noErrorOccured = () => {
		log('All medias were downloaded correctly');
		this.setState({
			message: <Text id="popup.appIntro.downloaded" />,
			allMediasDownloaded: true
		});
	}

	/**
	 * Function to cache all medias of an app
	 * @returns {String} console ok or error
	 */
	cacheAllMedias = () => {
		const limit = pLimit(10);
		// get all medias URL of the app
		const allMediasUrls = [];
		const viewer360Pages = this.props.pages.filter(page => page.templateId === 1 && page.customization.layer && page.customization.layer.type === '360picture');
		viewer360Pages && viewer360Pages.map(page => {
			const mediaId = page.customization.layer && page.customization.layer.media;
			if (mediaId) {
				const urls = [`${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}?crossOrigin`, `${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}`];
				allMediasUrls.push(...urls);
				saveBlobToIndexedDB(db(), mediaId, urls[0]);
			}
		});

		// Specific cache for some templates because we need media without webp extension for html-to-image
		const matchPages = this.props.pages.filter(page => page.templateId === 4);
		matchPages && matchPages.map(page => {
			const mediaId = page.customization.layer && page.customization.layer.media;
			if (mediaId) {
				const urls = [`${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}?crossOrigin`, `${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}`];
				allMediasUrls.push(...urls);
			}

			page.zones.map(zone => {
				const zoneMediaObject = this.props.medias[zone.media];
				if (zoneMediaObject) allMediasUrls.push(getDefaultMediaSrc(zoneMediaObject).split('.webp')[0]);

				const resultMediaObject = zone.result && this.props.medias[zone.result.media];
				if (resultMediaObject) {
					allMediasUrls.push(getDefaultMediaSrc(resultMediaObject).split('.webp')[0]);
					const thumbnail = resultMediaObject.children && resultMediaObject.children[0] && resultMediaObject.children[0].url.split('.webp')[0];
					allMediasUrls.push(thumbnail);
				}
			});

			page.items.map(item => {
				const media = item && getMediaObject(item.blocs, item.headerImg, this.props.medias);
				if (media) {
					allMediasUrls.push(getDefaultMediaSrc(media).split('.webp')[0]);
				}
			});

		});

		const exploPages = this.props.pages.filter(page => page.templateId === 1);
		exploPages && exploPages.map(page => {
			const mediaId = page.customization.layer && page.customization.layer.media;
			if (mediaId) {
				const urls = [`${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}?crossOrigin`, `${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}`];
				allMediasUrls.push(...urls);
			}
		});

		const painterPages = this.props.pages.filter(page => page.templateId === 14);
		painterPages && painterPages.map(page => {
			const mediaId = page.customization.canvas && page.customization.canvas.background && page.customization.canvas.background.media;
			if (mediaId) {
				const urls = [`${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}?crossOrigin`, `${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}`];
				allMediasUrls.push(...urls);
				saveBlobToIndexedDB(db(), mediaId, urls[0]);
			}
		});

		const puzzlePages = this.props.pages.filter(page => page.templateId === 5);
		puzzlePages && puzzlePages.map(page => {
			const mediaId = page.customization.puzzleImg;
			if (mediaId) {
				const url = `${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}`;
				allMediasUrls.push(url);
				saveBlobToIndexedDB(db(), mediaId, url);
			}
		});

		const boardPages = this.props.pages.filter(page => page.templateId === 18);
		boardPages?.map(page => {
			page.components.appGallery?.map(mediaId => {
				const url = `${getDefaultMediaSrc(this.props.medias[mediaId]).split('.webp')[0]}`;
				allMediasUrls.push(url);
				saveBlobToIndexedDB(db(), mediaId, url);
			});
		});

		Object.values(this.props.medias).map(media => {
			allMediasUrls.push(media.url);
			if (media.children) {
				media.children.map(child => allMediasUrls.push(child.url));
			}
			if (media.subtitles) {
				this.props.languages.map(lang => {
					media.subtitles[lang] && allMediasUrls.push(media.subtitles[lang]);
				});
			}
		});
		const mediasUrls = [...new Set(allMediasUrls)]; // remove possible duplicates
		this.setState({ mediasCount: mediasUrls.length });

		const allPromisesArr = [];
		// download the media for each media url. This promise is stored in an array
		mediasUrls.map(url => {
			allPromisesArr.push(limit(() => this.cacheMedia(url)));
		});
		// wait until all promises are resolved
		Promise.all(allPromisesArr)
			.then(allStatus => {
				// check if there was an error in all download, and trigger errorOccured() if yes
				const isErrorOccured = allStatus.some(this.isError);
				if (isErrorOccured) return this.errorOccured();
				return this.noErrorOccured();
			});
	}

	/**
	 * Download all medias of the app
	 */
	downloadMedias = () => {
		this.setState({
			message: <Text id="popup.appIntro.downloading" />,
			pending: true
		});

		fetch(`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`);
		fetch(`https://www.gstatic.com/draco/versioned/decoders/1.5.6/draco_decoder.wasm`);
		fetch(`https://www.gstatic.com/draco/versioned/decoders/1.5.6/draco_wasm_wrapper.js`);
		this.cacheAllMedias();
	}

	componentDidMount() {
		log('init offline download');
		if (navigator.storage && navigator.storage.persist) {
			navigator.storage.persist().then(isPersisted =>	log(`Persisted storage granted: ${isPersisted}`));
		}
		this.downloadMedias();
		if (!(getIdsFromPreviewUrl() || isDevEnv()))
			this.cacheLangJSON(this.props.languages, this.props.currentLang);
	}

	render() {
		return (
			<div style={"display: none;"} />
		);
	}
}
