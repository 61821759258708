import { Component } from 'preact';
import { log, storeItem } from 'Components/common';
import Offline from 'Components/offline';
// import Media from 'Components/elements/ui/media';
// import parse from 'html-react-parser';
// import DownloadSvg from 'Assets/icons/Download';

export default class AppIntroOffline extends Component {

	/**
	 * Checks if modal id is present into local storage of offline modals
	 * @param {Number} modalId offline modal id
	 */
	isOfflineModalSaved = modalId  => {
		const storedOfflineModals = localStorage.getItem('storedOfflineModals');
		try {
			const offlineModals = JSON.parse(storedOfflineModals);
			if (!offlineModals || !offlineModals[modalId]) {
				return false;
			}
			const now = Date.now();
			return offlineModals[modalId] >= now;
		}
		catch (e) {
			return false;
		}
	}
	
	/**
	 * Save modal id into local storage for 24h of offline modals
	 * @param {Number} modalId offline modal id
	 */
	saveOfflineModal = modalId  => {
		const now = Date.now();
		const oneDay = 24*60*60*1000;
		const storedOfflineModals = localStorage.getItem('storedOfflineModals');
		let offlineModals;
		try {
			offlineModals = JSON.parse(storedOfflineModals) || {};
		}
		catch (e) {
			offlineModals = {};
		}
		offlineModals[modalId] = now+oneDay;
		storeItem('local', 'storedOfflineModals', offlineModals);
	}

	/**
	 * Set state to hide or show modal depending on JSON modal informations
	 * @param {Object} props parent props
	 */
	checkModalStatus = props => {
		if (props.modal.recurrent) { // always show if recurrent
			this.setState({
				show: true
			});
		}
		else if (this.isOfflineModalSaved(props.modal.id)) {
			this.setState({
				show: false
			});
			this.props.goToModal(this.props.index + 1)();
		}
		else {
			this.saveOfflineModal(props.modal.id);
			this.setState({
				show: true
			});
		}
	}

	state = {
		show: true //this.checkModalStatus(this.props)
	}

	componentDidMount() {
		log('init appIntro offline modal');
	}

	render(props, state) {
		return (
			state.show &&
			// <div class={props.style.content}>
			// 	{props.modal.media
			// 		? <Media media={props.modal.media} />
			// 		: <DownloadSvg />
			// 	}
			// 	<div class={props.style.title}>{props.modal.title}</div>
			// 	<div class={props.style.description}>{parse(props.modal.description)}</div>
			<Offline
				appId={props.appInfo.id}
				// buttons={props.modal.buttons}
				medias={props.medias}
				forceOffline={props.appInfo.forceOffline}
				goToModal={props.goToModal}
				index={props.index}
				style={props.style}
			/>
			// </div>
		);
	}

}