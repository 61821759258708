import { Component } from 'preact';
import { Localizer, Text, MarkupText } from 'preact-i18n';
import parse from 'html-react-parser';
import { postAuthenticationMail } from 'Components/settings';
import { log, validateEmail, storeItem } from 'Components/common';
import { MediaBlock } from '../common';
import icon from 'Components/font-icon';
import commonStyle from '../common/style';
import style from './style';

export default class AuthenticationMail extends Component {
	state = {
		show: true,
		checked: false,
		authenticationMail: '',
		isLoading: false,
		hasError: false,
		isChecked: true,
		isValid: true,
		rememberMe: false
	}

	input = null

	toggle = () => {
		let checked = !this.state.checked;
		this.setState({
			checked,
			isChecked: true
		});
	}

	toggleRememberMe = () => {
		let rememberMe = !this.state.rememberMe;
		this.setState({ rememberMe });
	}

	handleMailChange = e => {
		this.setState({
			authenticationMail: e.target.value,
			isValid: true
		});
	}

	handleSubmit = e => {
		if (e) {
			e.preventDefault();
		}
		const isEmailValid = validateEmail(this.state.authenticationMail);
		if (isEmailValid && this.state.checked) {
			this.setState({
				isLoading: true
			});

			this
				.sendAuthenticationMail(this.state.authenticationMail)
				.then(() => {
					this.setState({
						show: false

					}, () => {
						this.state.rememberMe && this.saveAllowedApp();
						this.props.updateAuthentificationCheck("email");
					});

				}).catch(() => {
					this.setState({
						hasError: true
					});
				}).finally(() => {
					this.setState({
						isLoading: false
					});
				});

			if (process.env.NODE_ENV === 'development' && this.state.authenticationMail === 'maxime@yunow.io') {
				this.setState({
					show: false
				}, () => {
					this.state.rememberMe && this.saveAllowedApp();
					this.props.updateAuthentificationCheck("email");
				});

			}
		}
		else if (!isEmailValid && !this.state.checked) {
			return this.setState({
				isValid: false,
				isChecked: false
			});
		}
		else if (!isEmailValid && this.state.checked) {
			return this.setState({
				isValid: false
			});
		}
		else {
			return this.setState({
				isChecked: false
			});
		}
		
	}

	sendAuthenticationMail = async (authenticationMail) => {
		try {
			const response = await postAuthenticationMail(this.props.appInfo.id, authenticationMail);
			if (response.status !== 200) {
				throw new Error(response.stringify());
			}
			return response;
		}
		catch (error) {
			return Promise.reject(error);
		}
	}

	isAppAllowed = ()  => {
		const storedApps = localStorage.getItem('authentication');
		try {
			const apps = JSON.parse(storedApps) || {};
			return !!apps[this.props.appInfo.id];
		}
		catch (e) {
			return false;
		}
	}

	saveAllowedApp = ()  => {
		const storedApps = localStorage.getItem('authentication');
		let apps;
		try {
			apps = JSON.parse(storedApps) || {};
		}
		catch (e) {
			apps = {};
		}
		apps[this.props.appInfo.id] = Date.now();
		storeItem('local', 'authentication', apps);
	}

	componentDidMount() {
		log('init authentication mail popup');

		if (this.isAppAllowed()) {
			// eslint-disable-next-line react/no-did-mount-set-state
			this.setState({
				show: false
			} , () => {this.props.updateAuthentificationCheck("email"); });

		}
	}

	render({ appInfo }, state) {
		const modal = appInfo.emailAuth.modal;
		return state.show &&
			(<div className={commonStyle.authentication}>
				<MediaBlock {...appInfo} isMail modalType={modal} />
				<div className={commonStyle.wrapper}>
					<h1 className={commonStyle.appName}>{appInfo.name}</h1>
					<h2 className={commonStyle.title}>
						{modal?.title.trim() !== ''
							? modal.title
							: <Text id="authentication.title" />
						}
					</h2>
					<p className={commonStyle.message}>
						{modal?.description.trim() !== ''
							? parse(modal.description)
							: <Text id="authentication.mail.message" />
						}
					</p>
					<form onSubmit={this.handleSubmit} className={style.form}>
						<Localizer>
							<input
								className={style.mailInput}
								ref={r => this.input = r}
								onInput={this.handleMailChange}
								value={state.authenticationMail}
								placeholder={<Text id="authentication.mail.placeholder" />}
							/>
						</Localizer>
						{!state.isValid &&
							<div className={`${style.error} ${icon.icon_error}`}>
								<Text id="authentication.mail.invalid" />
							</div>
						}
						<label className={style.container}>
							<input
								type="checkbox"
								checked={state.checked}
								onClick={this.toggle}
							/>
							<span className={style.checkmark} />
							<small className={style.text}>
								<MarkupText	id="authentication.mail.conditions" />
							</small>
						</label>
						{!state.isChecked &&
							<div className={`${style.error} ${icon.icon_error}`}>
								<Text id="authentication.mail.notAccepted" />
							</div>
						}
						<label className={`${style.container} ${style.rememberMe}`}>
							<input
								type="checkbox"
								checked={state.rememberMe}
								onClick={this.toggleRememberMe}
							/>
							<span className={style.checkmark} />
							<small className={style.text}>
								<MarkupText	id="authentication.mail.rememberMe" />
							</small>
						</label>
						{state.isLoading
							? <div className={style.spinner} />
							: <button
								type="submit"
								className={style.submit}
							>
								{modal?.button.trim() !== ''
									? modal.button
									: <Text id="authentication.submit" />
								}
							</button>
						}
						{state.hasError &&
							<div className={`${style.error} ${icon.icon_error}`}>
								<Text id="authentication.mail.error" />
							</div>
						}
					</form>
				</div>
			</div>);
	}

}
