import { isUrlAnImage, isUrlAVideo, isUrlAnAudio, isUrlAPDF } from 'Components/common';

import Image from './image';
import Video from './video';
import Audio from './audio';
import Pdf from 'Components/card/blocks/media/pdf';
import Three from 'Components/card/blocks/media/three';

import style from './style';

let MediaComponent = null;

const Media = props => {
	if (isUrlAnImage(props.media.url)) MediaComponent = Image;
	else if (isUrlAVideo(props.media.url)) MediaComponent = Video;
	else if (isUrlAnAudio(props.media.url)) MediaComponent = Audio;
	else if (isUrlAPDF(props.media.url)) MediaComponent = Pdf;
	else MediaComponent = Three;

	return (
		<MediaComponent
			{...props}
			url={props.media.url}
			style={style}
		/>
	);
};

export default Media;