import { Component } from 'preact';
import { log } from 'Components/common';
import style from './style';
import icon from 'Components/font-icon';

export default class Arrow extends Component {

	componentDidMount() {
		log('init arrow');
	}

	render(props) {
		const dynamicStyle = props.customStyle ? props.customStyle : style;

		const side = props.side === 'next'
			? `${dynamicStyle.arrow_next} ${icon.icon_arrowRight}`
			: `${dynamicStyle.arrow_prev} ${icon.icon_arrowLeft}`;

		const isDynamic = props.isDynamic
			? `${dynamicStyle.arrow_dynamic}`
			: '';

		return (
			<span class={dynamicStyle.container}>
				<div class={`${props.className} ${dynamicStyle.arrow} ${side} ${isDynamic}`} onClick={props.onClick} />
			</span>
		);
	}
}
