import PelletLoader from 'Components/elements/pelletLoader';

import style from './style';
import { Text } from 'preact-i18n';

const ScreenLoader = props =>
	(props.show &&
		<div class={props.emptyTopbar ? `${style.screenloader} ${style.noTopbar}` : style.screenloader}>
			{props.loading
				? <h2 className={style.loading}>
					{props.loading}<Text id="app.completionPercent" />
				  </h2>
				: <PelletLoader />
			}
		</div>
	)
;

export default ScreenLoader;
