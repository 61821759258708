import * as settings from 'Components/settings';
import { globalVars } from 'Components/globalVars';
import defaultImg from 'Assets/images/default-image.png';
import Dexie from 'dexie';
import { route } from 'preact-router';
import { Text } from 'preact-i18n';

// Return true if it's a Dev Env
export const isDevEnv = () => settings.ENV !== 'production' || settings.preprod;

export const getDevAppId = () => settings.appIdDev;

export const getDynamicManifestURL = () => {
	let hostname = window.location.hostname;
	if (settings.hostname !== '') hostname = settings.hostname;
	if (settings.ENV === 'development')	hostname = settings.hostnameDev;
	return settings.getAPIEnvDomain() + 'manifest/' + hostname + '/manifest.json';
};

export const getDynamicFaviconURL = isIOS => {
	let hostname = window.location.hostname;
	if (settings.hostname !== '') hostname = settings.hostname;
	if (settings.ENV === 'development')	hostname = settings.hostnameDev;
	return `${settings.getAPIEnvDomain()}favicon/${hostname}/${isIOS ? '180x180' : '48x48'}/favicon-${isIOS ? 'png' : 'ico'}?v=${Date.now()}`;
};

export const log = message => isDevEnv() && console.log(message);
// export const log = (message, type = 'log') => {
// 	(type === 'log' && isDevEnv()) && console.log(message);
// 	type === 'error' && console.error(message);
// 	type === 'warn' && console.warn(message);
// }

/**
 * Remove a specific element in a specific array. If Value is an Object, set the Prop to use to find the element.
 * @param {Array} array
 * @param {Any} value
 * @param {String} prop Any property of the object (if Value is an Object) : used to find the element
 */
export const removeFromArray = (array, value, prop) => {
	const index = (typeof value === 'object')
		? array.findIndex(el => el[prop] === value[prop])
		: array.indexOf(value);
	if (index > -1) array.splice(index, 1);
	return array;
};

/**
 * Count how many elements there is in an array
 * @param {Array} array
 * @param {Object} el
 * @param {String} elProp get the prop of the element if it's an Object
 * @returns {Number} the number of elements
 */
export const countInArray = (array, el, elProp) => {
	let counts = {};
	for (let i = 0; i < array.length; i++) {
		const prop = elProp ? array[i][elProp] : array[i];
		counts[prop] = (counts[prop] || 0) + 1;
	}
	return counts[el];
};

/**
 * Return an Array Index according to the attribute's value of a object inside the array
 * @param {Array} array the array to sort
 * @param {String} attribute the attribute to use to find the object
 * @param {Any} value value of the attribute
 */
export const getObjectIndexFromArray = (array, attribute, value) => array.findIndex(element => element[attribute] === value);

/**
 * Return an Object from an array according to the attribute's value
 * @param {Array} array the array to sort
 * @param {String} attribute the attribute to use to find the object
 * @param {Any} value value of the attribute
 */
export const getObjectFromArray = (array, attribute, value) => array[getObjectIndexFromArray(array, attribute, value)];

/**
 * Shuffle/randomize the content of an array
 * @param {Array} array array to shuffle
 */
export const shuffleArray = array => {
	let indexArray = [];
	let shuffled = [];

	for (let i = 0; i < array.length; i++) {
		indexArray.push(i);
	}
	const rdmIndexArray = indexArray.sort(() => Math.random() - 0.5);

	rdmIndexArray.map(el => shuffled.push(array[el]));
	return shuffled;
};

/**
 * Get JSON of app
 * @param arg argument to get desired element (app json or medias json)
 */
export const getJsonData = async (type, arg) => {
	log('init getJsonData()')

	try {
		const response = await fetch(settings.getFetchUrl(type, arg));
		const json = await response.json();
		if (json.error) return Promise.reject(Error(json.message));
		isDevEnv() && console.log('%c ' + type + ' json ', 'background: #808080; color: white; font-weight: bold', json);
		return json;
	}
	catch (error) {
		return Promise.reject(error);
	}
};

/**
 * Get the json name (with file extension) from hostname in desired language
 * @param lang the desired language we want the data to be
 * @param isMedia choose to get media json or not
 * @returns {String} the json name as stored in AWS S3 bucket
 */
export const getJsonName = (lang = '', isMedia = false, isFirst = false) => {
	let jsonName = window.location.hostname;
	const langStr = lang ? '.' + lang.toLowerCase() : '';
	const first = isFirst && !isMedia  ? '.first' : '';
	const jsonStr = isMedia ? '.medias.json' : '.json';
	if (settings.hostname !== '') return settings.hostname + langStr + jsonStr;
	if (settings.ENV === 'development')	return settings.hostnameDev + langStr + jsonStr;
	return jsonName + langStr + first + jsonStr;
};

/**
 * Declare YunowDB, a Dexie IndexedDB database
 * @returns {object} the Dexie db object
 */
export const db = () => {
	const db = new Dexie('YunowDB');
	db.version(4).stores({
		card: 'id,screenId,title,media,timestamp',
		stats: '++id,appId,objectId,openedAt,openedFor,actionType,sent',
		blobs: 'id,blob'
	});
	return db;
};

const getDataToSend = async () => {
	const dataToSend = await db().stats.where('sent').equals(0).toArray();
	dataToSend.map(async data => {
		const result = pushStatsToStudio(data.appId, data.objectId, data.openedAt, data.openedFor, data.actionType, false);
		result && await db().stats.update(data.id, { sent: 1 });
	});
};

export const checkOfflineDataToBeSent = async () => {
	if (navigator.onLine) {
		try {
			const res = await fetchWithTimeout(`${settings.getAPIEnvDomain()}ping/`);
			const obj = await res.json();
			if (obj.success) {
				getDataToSend();
			}
		} catch(error) {
			console.error(error.name);
		}
	}
};

/**
 * Set state internetIsDisable: false of a component
 */
export const setEnable = c => async () => {
	// Check if really online and Studio up
	try {
		const res = await fetchWithTimeout(`${settings.getAPIEnvDomain()}ping/`);
		const obj = await res.json();
		if (obj.success) {
			console.warn('internet connection ok');
			c.setState({ internetIsDisable: false });
			getDataToSend();
		}
	} catch(error) {
		c.setState({ internetIsDisable: true });
	}
};

/**
 * Set state internetIsDisable: true of a component
 */
export const setDisable = c => () => {
	console.warn('no internet connection');
	c.setState({ internetIsDisable: true });
};

/**
 * Add a window listener to set state.IsDisable according to the online/offline internet connection
 */
export const addInternetConnectionListener = c => {
	window.addEventListener('online', setEnable(c));
	window.addEventListener('offline', setDisable(c));
};

/**
 * Remove window listener according to the online/offline internet connection
 */
export const removeInternetConnectionListener = c => {
	window.removeEventListener('online', setEnable(c));
	window.removeEventListener('offline', setDisable(c));
};

/**
 * Start a timer. If timeout reached, route to first page of the app
 * @param {Object} c app component
 */
export const startInactivityTimer = c => window.setTimeout(() => { customRoute(`/${c.state.appInfo.slug}/`, c.state.appInfo, true); }, c.timeout * 60 * 1000);

/**
 * Reset the app timer
 * @param {Object} c app component
 */
const resetTimer = c => () => {
	window.clearTimeout(c.timerTimeout);
	c.timerTimeout = startInactivityTimer(c);
};

/**
 * Add window listeners for a set of user interactions to reset inactivity timer
 * @param {Object} c app component
 */
export const addUserInteractionsListener = c => {
	const events = ['click', 'mousedown', 'keydown', 'wheel', 'touchstart'];
	events.map(name => window.addEventListener(name, resetTimer(c), true));
	c.timerTimeout = startInactivityTimer(c);
};

/**
 * Remove window listeners for a set of user interactions linked to inactivity timer
 * @param {Object} c app component
 */
export const removeUserInteractionsListener = c => {
	window.clearTimeout(c.timerTimeout);
	const events = ['click', 'mousedown', 'keydown', 'wheel', 'touchstart'];
	events.map(name => window.removeEventListener(name, resetTimer(c), true));
	c.timerTimeout = null;
};

/**
 * Create the dynamic color according to a given color
 * @param {string} color
 */
export const dynamicColor = color => {
	const rgbSplit = color.split('rgba(')[1].split(',');
	const lum = ((0.299 * rgbSplit[0]) + (0.587 * rgbSplit[1]) + (0.114 * rgbSplit[2])) / 255; // apply the magic
	return lum > 0.5 ? '0,0,0' : '255,255,255';
};

export const trimRGBA = color => {
	const rgbSplit = color.split('rgba(')[1].split(',');
	return `${rgbSplit[0]},${rgbSplit[1]},${rgbSplit[2]}`;
};

export const isTransparentColor = color => {
	const rgbSplit = color.split('rgba(')[1].split(',');
	return rgbSplit[3].trim().slice(0,-1) === '0';
};

/**
 * Return the ID of the page without its slug name
 * @param {object} props router path props passed to page component
 */
export const pageIdWithoutSlug = props => Number(props.pageSlug.split('-')[0]);

/**
 * Return the app Id from the URL https://preview.yunow.app/lang/ID-page-slug/appID
 */
export const getIdsFromPreviewUrl = () => {
	if (isStudioPreview()) {
		if (window.location !== window.parent.location && document.referrer.split('/')[3] === 'preview') return document.referrer.split('/')[4]; // https://studio.yunow.ninja/preview/appID/
		const pathnameArray = window.location.pathname.substring(1).split('/');
		const val = pathnameArray[pathnameArray.length - 1];
		if (Number(val)) return pathnameArray[pathnameArray.length - 1];
		else if (val.split('-')[0] === 'content') return `${pathnameArray[pathnameArray.length - 2]}-${val.split('-')[1]}`;
	}
	return null;
};

// https://preview.yunow.app/en?/69-collection-australienne/456/
export const getAppLangFromUrl = () => {
	const pathnameArray = window.location.pathname.substring(1).split('/');
	if(pathnameArray.includes("login")) return
	if (pathnameArray.length === 3) return pathnameArray[0]; // en/69-collection-australienne/456/
	else if (pathnameArray.length === 2 || pathnameArray.length === 1) { // en/69-collection-australienne/ || en/456/ || 69-collection-australienne/456/
		if (pathnameArray[0].split('-')[0].match(/[a-z]{2,4}/g)) return pathnameArray[0];
		return '';
	}
	return '';
};

export const getAppLangFromOriginalUri = () => {
	const originalUri = localStorage.getItem('originalUri').replace(/"/g, '');
	if (originalUri) return originalUri.split('/')[1];
	return '';
}

/**
 * Get the url of a media and test if it's a video/audio media or not
 * @param {string} url media url from JSON
 * @returns {boolean} true | false
 */
export const isUrlAnImage = url => {
	const ext = url.split('.webp')[0].split('.').pop().toUpperCase();
	return (ext === 'JPG' || ext === 'JPEG' || ext === 'PNG' || ext === 'BMP' || ext === 'GIF' || ext === 'WEBP');
};

/**
 * Get the url of a media and test if it's a video media or not
 * @param {string} url media url from JSON
 * @returns {boolean} true | false
 */
export const isUrlAVideo = url => url.split('.webp')[0].split('.').pop().toUpperCase() === ('MP4');

/**
 * Get the url of a media and test if it's an audio media or not
 * @param {string} url media url from JSON
 * @returns {boolean} true | false
 */
export const isUrlAnAudio = url => {
	const ext = url.split('.webp')[0].split('.').pop().toUpperCase();
	return (ext === 'MP3' || ext === 'WAV');
};

/**
 * Get the url of a media and test if it's a pdf media or not
 * @param {string} url media url from JSON
 * @returns {boolean} true | false
 */
export const isUrlAPDF = url => url.split('.webp')[0].split('.').pop().toUpperCase() === ('PDF');

/**
 * Get the url of a media and test if it's a 3D object media or not
 * @param {string} url media url from JSON
 * @returns {boolean} true | false
 */
export const isUrlA3D = url => {
	const ext = url.split('.webp')[0].split('.').pop().toUpperCase();
	return (ext === 'OBJ' || ext === 'GLB' || ext === 'GLTF');
};

/**
 * Get an url of a media and return url of default image if url is null or undefined
 * @param {String} url url of a media
 */
export const getDefaultImage = url => {
	let src = url;
	if (!src) src = defaultImg;
	return src;
};

/**
 * Return media.url or media.thumbnail url according to its type (image/video/audio)
 * or a default media url
 * @param {Object} media media object from JSON with {id, Thumbnail, Url, Weight}
 * @returns {string} url of a media
 */
export const getDefaultMediaSrc = media => {
	let src = null;
	if (!media) src = defaultImg;
	else src = isUrlAnImage(media.url)
		? getDefaultImage(media.url)
		: media.children && media.children[0] && getDefaultImage(media.children[0].url);
	return src;
};

/**
 * Get the media source from an item depending on its blocs
 * @param {Array} blocs all item blocs from
 * @param {Number} headerImg id of the header image of item
 * @param {Object} medias all Medias in the JSON
 * @returns {string} url of a media
 */
export const getImageSource = (blocs, headerImg, medias) => {
	// try to get header picture first if one exists
	if (headerImg) return getDefaultMediaSrc(medias[headerImg]);

	const bloc = blocs.find(bloc => bloc.type === 'media' || bloc.type === 'carousel');
	if (bloc && bloc.media) return getDefaultMediaSrc(medias[bloc.media]);
	else if (bloc && bloc.carousel && bloc.carousel.medias) return getDefaultMediaSrc(medias[bloc.carousel.medias[0]]);

	return defaultImg;
};

/**
 * Get the media object from an item depending on its structure (layer, quiz, ...)
 * @param {Object} blocs all fields of item from JSON
 * @param {Number} headerImg structure Id of item from JSON
 * @param {Object} medias all Medias in the JSON
 * @returns {Object} single media object of the JSON
 */
export const getMediaObject = (blocs, headerImg, medias) => {
	// try to get header picture first if one exists
	if (headerImg) return medias[headerImg];

	const bloc = blocs.find(bloc => bloc.type === 'media' || bloc.type === 'carousel');
	if (bloc && bloc.media) return medias[bloc.media];
	else if (bloc && bloc.carousel && bloc.carousel.medias) return medias[bloc.carousel.medias[0]];

	return null;
};

/**
 * Preload in cache the media's default image
 * @param {Object} media formated media from JSON
 */
export const preloadImage = media => {
	if (media) {
		let img = new Image();
		return img.src = getDefaultMediaSrc(media);
	}
};

export const generateMediaUrl = (filename, s3Folder) => {
	const commonUrl = `/medias/${filename}`;
	if (s3Folder) {
		return `${settings.getAWSEnvUrl().split('published')[0]}${s3Folder}${commonUrl}`;
	}
	return commonUrl;
};

/**
 * Return a random value between min/max included
 * @param {Number} min min value
 * @param {Number} max max value
 */
export const getRandom = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Get the median value of an array
 * @param {Array} arr the array from witch we want to find the median value
 * @param {Boolean} shouldSort the array from witch we want to find the median value
 * @returns The median value of the array
 */
export const getMedian = (arr, shouldSort) => {
	const mid = Math.floor(arr.length / 2);
	const nums = shouldSort ? [...arr].sort((a, b) => a - b) : arr;
	return arr.length % 2 !== 0 ? nums[mid] : nums[mid - 1]; // get lower value of array if even length
};

/**
 * Increment the state of a Component from x time
 * @param {Object} c Component
 * @param {String} counter state prop of the Component
 * @param {Number} x increment
 */
export const incrState = (c, counter, x) => {
	let stateToIncr = c.state[counter];
	stateToIncr += x;
	c.setState({ [counter]: stateToIncr });
};

/**
 * Push statistics to Studio
 * @param {String} appId application id
 * @param {String} objectId item id
 * @param {String} openedAt unix timestamp of the open time (seconds)
 * @param {String} openedFor showing time (seconds)
 * @param {String} actionType must be pageOpened | pageClosed | contentOpened | categorySelected
 */
export const pushStatsToStudio = async (appId, objectId, openedAt, openedFor, actionType, shouldAdd = true) => {
	if (navigator.onLine) {
		try {
			const response = await settings.postStats(appId, objectId, openedAt, openedFor, actionType);
			const json = await response.json();
			if (json.error) return null;
			shouldAdd && storeStatsInDb(appId, objectId, openedAt, openedFor, actionType, 1);
			return json;
		} catch (error) {
			shouldAdd && storeStatsInDb(appId, objectId, openedAt, openedFor, actionType, 0);
			console.error(error.name);
		}
	}
	else {
		shouldAdd && storeStatsInDb(appId, objectId, openedAt, openedFor, actionType, 0);
	}
	return null;
};

const storeStatsInDb = async (appId, objectId, openedAt, openedFor, actionType, sent) => {
	await db().stats.add({
		appId,
		objectId,
		openedAt,
		openedFor,
		actionType,
		sent
	});
};

/**
 * Checks document URL to know if we are in preview mode
 * Standard URLs are https://studio.yunow.io/preview/XX/ or https://preview.yunow.app/lang/ID-page-slug/appID/
 * Return true if we have two parts in the URL
 */
export const isStudioPreview = () => {
	const previewUrl = /\/previewm.preprod.yunow.app|\/preview.preprod.yunow.app|\/preview.beta.yunow.app|\/previewm.beta.yunow.app|studio.beta.yunow.io|\/preview.yunow.app|\/previewm.yunow.app|studio.yunow.io/.test(window.location.href);
	const pathnameArray = window.location.pathname.substring(1).split('/')[0];
	return pathnameArray === 'preview' || settings.forceProdJSON || previewUrl;
};

/**
 * Check wether the preview is in mobile mode or not
 * @returns true if the preview is in mobile mode
 */
export const isMobilePreview = () => /\/previewm.preprod.yunow.app|\/previewm.beta.yunow.app|\/previewm.yunow.app/.test(window.location.href);

/**
 * Return True if Safari is used
 */
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Convert seconds to Minutes:Seconds format
 * @param {Number} time seconds to convert
 */
export const secondsToMinSec = time => {
	let minutes = Math.floor(time / 60);
	let seconds = Math.floor(time - minutes * 60);

	// display time on 2 digits
	minutes = ('0' + minutes).slice(-2);
	seconds = ('0' + seconds).slice(-2);

	// display with ':' separator
	const finalFormat = minutes + ':' + seconds;
	return finalFormat;
};

/**
 * Get array (Page, Quiz, ...) object from App or page JSON and return them sorted according to the desired property
 * @param {String} property The property we want the sort on
 */
export const dynamicSort = property => {
	let sortOrder = 1;
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	return (a, b) => {
		const result = (a[property] > b[property]) ? 1 : (b[property] > a[property]) ? -1 : 0;
		return result * sortOrder;
	};
};

/**
 * Return a random String
 */
export const randomString = () => Math.random().toString(36).replace(/[^a-z]+/g, '');

export const validateEmail = email => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[A-Za-z0-9]*)+$/.test(email); // eslint-disable-line

/**
 * Checks wether lat and lng coordinates values are valid or not
 * @param {Object} position The latitude and longitude to test
 * @returns True if both coordinates are valid
 */
export const validateLatLngCoords = position => /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(position.lat.toString().concat(',', position.lng.toString()));

/**
 * Set the background of templates
 * @param {Object} backgroundObj the json background object from customization
 * @param {Object} medias the json medias
 */
 export const setBackground = (backgroundObj, medias, lang) => {
	if (backgroundObj) {
		let background = {
			video: {
				url: '',
				subtitles: {}
			},
			img: '',
			alt: medias[backgroundObj.media] && medias[backgroundObj.media].alt && medias[backgroundObj.media].alt[lang],
			color: globalVars.color.primary,
			fill: backgroundObj.fill
		};
		if (backgroundObj.type === 'picture')
			background.img = getDefaultMediaSrc(medias[backgroundObj.media]);
		else if (backgroundObj.type === 'video') {
			background.video.url = medias[backgroundObj.media].url;
			background.video.subtitles = medias[backgroundObj.media].subtitles;
		}
		else background.color = backgroundObj.color;
		return background;
	}
	return null;
};

const fetchWithTimeout = async (resource, options = {}) => {
	const { timeout = 6000 } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
	const response = await fetch(resource, {
		...options,
		signal: controller.signal
	});
	clearTimeout(id);
	return response;
};

/**
 * Override preact route to handle Studio preview refresh on same page
 * @param {String} url The URL we want to navigate to
 * @param {Number} appId The id of the application
 * @param {Boolean} replace Optional boolean
 */
export const customRoute = (url, appId, replace) => {
	if (isStudioPreview()) {
		window.parent.postMessage({
			event_id: 'yunow_url_change',
			data: {
				newurl: `${document.location.origin.replace(/\/$/, '')}${url}/${appId}`,
			}
		}, '*');
	}
	route(url, replace);
};

/**
 * Set the template state using the state saved in sessionStorage history
 * @param {Object} c The template component itself
 * @returns True if the template is loaded as a result of a back to previous state nav
 */
export const setTemplateFromHistory = (c) => {
	const storedHistory = sessionStorage.getItem('history');
	const history = JSON.parse(storedHistory) || [];
	if (history.length > 0) {
		const lastEntry = history[history.length - 1];
		if (lastEntry && c.props.page.id === lastEntry.id) {
			if (lastEntry.openedItem) c.props.showModal(lastEntry.openedItem);
			c.setState({ ...lastEntry.compState }, () => {
				history.pop();
				storeItem('session', 'history', history);
			});
			return true;
		}
	}
	return false;
};

/**
 * Allow the template component to know if the navigation has been triggered from an item
 * If that's the case, return the card ID to animate the corresponding content
 * @returns The id of the openedItem if there is one, else null
 */
export const isVueNavigation = () => {
	const storedHistory = sessionStorage.getItem('history');
	const history = JSON.parse(storedHistory) || [];
	if (history.length > 0) {
		const lastEntry = history[history.length - 1];
		if (lastEntry) {
			if (lastEntry.openedItem) return lastEntry.openedItem;
			return null;
		}
	}
	return null;
};

/**
 * Handle back to previous state by getting info from sessionStorage
 * @param {Object} props props of corresponding component
 */
export const restorePreviousState = props => {
	// First we check if there is a card that should be shown (handle backToPreviousState from a button within a card)
	const storedCardHistory = sessionStorage.getItem('cardHistory');
	const cardHistory = JSON.parse(storedCardHistory) || {};
	const id = props.currentPageId || props.page.id;
	const pageCardHistory = cardHistory[id];
	if (pageCardHistory && pageCardHistory.length > 0) {
		// if only one card left in cardHistory, close content (will delete page card history entry cf. cardModal component)
		if (pageCardHistory.length === 1) {
			props.closeModal(true);
		}
		// if more than one, delete the current one and open the one before
		else if (pageCardHistory.length > 1) {
			pageCardHistory.pop();
			storeItem('session', 'cardHistory', cardHistory);
			const lastCardEntry = pageCardHistory[pageCardHistory.length - 1];
			props.showModal(lastCardEntry);
		}
	}
	else { // handle navigation from a page to another
		const storedHistory = sessionStorage.getItem('history');
		const customHistory = JSON.parse(storedHistory) || [];
		if (customHistory.length > 0) {
			const lastEntry = customHistory[customHistory.length - 1];
			if (lastEntry) {
				if (lastEntry.openedItem) setTimeout(() => props.showModal(lastEntry.openedItem), 250); // timeout to prevent openedItem to be logged with wrong page id
				history.back(); // trigger popstate event on page component (cf. handleGoBack function)
			}
		}
	}
};

/**
 * Conserve aspect ratio of the original region to fit into a certain area
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
	const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
	return { width: srcWidth * ratio, height: srcHeight * ratio };
};

/**
 * @param {Array} consultedContents The consulted contents (consultedPois) in localStorage
 * @param {Object} item The item/content we want to check as locked/unlocked
 * @returns True if the tested item is locked, else false
 */
 export const isLockedByDate = (consultedContents, item) => {
	if (item.lockAt) {
		const lockAt = new Date(item.lockAt);
		if (lockAt?.getTime() <= Date.now()) {
			return true;
		}
	}
	if (item.unlockAt) {
		const unlockAt = new Date(item.unlockAt);
		if (unlockAt?.getTime() > Date.now() && !consultedContents.includes(item.id)) {
			return true;
		}
	}
	return false;
};

/**
 * Check if an item is to be published or not taking its publishAt and withdrawAt dates into account
 * @param {Object} item The item/content we want to check as published
 * @returns true if the tested item is published/visible
 */
export const isItemPublishedByDate = item => {
	if (!item.publishAt && !item.withdrawAt) return true;
	let published, withdrawn;
	if (item.publishAt) {
		const publishAt = new Date(item.publishAt);
		if (publishAt?.getTime() <= Date.now()) {
			published = true;
		}
		else published = false;
	}
	if (item.withdrawAt) {
		const withdrawAt = new Date(item.withdrawAt);
		if (withdrawAt?.getTime() > Date.now()) {
			withdrawn = true;
		}
		else withdrawn = false;
	}
	return published && withdrawn;
};

/**
 * Copy the selected string content to the clipboard
 * @param {String} content the content to be copied to the clipboard
 */
export const copyToClipboard = (content, showSnackbar) => {
	const plainString = content.replace(/<[^>]+>/g, '');
	navigator.clipboard.writeText(plainString)
	.then(() => {
		showSnackbar(<Text id="common.copied" />, 'bottom');
	})
	.catch(() => {
		showSnackbar(<Text id="common.notCopied" />, 'bottom');
	});
};

export const distance = (lat1, lon1, lat2, lon2) => {
	// https://stackoverflow.com/questions/51819224/how-to-find-nearest-location-using-latitude-and-longitude-from-a-json-data
	const radlat1 = Math.PI * lat1 / 180;
	const radlat2 = Math.PI * lat2 / 180;
	const theta = lon1 - lon2;
	const radtheta = Math.PI * theta / 180;
	let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	if (dist > 1) {
		dist = 1;
	}
	dist = Math.acos(dist);
	dist = dist * 180 / Math.PI;
	dist = dist * 60 * 1.1515;
	dist = dist * 1.609344;
	return dist;
};

export const formatFontawesomeName = entry => {
	const cleaned = entry.split('fa-')[2];
	const arr = cleaned.split('-');
	return `fa${arr.map(el => el.charAt(0).toUpperCase() + el.slice(1)).join('')}`;
};

export const storeItem = (location, name, content) => {
	const storage = location === 'local' ? localStorage : sessionStorage;
	try {
		storage.setItem(name, JSON.stringify(content));
	}
	catch (error) {
		console.error(error);
	}
};

/**
 * Add a backslash before regex characters. Prevents error with .search function.
 * @param {String} str The string we want to escape regex characters from
 * @returns The cleaned string
 */
const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const highlightKeyword = (text, keyword, style = '') => {
	if (keyword === '') return text;
	const accentMap = {
		ae: '(ae|æ|ǽ|ǣ)',
		a:  '(a|á|ă|ắ|ặ|ằ|ẳ|ẵ|ǎ|â|ấ|ậ|ầ|ẩ|ẫ|ä|ǟ|ȧ|ǡ|ạ|ȁ|à|ả|ȃ|ā|ą|ᶏ|ẚ|å|ǻ|ḁ|ⱥ|ã)',
		c:  '(c|ć|č|ç|ḉ|ĉ|ɕ|ċ|ƈ|ȼ)',
		e:  '(e|é|ĕ|ě|ȩ|ḝ|ê|ế|ệ|ề|ể|ễ|ḙ|ë|ė|ẹ|ȅ|è|ẻ|ȇ|ē|ḗ|ḕ|ⱸ|ę|ᶒ|ɇ|ẽ|ḛ)',
		i:  '(i|í|ĭ|ǐ|î|ï|ḯ|ị|ȉ|ì|ỉ|ȋ|ī|į|ᶖ|ɨ|ĩ|ḭ)',
		n:  '(n|ń|ň|ņ|ṋ|ȵ|ṅ|ṇ|ǹ|ɲ|ṉ|ƞ|ᵰ|ᶇ|ɳ|ñ)',
		o:  '(o|ó|ŏ|ǒ|ô|ố|ộ|ồ|ổ|ỗ|ö|ȫ|ȯ|ȱ|ọ|ő|ȍ|ò|ỏ|ơ|ớ|ợ|ờ|ở|ỡ|ȏ|ō|ṓ|ṑ|ǫ|ǭ|ø|ǿ|õ|ṍ|ṏ|ȭ)',
		u:  '(u|ú|ŭ|ǔ|û|ṷ|ü|ǘ|ǚ|ǜ|ǖ|ṳ|ụ|ű|ȕ|ù|ủ|ư|ứ|ự|ừ|ử|ữ|ȗ|ū|ṻ|ų|ᶙ|ů|ũ|ṹ|ṵ)'
	};
	const accentRegex = new RegExp(Object.keys(accentMap).join('|'), 'g');
	const queryRegex = new RegExp(escapeRegExp(keyword).toLowerCase().replace(accentRegex, m => accentMap[m] || m), 'gi');
	return text.replace(queryRegex, m => style ? `<span class="${style}">${m}</span>` : `<mark>${m}</mark>`);
};

export const removeDiacritics = str => str.normalize("NFD").replace(/\p{Diacritic}/gu, "");

/**
 * Checks if an HTML element is outside the viewport or not
 * @param {HTMLElement} elem the HTML element to check
 * @returns true if in viewport or distance if not
 */
export const isInViewport = elem => {
	const distance = elem.getBoundingClientRect();
	if (distance.top >= 0 &&
		distance.left >= 0 &&
		distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		distance.right <= (window.innerWidth || document.documentElement.clientWidth)
	) return true;
	return distance;
};

export const checkNDD = (mail, domains) => {
	const parts = mail.split('@');
	const domain = '' + parts.pop();
	const lowerDomain = domain.toLowerCase();
	if (!domains.includes(lowerDomain)) return true;
	return false;
};

/**
 * Capture all click events and checks if it's an HTML a tag and if it's an in app link
 * Opens a card if URL is inApp link of a content, else do nothing
 * @param {Object} c The app component
 */
export const captureAllClickEvents = c => {
	document.addEventListener('click', e => {
		const origin = e.target.closest('a');
		if (origin) {
			// handle clicks on HTML <a> tag only
			const regExp = new RegExp(window.location.hostname, 'g');
			const cardSlug = origin.href.split('/')[5];
			if (cardSlug && origin.href.match(regExp) && cardSlug.split('-')[0].match(/\d+/g)) {
				e.stopPropagation();
				e.preventDefault();
				c.onShowModal(Number(cardSlug.split('-')[0]));
			}
		}
	});
};

export const uniqueId = () => {
	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substring(2);
	return randomness + dateString;
};

/**
 * Rewrite a number on 2 digits number
 * @param {Number} nb number to rewrite
 */
export const onTwoDigits = nb => ('0' + nb).slice(-2);

/**
	 * Promise to save a blob to indexedDB
	 * @param {object} db Dexie database
	 * @param {number} id id of the media
	 * @param {string} url the url of the media
	 */
export const saveBlobToIndexedDB = async (db, id, url) => {
	try {
		const nbOfItems = await db.blobs.where('id').equals(id).count();
		if (nbOfItems === 0) {
			const response = await fetch(url);
			const imgBlob = await response.blob();
			await db.blobs.put({
				id,
				blob: imgBlob
			});
			return imgBlob;
		}
		return null;
	}
	catch (error) {
		return Promise.reject(error);
	}
}

/*  .oooooo.
 d8P'  `Y8b
888            .oooo.   ooo. .oo.  .oo.    .ooooo.
888           `P  )88b  `888P"Y88bP"Y88b  d88' `88b
888     ooooo  .oP"888   888   888   888  888ooo888
`88.    .88'  d8(  888   888   888   888  888    .o
 `Y8bood8P'   `Y888""8o o888o o888o o888o `Y8bod8P'
*/

/**
 *
 * @param {Object} gameSession The json object of gameSession parameters
 * @param {Array} filteredPagesResults The filtered objects containing pages results
 * @param {Array} consultedContents The consulted contents (consultedPois) in localStorage
 * @param {Number} itemId The item/content we want to check as locked/unlocked
 * @returns True if the tested item is locked, else false
 */
export const isItemLocked = (gameSession, filteredPagesResults, consultedContents, itemId) => {
	if (gameSession) {
		// For each filtered pages, we check if current item id is included inside the contents to be locked/unlocked for this page
		return filteredPagesResults.some(res => {
			// If id is included, it must be locked, except if already unlocked from a game, so check in localStorage
			if (res.contents.includes(itemId)) {
				if (!consultedContents.includes(itemId)) {
					return true;
				}
			}
		});
	}
	return false;
};

/**
 * Call Studio API to send the game session info
 * @param {Number} appId id of the current app
 * @param {string} gameSession stringified object of an array of ids of finished pages and arrays of consultedPois
 * @param {string} email email to send the session code to
 * @returns {json} json with success/error message
 */
 export const postGameSession = async (appId, gameSession, email) => {
	try {
		const response = await settings.postGameSession(appId, Math.floor(Date.now() / 1000), gameSession, email);
		const json = await response.json();
		if (json.error) return Promise.reject(Error(json.message));
		return json;
	}
	catch (error) {
		return Promise.reject(error);
	}
};

/**
 * Return the success percentage
 * @param {Number} score actual score
 * @param {Number} scoreMax maximum score
 */
const percentOfSuccess = (score, scoreMax) => {
	const percentOfSuccess = Math.floor(score * 100 / scoreMax);
	return percentOfSuccess;
};

/**
 * Return the reward string according to the score
 * @param {Number} score actual score
 * @param {Number} scoreMax maximum score
 */
export const reward = (score, scoreMax) => {
	const successPercent = percentOfSuccess(score, scoreMax);
	const reward = successPercent > 75
		? 'excellent'
		: successPercent <= 75 && successPercent > 50
			? 'good'
			: successPercent <= 50 && successPercent > 25
				? 'bad'
				: successPercent <= 25
					? 'ugly'
					: true;
	return reward;
};

/**
 * Check if this page is the last one of the pages list
 */
export const isLastPage = props => {
	const pagePosition = props.appInfo.pages.findIndex(page => page.id === props.page.id) + 1;
	return props.appInfo.pages.length === pagePosition;
};

/**
 * Get the slug of the next page in the pages list
 */
export const getNextPageLink = props => {
	let nextPage;
	if (isLastPage(props)) {
		nextPage = props.appInfo.pages[0];
	}
	else {
		const nexPageIndex = props.appInfo.pages.findIndex(page => page.id === props.page.id) + 1;
		nextPage = props.appInfo.pages[nexPageIndex];
	}
	return `${nextPage.id}-${nextPage.slug}`;
};
