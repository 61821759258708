import { Component } from 'preact';
import { log, isUrlAnImage, isUrlAVideo, isUrlAnAudio, isUrlAPDF } from 'Components/common';

import style from './style';

import Image from './image';
import Video from './video';
import Audio from './audio';
import Pdf from './pdf';
import Three from 'Components/card/blocks/media/three';

let MediaComponent = null;

export default class Slide extends Component {
	componentDidMount() {
		log('init slide media');
	}

	render(props) {
		if (isUrlAnImage(props.media.url)) MediaComponent = Image;
		else if (isUrlAVideo(props.media.url)) MediaComponent = Video;
		else if (isUrlAnAudio(props.media.url)) MediaComponent = Audio;
		else if (isUrlAPDF(props.media.url)) MediaComponent = Pdf;
		else MediaComponent = Three;

		return (
			<MediaComponent
				{...props}
				url={props.media.url}
				style={style}
				pinchZoomPan={props.pinchZoomPan}
			/>
		);
	}
}
