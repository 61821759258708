import { Component } from 'preact';
import { log } from 'Components/common';
import LanguageButton from './button';
import LanguageFlap from './flap';

export default class LanguageController extends Component {
	state = {
		isFlapOpen: false
	}

	/**
	 * Open/Close the flap
	 */
	toggleFlap = () => {
		this.setState({
			isFlapOpen: !this.state.isFlapOpen
		});
	};
	
	componentDidMount() {
		log('init topbar language controller');
		this.props.onRefLangController(this); // ref component on app lvl
	}

	render(props, state) {
		return (
			<div>
				<LanguageButton
					toggleLangFlap={this.toggleFlap}
					lang={props.appInfo.lang}
					showLangFlags={this.props.appInfo.showLangFlags}
					showLangNames={this.props.appInfo.showLangNames}
				/>
				<LanguageFlap
					allLang={props.appInfo.allLang}
					setLang={props.setLang}
					lang={props.appInfo.lang}
					isLangFlapOpen={state.isFlapOpen}
					toggleLangFlap={this.toggleFlap}
					showLangFlags={this.props.appInfo.showLangFlags}
					showLangNames={this.props.appInfo.showLangNames}
				/>
			</div>
		);
	}
}