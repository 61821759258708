const Play = () => (
	<svg
		width="2.5rem"
		height="2.8rem"
		viewBox="0 0 25 28"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-11.000000, -14.000000)" fill="#FFFFFF" fill-rule="nonzero">
				<path d="M13.6421993,14.2329934 C13.0967192,13.9223355 12.4262132,13.9223355 11.8807331,14.2329934 C11.335253,14.5436512 11,15.117923 11,15.7403028 L11,40.2597028 C11,40.8821106 11.335253,41.4563544 11.8807331,41.7670122 C12.152918,41.9245253 12.4572006,42 12.7614662,42 C13.0657318,42 13.3699974,41.9212434 13.6421993,41.7670122 L35.1203716,29.5084323 C35.6669563,29.1977745 36,28.6224106 36,28.0000308 C36,27.3798352 35.6658517,26.8044713 35.1203716,26.4927214 L13.6421993,14.2329934 Z" />
			</g>
		</g>
	</svg>
);

export default Play;
