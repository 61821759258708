import { Component } from 'preact';
import { Text } from 'preact-i18n';
import encUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import { postGameSession, log, customRoute, storeItem } from 'Components/common';
import { getGameSession, cipherSecret } from 'Components/settings';
import MenuModal from './modals/menu';
import NewModal from './modals/new';
import SaveModal from './modals/save';
import OpenModal from './modals/open';
import CreateModal from './modals/create';
import ProgressBar from './progressBar';

import style from './style';

export default class GameSession extends Component {

	/**
	 * Update the percentage value used for progress bar of game session
	 * @param {Boolean} updateState true if we want to update the state, false if we want a return value
	 * @returns the percentage as integer (0 - 100)
	 */
	updatePercentage = updateState => {
		const storedGameSession = localStorage.getItem('gameSession');
		let percent = 0;
		try {
			const gameSession = JSON.parse(storedGameSession) || {};
			if (gameSession?.finishedPages && this.props.appInfo.gameSession.data) {
				percent = Math.round(gameSession.finishedPages.length / this.props.appInfo.gameSession.data.length * 100);
				if (percent > 100) percent = 100;
			}
		}
		catch {
			if (updateState) this.setState({ percent });
		}
		if (updateState) this.setState({ percent });
		return percent;
	}

	state = {
		percent: this.updatePercentage(),
		showMenuModal: false,
		showSaveModal: false,
		showNewModal: false,
		showCreateModal: false,
		showOpenModal: false,
		codeError: false,
		saveError: false
	}

	toggleMenuModal = () => {
		this.setState({ showMenuModal: !this.state.showMenuModal });
	}

	toggleSaveModal = () => {
		const storedGameSession = localStorage.getItem('gameSession');
		let gameSession = JSON.parse(storedGameSession) || {};
		// if a session already exists auto save on it else open modal
		if (gameSession.cipherText && !this.state.showSaveModal) {
			const originalMail = AES.decrypt(gameSession.cipherText, cipherSecret).toString(encUTF8);
			this.saveSession(this.props.appInfo.id, storedGameSession, originalMail);
			this.setState({ showMenuModal: false });
		}
		else this.setState({ showSaveModal: !this.state.showSaveModal });
	}

	toggleNewModal = () => {
		this.setState({ showNewModal: !this.state.showNewModal });
	}

	toggleCreateModal = () => {
		this.setState({ showCreateModal: !this.state.showCreateModal });
	}

	toggleOpenModal = () => {
		this.setState({ showOpenModal: !this.state.showOpenModal });
	}

	newSession = (fromCreate) => {
		this.setState({ showMenuModal: false, showNewModal: false, showCreateModal: false });
		if (fromCreate) {
			this.toggleCreateModal();
			this.props.closeCreateModal(true);
			customRoute(window.location.pathname, this.props.appInfo.id, true);
		}
		else {
			const allConsultedPois = Object.keys(localStorage).filter(item => item.match('consultedPois-'));
			allConsultedPois.map(res => localStorage.removeItem(res));
			localStorage.removeItem('logInfo');
			localStorage.removeItem('storedAppLangPopups');
			localStorage.removeItem('gameSession');
			localStorage.removeItem('authentication');
			localStorage.removeItem('storedStartModals');
			localStorage.removeItem('storedFreeModals');
			localStorage.removeItem('storedOfflineModals');
			sessionStorage.removeItem('history');
			sessionStorage.removeItem('cardHistory');
			this.props.newGameSession();
			this.props.closeCreateModal(false);
		}
	}
	
	saveSession = (appId, gameSession, email) => {
		this.setState({ saveError: false });
		postGameSession(appId, gameSession, email)
		.then(() => {
			this.setState({ showMenuModal: false, showSaveModal: false });
			this.props.showSnackbar(<Text id="gameSession.snackbar.saved" />);
		})
		.catch(() => {
			this.props.showSnackbar(<Text id="gameSession.snackbar.error" />);
			this.setState({ saveError: true });
		});
	}

	openSession = code => {
		getGameSession(this.props.appInfo.id, code)
		.then(response => {
			response.json().then(json => {
				const gameSession = json.message;
				storeItem('local', 'gameSession', gameSession);
				// parse gameSession to get every consultedPois-ID and add them to localstorage in order to unlock pois
				const gameSessionObj = JSON.parse(gameSession) || {};
				const allConsultedPois = Object.keys(gameSessionObj).filter(item => item.match('consultedPois-'));
				allConsultedPois.map(consultedPois => storeItem('local', consultedPois, gameSessionObj[consultedPois]));
				this.toggleOpenModal();
				this.setState({ showMenuModal: false, showOpenModal: false });
				this.updatePercentage(true);
				customRoute(window.location.pathname, this.props.appInfo.id, true);
				this.props.showSnackbar(<Text id="gameSession.snackbar.opened" />);
			})
		})
		.catch(() => {
			this.setState({ codeError: true });
		});
	}

	resetError = () => {
		this.setState({ saveError: false });
	}

	componentDidMount() {
		log('init game session');
		this.props.onRefGameSession(this); // reference component for app lvl
		if (this.props.appInfo.gameSession.modals && this.props.appInfo.gameSession.modals.create) {
			const storedGameSession = localStorage.getItem('gameSession');
			try {
				const gameSession = JSON.parse(storedGameSession) || {};
				if (!(gameSession && gameSession.pseudo && gameSession.pseudo.trim() !== '')) {
					this.toggleCreateModal();
					this.props.closeCreateModal(false);
				}
				else this.props.closeCreateModal(true);
			}
			catch (error) {
				console.log('error parsing game session : ', error);
			}
		}
		else this.props.closeCreateModal(true);
	}

	render(props, state) {
		return (
			!props.appInfo.gameSession.hideInMenu &&
				<div class={style.gameSession}>
					{this.state.percent !== Infinity && <ProgressBar color={'secondaryText'} percent={state.percent} />}
					<button className={style.toggleMenu} onClick={this.toggleMenuModal}>
						<Text id="gameSession.handle" />
					</button>
					{state.showMenuModal &&
						<MenuModal
							medias={props.medias}
							appInfo={props.appInfo}
							info={props.appInfo.gameSession.modals.menu}
							isMobile={props.isMobile}
							percent={state.percent}
							toggleMenuModal={this.toggleMenuModal}
							toggleNewModal={this.toggleNewModal}
							toggleSaveModal={this.toggleSaveModal}
							toggleOpenModal={this.toggleOpenModal}
						/>
					}
					{state.showNewModal &&
						<NewModal
							medias={props.medias}
							appInfo={props.appInfo}
							info={props.appInfo.gameSession.modals.new}
							isMobile={props.isMobile}
							internetIsDisable={props.internetIsDisable}
							toggleMenuModal={this.toggleMenuModal}
							toggleNewModal={this.toggleNewModal}
							newSession={this.newSession}
						/>
					}
					{state.showSaveModal &&
						<SaveModal
							medias={props.medias}
							appInfo={props.appInfo}
							info={props.appInfo.gameSession.modals.save}
							isMobile={props.isMobile}
							internetIsDisable={props.internetIsDisable}
							toggleMenuModal={this.toggleMenuModal}
							toggleSaveModal={this.toggleSaveModal}
							saveGameSession={this.saveSession}
							error={state.saveError}
							resetError={this.resetError}
						/>
					}
					{state.showOpenModal &&
						<OpenModal
							medias={props.medias}
							appInfo={props.appInfo}
							info={props.appInfo.gameSession.modals.open}
							isMobile={props.isMobile}
							internetIsDisable={props.internetIsDisable}
							toggleMenuModal={this.toggleMenuModal}
							toggleOpenModal={this.toggleOpenModal}
							openSession={this.openSession}
							error={state.codeError}
						/>
					}
					{state.showCreateModal &&
						<CreateModal
							medias={props.medias}
							appInfo={props.appInfo}
							info={props.appInfo.gameSession.modals.create}
							isMobile={props.isMobile}
							toggleMenuModal={this.toggleMenuModal}
							toggleCreateModal={this.toggleCreateModal}
							newSession={this.newSession}
						/>
					}
				</div>
		);
	}
}
