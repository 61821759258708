import { Component } from 'preact';
import parse from 'html-react-parser';
import { log, copyToClipboard, highlightKeyword } from 'Components/common';

export default class Legend extends Component {
	
	isLegend = props =>
		props.legend &&
		props.legend[props.lang];
		
	state = {
		isLegend: this.isLegend(this.props)
	}

	componentDidMount() {
		log('init card legend');
	}

	componentWillUpdate(newProps, newState) {
		const isLegend = this.isLegend(newProps);
		newState.isLegend = isLegend;
	}

	render(props, state) {
		return (
			state.isLegend &&
			<small onClick={() => state.isLegend && props.isMobile && copyToClipboard(props.legend[props.lang], props.showSnackbar)}>{state.isLegend && props.keyword ? parse(highlightKeyword(props.legend[props.lang], props.keyword)) : parse(props.legend[props.lang])}</small>
		);
	}
}
