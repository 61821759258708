import { Component } from 'preact';
import { Link } from 'preact-router/match';
import { Text } from 'preact-i18n';
import { log } from 'Components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './style';

export default class Menu extends Component {
	showPopup = () => {
		this.props.showModal(null, { simplePopup: true });
	}

	showCardModal = link => {
		this.props.toggleFlap(null);
		this.props.showModal(Number(link.split('/')[1].split('-')[0]));
	}

	handleLink = link => {
		this.props.saveStateOfPage();
		this.props.toggleFlap(`/${this.props.appInfo.lang}/${link}`);
	}

	

	calcHeight = () => {
		let height = 5.2;
		if (this.props.headerMedia) height += 12;
		if (this.props.gameSession && !this.props.gameSession.hideInMenu) height += 15;
		if (this.props.footerMedia) height += 14;
		if (!this.props.appInfo.menu.hideYunow) height += 3.2;
		return height;
	}

	componentDidMount() {
		log('init menu');
		
	}

	render(props) {
		return (
			props.appInfo.menu.entries &&
				<div id="menu" class={style.menu} style={`height: calc(100% - ${this.calcHeight()}rem);`}>
					<div class={style.links}>
						{props.appInfo.menu.entries.map((entry, index) => (
							<h2 key={index}>
								{
								entry.type === 'url'
									? <a
										class={style.link}
										href={entry.target}
										onClick={() => props.toggleFlap(null)}
										target={'_blank'}
										rel={'noopener noreferrer'}
									>
										{entry.icon && <FontAwesomeIcon icon={entry.icon} className={style.icon} />}
										{entry.media && props.medias[entry.media] && <img className={style.media} src={props.medias[entry.media].url} alt={(props.medias[entry.media].alt && props.medias[entry.media].alt[props.appInfo.lang]) || ''} />}
										{entry.label}
									</a>
									: entry.type === 'separator'
										? <hr />
										: entry.type === 'content'
											? <span
												class={style.link}
												onClick={() => this.showCardModal(entry.target)}
											>
												{entry.icon && <FontAwesomeIcon icon={entry.icon} className={style.icon} />}
												{entry.media && props.medias[entry.media] && <img className={style.media} src={props.medias[entry.media].url} alt={(props.medias[entry.media].alt && props.medias[entry.media].alt[props.appInfo.lang]) || ''} />}
												{entry.label}
											</span>
											: <Link
												class={style.link}
												activeClassName={style.active}
												href={`/${props.appInfo.lang}/${entry.target}`}
												onClick={() => this.handleLink(entry.target)}
											>
												{entry.icon && <FontAwesomeIcon icon={entry.icon} className={style.icon} />}
												{entry.media && props.medias[entry.media] && <img className={style.media} src={props.medias[entry.media].url} alt={(props.medias[entry.media].alt && props.medias[entry.media].alt[props.appInfo.lang]) || ''} />}
												{entry.label}
											</Link>
								}
							</h2>
						))}
						{props.appInfo.menu.addToHomescreen &&
							<h2 class={style.link} onClick={this.showPopup}>
								<Text id="menu.add_desktop" />
							</h2>
						}
					</div>
				</div>
		);
	}
}
