export let globalVars = {
	color: {
		primary: null,
		primaryTrimed: null,
		secondary: null,
		secondaryTrimed: null,
		primaryText: null,
		secondaryText: null,
		titleColor: 'rgba(0,0,0,1)',
		titleColorTrimed: '0,0,0',
		titleDynamicColor: '255,255,255',
		textColor: 'rgba(0,0,0,1)',
		textColorTrimed: '0,0,0',
		modalColor: 'rgba(255,255,255,1)',
		itemColor: 'rgba(255,255,255,1)',
		itemDynamicColor: '0,0,0',
		topBar: 'rgba(0,0,0,1)',
		topBarText: '255,255,255',
		slider: "rgba(0,0,0,1)",
		menu: null,
		menuText: null,
		pdf: "rgba(255,255,255,1)"
	},
	textFont: null,
	titleFont: null,
	breakpoint: { // see var.scss
		xs: 360,
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200
	}
};
