import { Component, createRef } from 'preact';
import { log, secondsToMinSec } from 'Components/common';
import { menu } from 'Components/settings';
import { Localizer } from 'preact-i18n';
import PlayIcon from 'Assets/icons/play';
import PauseIcon from 'Assets/icons/pause';
import Muted from '../../../../../../assets/icons/Muted';
import Volume from '../../../../../../assets/icons/Volume';
import style from './style';


export default class Video extends Component {
	state = {
		url: this.props.url,
		isPlaying: (this.props.currentTime !== null && this.props.currentTime !== undefined) || false,
		currentTime: this.props.currentTime || 0,
		duration: null,
		currentVolume: this.props.currentVolume || 1,
		muted: this.props.muted || false
	}

	/**
	 * Pause all medias excepts the one to play
	 */
	pauseAllMedias = () => {
		const audios = document.getElementsByTagName('audio');
		for (let i = 0, len = audios.length; i < len; i++) {
			audios[i].pause();
		}
		const mapExplo = document.getElementById('map-explo');
		const videos = document.getElementsByTagName('video');
		for (let j = 0, len = videos.length; j < len; j++) {
			if (videos[j] !== this.player) {
				if (videos[j].id === 'background-video') {
					videos[j].muted = true;
				} else if (mapExplo) {
					const videoExplo = mapExplo.getElementsByTagName('video')[0];
					if (videoExplo) videoExplo.muted = true;
					else videos[j].pause();
				}
				else videos[j].pause();
			}
		}
	}

	playPause = () => {
		if (!this.player) return;
		
		if (this.player.paused) {
			this.setState({ isPlaying: true });
			this.pauseAllMedias();
			return this.player.play();
		}
		this.setState({ isPlaying: false });
		return this.player.pause();
	}

	load = (e) => {
		this.setState({ duration: e.target.duration });
	}

	durationChange = ev => {
		ev.preventDefault();
		ev.stopPropagation();
		if (!this.player) return;
		const value = Number(ev.target.value);
		this.player.currentTime = value;
		this.setState({ currentTime: value });
	}

	change = (e) => {
		if (this.player) {
			if (this.props.setCurrentTime) this.props.setCurrentTime(e.target.currentTime);
			this.setState({ currentTime: e.target.currentTime });
		}
	}

	pause = () => {
		this.player && this.setState({ isPlaying: false });
	}

	volumeChange = ev => {
		ev.preventDefault();
		ev.stopPropagation();
		if (!this.player) return;
		let muted;
		const value = Number(ev.target.value);
		this.player.volume = value;
		if (value === 0) muted = true;
		else muted = false;
		this.player.muted = muted;
		this.setState({ currentVolume: value, muted });
		if (this.props.setCurrentVolume) this.props.setCurrentVolume(value);
	}

	volumeToggle = () => {
		if (!this.player) return;
		this.player.muted = !this.player.muted;
		let currentVolume = !this.player.muted ? this.player.volume || 1 : 0;
		this.player.volume = !this.player.muted ? currentVolume : this.player.volume;
		this.setState(prevState => ({ muted: !prevState.muted, currentVolume }));
		if (this.props.setCurrentVolume) this.props.setCurrentVolume(this.player.volume);
		if (this.props.setMuted) this.props.setMuted(this.player.muted);
	}

	handleLoadStart = () => {
		if (this.player) {
			this.player.volume = this.props.currentVolume || 1;
			this.player.muted = this.props.muted;
		}
	}

	constructor(props) {
		super(props);

		this.player = createRef();
	}

	componentDidMount() {
		log('init video player into slider');
		this.player.currentTime = this.props.currentTime || 0;
	}

	componentDidUpdate(prevProps) {
		/* eslint-disable */
		if (this.props.url !== prevProps.url) {
			this.setState({
				url: this.props.url,
				isPlaying: false,
				currentTime: 0,
				duration: null
			});
			/* eslint-enable */
		}
	}

	render(props, state) {
		const thumbnail = props.media.children && props.media.children[0] && props.media.children[0].url.replace('.webp', '');
		return (
			state.url &&
			<div class={`${props.style.media} ${style.player}`}>
				<video
					key={state.url}
					class="JS_media"
					data-type="VIDEO"
					data-src={state.url}
					data-thumbnail={thumbnail}
					data-legend={props.media.legend && props.media.legend[props.lang]}
					data-alt={(props.media.alt && props.media.alt[props.lang]) || ''}
					data-subtitles={props.media.subtitles && props.media.subtitles[props.lang]}
					poster={thumbnail}
					ref={ref => this.player = ref}
					onloadedmetadata={this.load}
					ontimeupdate={this.change}
					onpause={this.pause}
					onLoadStart={this.handleLoadStart}
					crossorigin="anonymous"
					autoplay={props.currentTime !== null && props.currentTime !== undefined}
				>
					<source src={state.url} />
					{props.media.subtitles && props.media.subtitles[props.lang] &&
						<Localizer>
							<track label={menu[props.lang]} kind="subtitles" src={props.media.subtitles[props.lang]} srclang={props.lang} default />
						</Localizer>
					}
				</video>
				<div
					class={state.isPlaying ? `${style.overlay} ${style.play}` : style.overlay}
					onClick={this.playPause}
				>
					<div class={style.button}><PlayIcon /></div>
				</div>
				<div class={style.controls}>
					<button	class={style.playPause} onClick={this.playPause}>
						<div class={style.button}>
							{state.isPlaying
								? <PauseIcon />
								: <PlayIcon />
							}
						</div>
					</button>
					<div>{secondsToMinSec(state.currentTime)}</div>
					<div class={style.progressBarContainer}>
						<input
							class={style.progressBar}
							type="range"
							step="any"
							max={state.duration}
							value={state.currentTime}
							onInput={this.durationChange}
						/>
					</div>
					<div>{secondsToMinSec(state.duration)}</div>
					{!this.props.isMobile &&
						<div className={style.volume}>
							<div className={style.icon} onClick={this.volumeToggle}>
								{state.muted
									? <Muted />
									: <Volume />
								}
							</div>
							<input
								className={style.volumeLvl}
								type="range"
								step="any"
								max={1}
								min={0}
								value={state.currentVolume}
								onInput={this.volumeChange}
							/>
						</div>
					}
				</div>
			</div>
		);
	}
}
