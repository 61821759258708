import { Component } from 'preact';
import { log } from 'Components/common';
import CountryFlag from 'react-country-flag';
import icon from 'Components/font-icon';
import style from './style';

export default class LanguageTopbar extends Component {

	componentDidMount() {
		log('init language topbar button');
	}

	render(props) {
		return (
			<div id="langBtn" class={style.language}>
				<button class={style.changer} onClick={props.toggleLangFlap}>
					<div class={`${style.cartridge} ${icon.icon_right}`} style={`${props.lang === 'buc' ? 'margin-left: -.6rem;' : ''}`}>
						{props.lang === 'buc' && this.props.showLangFlags
							? <img src='/assets/images/BUC@2x.png' style={`width: 2.4rem; height: 1.8rem; outline: .1rem solid rgb(var(--topBarText)); vertical-align: middle;`} />
							: this.props.showLangFlags
								? <CountryFlag
									countryCode={props.lang === 'zh' ? 'CN' : props.lang === 'ja' ? 'JP' : (props.lang === 'en' || props.lang === 'en-falc') ? 'GB' : props.lang.slice(0,2).toUpperCase()}
									style={{
										width: '2.4rem',
										height: '1.8rem',
										outline: '0.1rem solid rgb(var(--topBarText))'
									}}
									title={props.lang === 'zh' ? 'CN' : props.lang === 'ja' ? 'JP' : (props.lang === 'en' || props.lang === 'en-falc') ? 'GB' : props.lang.slice(0,2).toUpperCase()}
									svg
								  />
								: props.lang === 'buc' ? 'KIB' : this.props.lang.toUpperCase()
						}
					</div>
				</button>
			</div>
		);
	}
}
