import { Component } from 'preact';
import { Text } from 'preact-i18n';
import parse from 'html-react-parser';
import { log, distance } from 'Components/common';
import { MediaBlock } from '../common';

import commonStyle from '../common/style';

let watchID;

export default class AuthenticationGeoloc extends Component {
	state = {
		show: true
	}

	success = position => {
		const dist = distance(position.coords.latitude, position.coords.longitude, this.props.appInfo.geolocAuth.lat, this.props.appInfo.geolocAuth.lng);
		if (dist <= this.props.appInfo.geolocAuth.threshold) {
			if (navigator.geolocation && watchID) navigator.geolocation.clearWatch(watchID);
			this.setState({ show: false }, () => { this.props.updateAuthentificationCheck("geoloc");});
		}
	}

	error = () => {
		console.error('Sorry, no position available.');
	}

	componentDidMount() {
		log('init authentication geoloc popup');
		if (navigator.geolocation) {
			watchID = navigator.geolocation.watchPosition(this.success, this.error, { enableHighAccuracy: true });
		}
		else {
			console.error('Geolocation is not supported by your browser');
		}
	}

	componentWillUnmount() {
		if (navigator.geolocation && watchID) navigator.geolocation.clearWatch(watchID);
	}

	render({ appInfo }, state) {
		const modal = appInfo.geolocAuth.modal;
		return state.show &&
			(<div className={commonStyle.authentication}>
				<MediaBlock {...appInfo}  modalType={modal} />
				<div className={commonStyle.wrapper}>
					<h1 className={commonStyle.appName}>{appInfo.name}</h1>
					<h2 className={commonStyle.title}>
						{modal?.title.trim() !== ''
							? modal.title
							: <Text id="authentication.title" />
						}
					</h2>
					<p class={commonStyle.message}>
						{modal?.description.trim() !== ''
							? parse(modal.description)
							: <Text id="authentication.geoloc.message" />
						}
					</p>
				</div>
			</div>);
	}
}