import { Component } from 'preact';
import BasketButton from 'Components/elements/ui/basket';
import BasketModal from 'Components/elements/ui/modal/basket';
import { db, log } from 'Components/common';

/**
 * Promise to add an item (card) in the basket
 * @param {object} db Dexie database
 * @param {number} itemId id of the card
 */
export const addItemToBasket = async (db, item) => {
	try {
		const res = await db.card.put({
			id: item.id,
			pageId: item.pageId,
			title: item.title,
			media: item.media,
			alt: item.alt,
			timestamp: item.timestamp
		});
		return res;
	}
	catch (error) {
		return Promise.reject('Error while adding the item to basket');
	}
};


/**
 * Promise to remove an item (card) from the basket
 * @param {object} db Dexie database
 * @param {number} itemId id of the card
 */
export const removeItemFromBasket = async (db, itemId) => {
	try {
		const res = await db.card.delete(itemId);
		return res;
	}
	catch (error) {
		return Promise.reject('Error while removing the item from basket');
	}
};


/**
 * Promise to check if an item (card) is in the basket or not
 * @param {object} db Dexie database
 * @param {number} itemId id of the card
 * @returns {boolean} boolean
 */
export const isItemInBasket = async (db, itemId) => {
	try {
		const nbOfItems = await db.card.where('id').equals(itemId).count();
		return !!nbOfItems; // tricks to convert 0 to false, and any other number to true
	}
	catch (error) {
		return Promise.reject('Error while checking item in the basket');
	}
};

/**
 * Promise to get all items (cards) from the basket
 * @param {object} db Dexie database
 * @returns {array} array of cards in DB
 */
export const getItemsFromBasket = async (db) => {
	try {
		const res = await db.card.toArray();
		return res;
	}
	catch (error) {
		return Promise.reject('Error while getting items from basket');
	}
};


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////


export default class BasketController extends Component {

	state = {
		basketCounter: 0,
		cards: null,
		showBasket: false
	}

	/**
	 * Promise to set the state of card array and number of item in the basket
	 * after fetching the basket in database
	 */
	setBasketCounter = async () => {
		try {
			const res = await getItemsFromBasket(db());
			return this.setState({
				cards: res,
				basketCounter: res.length
			});
		}
		catch (error) {
			return log('error', error);
		}
	}

	/**
	 * Open/Close the basket modal
	 */
	toggleBasket = () => {
		this.setState(prevState => ({ showBasket: !prevState.showBasket }));
	};

	/**
	 * Empty the basket content
	 */
	dropBasket = () => {
		getItemsFromBasket(db())
			.then(cards => {
				cards.map(card => this.props.setBasketCartState(false, card.id));
				db().card.clear().then(() => {
					this.setBasketCounter();
				}).catch((err) => {
					console.error(err.message);
				});
			})
			.catch(error => log(error));
	}

	componentDidMount() {
		log('init basket controller');

		this.props.onRefBasket(this); // link this component to his parent
		this.setBasketCounter();
	}

	render(props, state) {
		return (
			state.cards &&
			<>
				{props.showShare &&
					<BasketButton
						toggleBasket={this.toggleBasket}
						basketCounter={state.basketCounter}
						internetIsDisable={props.internetIsDisable}
						share={props.share}
					/>
				}
				{props.showShare && props.share && state.showBasket &&
					<BasketModal
						toggleBasket={this.toggleBasket}
						internetIsDisable={props.internetIsDisable}
						basketCounter={state.basketCounter}
						cards={state.cards}
						setBasketCounter={this.setBasketCounter}
						dropBasket={this.dropBasket}
						appInfo={props.appInfo}
						share={props.share}
						pageId={props.pageId}
						setBasketCartState={props.setBasketCartState}
						isMobile={props.isMobile}
						isPreview={props.isPreview}
					/>
				}
			</>
		);
	}
}
