import { Component } from 'preact';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import RetinaImage from 'react-retina-image';
import style from './style';

export default class Image extends Component {
	getWatermarkPosition = props => {
		const mediaWidth = props.media.size.width;
		const mediaHeight = props.media.size.height;

		const container = document.getElementById('slideContainer');
		const containerWidth = container
			? container.clientWidth
			: null;
		const containerHeight = container
			? container.clientHeight
			: null;

		let watermarkPosition = {};
		watermarkPosition.left = '0px';
		watermarkPosition.bottom = '0px';

		const isMediaWider = mediaWidth >= containerWidth;
		const isMediaHeighter = mediaHeight >= containerHeight;
		const deltaWidth = mediaWidth - containerWidth;
		const deltaHeight = mediaHeight - containerHeight;
		const mediaRatio = mediaWidth / mediaHeight;
		let newWidth;
		let newHeight;

		if (deltaWidth >= deltaHeight) {
			newWidth = isMediaWider
				? containerWidth
				: mediaWidth;
			newHeight = newWidth / mediaRatio;
		}
		else {
			newHeight = isMediaHeighter
				? containerHeight
				: mediaHeight;
			newWidth = newHeight * mediaRatio;
		}

		const left = Math.round(((containerWidth - newWidth) / 2) + 12);
		const bottom = Math.round(((containerHeight - newHeight) / 2) + 12);
		watermarkPosition.left = `${left}px`;
		watermarkPosition.bottom = `${bottom}px`;

		return watermarkPosition;
	}

	render(props) {
		return (
			<>
				{this.props.pinchZoomPan ?
					<PinchZoomPan maxScale={8} position={'center'} zoomButtons={false}>
						<img
							id={props.id}
							class={style.image}
							src={props.url}
							onClick={props.onToggleZoom}
							data-type="IMG"
							data-src={props.url}
							data-legend={props.media && props.media.legend && props.media.legend[props.lang]}
							data-alt={(props.media && props.media.alt && props.media.alt[props.lang]) || ''}
						/>
					</PinchZoomPan>
					: <div
						id={props.id}
						class={`JS_media ${props.style.media}`}
						style={`background-image:url('${props.url}')`}
						onClick={props.onToggleZoom}
						data-type="IMG"
						data-src={props.url}
						data-legend={props.media && props.media.legend && props.media.legend[props.lang]}
						data-alt={(props.media && props.media.alt && props.media.alt[props.lang]) || ''}
					 />
				}
				
				{props.offer === 'free' &&
					<RetinaImage
						class={style.watermark}
						src="/assets/images/watermark-media.png"
						style={`left: ${this.getWatermarkPosition(props).left}; bottom: ${this.getWatermarkPosition(props).bottom}`}
					/>
				}
			</>
		);
	}
}
