import { Component } from 'preact';
import { Localizer, Text, MarkupText } from 'preact-i18n';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { postApp } from 'Components/settings';
import { log, checkNDD } from 'Components/common';
import icons from 'Components/font-icon';
import style from './style';
import formStyle from 'Components/basket/form/style';
import 'react-multi-email/dist/style.css';

export default class FastShareModal extends Component {
    state = {
		emails: [],
		isValid: false,
		senderField: '',
        messageField: this.props.fastShareLink,
        showErrorMsg: false,
        sendPending: false,
        isSent: false,
		isWrongDomain: false
	}

    resetSendProcess = () => {
		setTimeout(() => {
            const emails = [];
            const senderField = '';
			this.setState({ sendPending: false, isSent: false, showErrorMsg: false, emails, senderField, messageField: this.props.fastShareLink });
			this.props.toggleFastShare();
		}, 2000);
	}

    emailsArrToStr = arr => {
		const sep = ',';
		let emailsStr = '';
		arr.map((email, index) => {
			emailsStr += email;
			if (index !== arr.length - 1) emailsStr += sep;
		});
		return emailsStr;
	}

    /**
	 * Format the share message for Studio API and send it
	 * @param {Number} appId id of the current app
	 * @param {Number} pageId id of the current page
	 * @param {string} message message to send
	 * @param {string} emails emails to send the message to
	 * @param {string} senderField free text field
	 * @returns {json} json with success/error message
	 */
     sendFastShareMessage = async (appId, pageId, message, emails, senderField, isPreview) => {
		try {
			const response = await postApp(appId, pageId, Math.floor(Date.now() / 1000), emails, message, senderField, isPreview);
			const json = await response.json();
			if (json.error) return Promise.reject(Error(json.message));
			return json;
		}
		catch (error) {
			return Promise.reject(error);
		}
	}

	/**
	 * Get the message and send them
	 * @param {Event} ev "send to" default form event
	 */
	sendFastShare = ev => {
		ev.preventDefault(); // prevent "send to" default form event

		this.setState({ sendPending: true });
		this.setState({ showErrorMsg: false });

		this.sendFastShareMessage(
			this.props.appInfo.id,
			this.props.pageId,
			this.state.messageField,
			this.emailsArrToStr(this.state.emails),
			this.state.senderField,
			this.props.isPreview
		)
			.then(() => {
				setTimeout(() => this.setState({ sendPending: false, isSent: true }, this.resetSendProcess), 2000);
			}).catch(() => {
				this.setState({ sendPending: false });
				this.setState({ showErrorMsg: true });
			});
	};

    /**
	 * Reset fastShare
	 */
	resetFastShare = () => {
		const emails = [];
		const senderField = '';
		return this.setState({ emails, senderField });
	}

	resetEmail = () => {
		this.setState({ isValid : false, emails: [], senderField: '' });
	}

	/**
	 * Check if email addresses are valid and checkbox is checked if mail storage activated
	 */
	checkValid = mails => {
		const emails = mails ? mails : this.state.emails;
		const isValid = emails.length > 0 && emails.every(email => isEmail(email));
		if (this.props.fastShare.restrictedDomains && isValid) {
			const isWrongDomain = emails.some(email => checkNDD(email, this.props.fastShare.restrictedDomains));
			this.setState({ isWrongDomain });
		}
        this.setState({ emails, isValid });
	}

	handleChange = e => {
		this.setState({ senderField: e.target.value });
	}

    handleMessageChange = e => {
		this.setState({ messageField: e.target.value });
	}

	componentDidMount() {
		log('init fast share modal');
	}

	render(props, state) {
		return (
            <div class={style.fastShareModal}>
				<div class={style.popup}>
					<div className={style.wrapper}>
						<header className={style.header}>
							{props.isMobile
								? <>
									<button class={`${style.return} ${icons.icon_arrowLeft}`} onClick={props.toggleFastShare} type="button" />
									<h2 className={style.title}>
										{props.fastShare && props.fastShare.title
											? props.fastShare.title
											: <Text id="fastShare.title" />
										}
									</h2>
								  </>
								: <>
									<h2 className={style.title}>
										{props.fastShare && props.fastShare.title
											? props.fastShare.title
											: <Text id="fastShare.title" />
										}
									</h2>
									<button class={`${style.close} ${icons.icon_close}`} onClick={props.toggleFastShare} type="button" />
								  </>
							}
						</header>
						<div className={style.fastShare}>
							<div class={formStyle.container}>
								<form onsubmit={this.sendFastShare}>
									{props.fastShare.senderField &&
										<div className={formStyle.freeContainer}>
											<div className={formStyle.freeField}>
												<Localizer>
													<input
														class={formStyle.input}
														type="text"
														onChange={this.handleChange}
														value={state.senderField}
														placeholder={<Text id="fastShare.from" />}
													/>
												</Localizer>
											</div>
										</div>
									}
									<div className={style.message}>
										<textarea
											className={formStyle.input}
											type="text"
											value={state.messageField}
											onInput={this.handleMessageChange}
										/>
									</div>
									<div className={formStyle.flexContainer}>
										<div className={formStyle.emailInput}>
											<Localizer>
												<ReactMultiEmail
													className={state.emails.length > 0 ? formStyle.input : `${formStyle.input} ${formStyle.fullWidth}`}
													placeholder={<Text id="fastShare.to" />}
													emails={state.emails}
													onChange={(emails) => {
														this.checkValid(emails);
													}}
													validateEmail={email => {
														return isEmail(email);
													}}
													getLabel={(email, index, removeEmail) => {
														return (
															<div className={formStyle.tag} data-tag key={index}>
																<span className={formStyle.text}>{email}</span>
																<span className={`${formStyle.icon} ${formStyle.iconCloseTag} ${icons.icon_close}`} data-tag-handle onClick={() => removeEmail(index)} />
															</div>
														);
													}}
												/>
											</Localizer>
										</div>
									</div>
									{state.showErrorMsg &&
										<div class={`${formStyle.error} ${icons.icon_error}`}>
											<MarkupText id="basket.error" />
										</div>
									}
									{state.isWrongDomain &&
										<div class={`${formStyle.error} ${icons.icon_error}`}>
											<Text id="basket.wrongDomain" />
										</div>
									}
									<button
										class={formStyle.submit}
										type="submit"
										disabled={(props.internetIsDisable || state.sendPending || !state.isValid) && 'disabled'}
									>
										{state.sendPending
											? <div className={formStyle.pending} />
											: !state.isSent
												? <Text id="basket.submit" />
												: <Text id="basket.success" />
										}
									</button>
								</form>
							</div>
						</div>
                    </div>
                </div>
            </div>
		);
	}
}