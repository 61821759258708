import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { log, isLastPage, getNextPageLink, customRoute } from 'Components/common';
import { globalVars } from 'Components/globalVars';
import parse from 'html-react-parser';
import Arrow from 'Components/elements/buttons/arrow';
import CustomButton from 'Components/elements/buttons/custom';
import Media from 'Components/elements/ui/media';
import Slick from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from './style';

export default class GameTimerPopup extends Component {
	state = {
		show: false,
		time: this.props.gameTimer
	}

	/**
	 * Show the timeout popup
	 */
	show = () => {
		this.setState({ show: true });
	}

	/**
	 * Close the timeout popup
	 */
	close = () => {
		this.setState({ show: false });
	}

	/**
	 * Execute an action depending on the button type
	 */
	 goTo = button => {
		switch (button.type) {
			case 'close':
				this.close();
				break;
			case 'openContent':
				this.props.showModal(Number(button.target.split('/')[1].split('-')[0]));
				break;
			case 'home':
			case 'goToPage':
				this.props.saveStateOfPage();
				customRoute(`/${this.props.appInfo.lang}/${button.target}`, this.props.appInfo.id);
				this.close();
				break;
			case 'openURL':
				window.open(button.target);
				break;
			case 'refresh':
				customRoute(window.location.pathname, this.props.appInfo.id, true);
				break;
			case 'next':
				this.props.saveStateOfPage();
				customRoute(`/${this.props.appInfo.lang}/${getNextPageLink(this.props)}`, this.props.appInfo.id);
				break;
			case 'previous':
			case 'previousState':
				history.back();
				break;
		}
	}

	getSliderSettings = () => (
		{
			draggable: true,
			swipe: true,
			dots: true,
			infinite: false,
			arrows: true,
			nextArrow: <Arrow side="next" />,
			prevArrow: <Arrow side="prev" />,
			responsive: [{
				breakpoint: globalVars.breakpoint.md,
				settings: {
					arrows: false,
					draggable: false,
					swipe: true
				}
			}]
		}
	)

	componentDidMount() {
		log('init GameTimerPopup');
	}

	render(props, state) {
		const gameTimerModal = this.props.modalInfo || this.props.page.modals.find(modal => modal.type === 'chrono');
		return (state.show && gameTimerModal) && (
			<div class={`${style.gameTimerPopup} ${this.props.modalInfo ? style.app : ''}`}>
				<div class={style.popup}>
					<button class={style.skip} onClick={this.close}>
						<Text id="popup.appIntro.skip" />
					</button>
					<div className={`${style.content} ${style.noTopPadding}`}>
						<div className={style.container}>
							{gameTimerModal.medias.length > 0
								? <Slick {...this.getSliderSettings()} className={style.slider}>
									{gameTimerModal.medias.map(media => (
										<Media key={media} media={props.medias[media]} lang={props.appInfo.lang} />
									))}
								</Slick>
								: <div className={style.slider}><img src={`/assets/images/game-timeout.svg`} alt="" /></div>
							}
							<div className={`${style.wrapper} ${gameTimerModal.medias.length > 0 && style.top} ${gameTimerModal.medias.length === 1 && style.noMarginTop}`}>
								<h1 class={style.title}>
									{gameTimerModal.title
										? gameTimerModal.title
										: <Text id="timeOut.title" />
									}
								</h1>
								<div class={style.description}>
									{gameTimerModal.description
										? parse(gameTimerModal.description)
										: <>
											{state.time > 30
												? <Text id="timeOut.time.minutes" fields={{ time: state.time / 60 }} plural={state.time / 60} />
												: <Text id="timeOut.time.seconds" fields={{ time: state.time }} />
											}
											<Text id="timeOut.indication" />
										</>
									}
								</div>
							</div>
						</div>
						<div class={style.buttons}>
							{(gameTimerModal.buttons && gameTimerModal.buttons.length > 0)
								? gameTimerModal.buttons.map((button, index) => (
									<CustomButton
										key={index}
										appInfo={props.appInfo}
										medias={props.medias}
										customStyle={style.custom}
										action={() => this.goTo(button)}
										button={button}
									/>
								  ))
								: !this.props.modalInfo &&
									<>
										<button class={style.cta} onClick={this.close}>
											<Text id="timeOut.button.continue" />
										</button>
										<button class={style.cta} onClick={this.goTo(getNextPageLink(props))}>
											{isLastPage(props)
												? <Text id="timeOut.button.home" />
												: <Text id="timeOut.button.next" />
											}
										</button>
									</>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
