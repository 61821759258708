import { Component } from 'preact';
import { Text, Localizer } from 'preact-i18n';
import { log, storeItem, uniqueId } from 'Components/common';
import SessionName from '../../widget';
import style from './style';

export default class CreateModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pseudo: '',
			avatar: null
		};
	}

	save = () => {
		storeItem('local', 'gameSession', { pseudo: this.state.pseudo, avatar: this.state.avatar, uniqueId: uniqueId() });
		if (this.props.newSession) {
			this.props.newSession(true);
		}
		else {
			this.props.closeCreateModal(true);
		}
	}

	avatarClicked = avatar => {
		this.setState({ avatar });
	}

	handlePseudoChange = event => {
		this.setState({ pseudo: event.target.value });
	}

	componentDidMount() {
		log('init game session CREATE modal');
	}

	render() {
		return (
			<div className={style.createModal}>
				<div className={style.popup}>
					<div className={style.container}>
						<div className={style.wrapper}>
							<h1 className={style.label}>{this.props.info.pseudoLabel ? this.props.info.pseudoLabel : <Text id="gameSession.modals.create.pseudoLabel" />}</h1>
							<Localizer>
								<input
									className={style.pseudoInput}
									type="text"
									value={this.state.pseudo}
									onInput={this.handlePseudoChange}
									placeholder={<Text id="gameSession.modals.create.placeholder" />}
								/>
							</Localizer>
							<h1 className={style.label}>{this.props.info.avatarLabel ? this.props.info.avatarLabel : <Text id="gameSession.modals.create.avatarLabel" />}</h1>
							<div className={style.avatars}>
								{this.props.info.avatars.map(avatarId => {
									let media = {};
									if (this.props.medias[avatarId]) {
										media.src = this.props.medias[avatarId].url;
										media.alt = this.props.medias[avatarId].alt && this.props.medias[avatarId].alt[this.props.appInfo.lang] || '';
									}
									return <img key={avatarId} src={media.src} alt={media.alt} onClick={() => this.avatarClicked(avatarId)} />;
								})}
							</div>
							<h1 className={style.label}><Text id="common.preview" /></h1>
							<SessionName
								pseudo={this.state.pseudo}
								avatar={this.state.avatar}
								medias={this.props.medias}
								lang={this.props.appInfo.lang}
								show
								preview
							/>
						</div>
						<button className={style.submit} onClick={this.save}>
							<Text id="gameSession.modals.create.button" />
						</button>
					</div>
				</div>
			</div>
		);
	}
}
