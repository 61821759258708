import { Component } from 'preact';
import ScreenLoader from 'Components/elements/ui/screenLoader';
import ZoomButton from 'Components/elements/buttons/zoom';
// https://gltf-viewer.donmccurdy.com/ -> check for futur implementation enhancements
import * as THREE from 'three';
import { initOrbits } from './setup';
import { start, stop } from './animation';
import { loadOBJ } from './objLoader';
import { loadGLTF } from './loadGLTF';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';

import style from './style';

export default class Three extends Component {
	state = {
		showLoader: true,
		objectLoadingPerc: null
	}

	init3dViewer = () => {
		if (this.frameId && this.controls) stop(this.frameId, this.controls);
		if (this.renderer && this.renderer.domElement) this.dispose();
		
		// get container dimensions and use them for scene sizing
		const width = this.canvas.clientWidth;
		const height = this.canvas.clientHeight;

		// add scene
		this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color(0x191919);

		// add camera
		this.camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1000);
		this.scene.add(this.camera);

		// add renderer
		this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.renderer.useLegacyLights = false;
		this.renderer.setClearColor(0xcccccc);
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(width, height);

		this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
		this.pmremGenerator.compileEquirectangularShader();
	
		this.neutralEnvironment = this.pmremGenerator.fromScene(new RoomEnvironment()).texture;

		this.renderer.toneMapping = Number(THREE.LinearToneMapping);
		this.renderer.toneMappingExposure = .75;

		Promise.resolve({ envMap: this.neutralEnvironment} ).then(({ envMap }) => {
			this.scene.environment = envMap;
		});

		// add controls
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.screenSpacePanning = true;
		initOrbits(this.controls);

		this.canvas.appendChild(this.renderer.domElement);

		const ext = this.props.media.url.split('.webp')[0].split('.').pop().toUpperCase();
		if (ext === 'OBJ') {
			const texture = this.props.media.children && this.props.media.children[0] && this.props.media.children[0].url;
			// load object
			loadOBJ(this, THREE, `${texture}?crossOrigin`, this.props.media.url);
		}
		else
			// load gltf/glb
			loadGLTF(this, THREE, this.props.media.url);

		// start the animation
		start(this.frameId, this.renderer, null, this.scene, this.camera, this.controls);
	}

	dispose = () => {
		// destroy canvas
		this.canvas.removeChild(this.renderer.domElement);

		// traverse the scene for all disposables
		this.scene.traverse(obj => {
			// dispose materials
			if (obj.material) {
				// a mesh's material may be an array
				if (Array.isArray(obj.material)) {
					obj.material.forEach(mtl => {
						mtl.dispose();
					});
				}
				// dispose textures
				for (const key of Object.keys(obj.material)) {
					const value = obj.material[key];
					if (value && typeof value === 'object' && 'minFilter' in value) {
						value.dispose();
					}
				}
				obj.material.dispose();
			}

			// dispose geometry
			if (obj.geometry) {
				obj.geometry.dispose();
				obj.geometry.attributes.color = {};
				obj.geometry.attributes.normal = {};
				obj.geometry.attributes.position = {};
				obj.geometry.attributes.uv = {};
				obj.geometry.attributes = {};
				obj.material = {};
			}			
		});

		for (let i = this.scene.children.length - 1; i >= 0; i--) { 
			const obj = this.scene.children[i];
			this.scene.remove(obj); 
		}

		this.renderer.info.reset();
		this.renderer.clear();
		this.renderer.forceContextLoss();
		this.renderer.dispose();
		this.scene.remove();

		this.renderer.domElement = null;
		this.renderer = null;
		this.scene = null;
		this.camera = null;
		this.pmremGenerator = null;
		this.neutralEnvironment = null;
		this.controls = null;
		THREE.Cache.clear();
	}

	/**
	 * Active zoom on a media by clicking the zoom button
	 */
	zoomClicked = () => {
		const media = document.getElementById(`container-${this.props.index}`).getElementsByClassName('JS_media')[0];
		const e = { target: media };
		// open the media in zoom mode
		this.props.onToggleZoom(e);
	}

	componentDidMount() {
		this.init3dViewer();
		window.addEventListener('resize', this.init3dViewer);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.init3dViewer);
		// stop the animation
		stop(this.frameId, this.controls);

		this.dispose();
	}

	render() {
		return (
			<div
				class={`JS_media ${this.props.style && this.props.style.media} ${style.three}`}
				ref={c => { this.canvas = c; }}
				data-type="3D"
				data-src={JSON.stringify(this.props.media)}
				data-legend={this.props.media.legend && this.props.media.legend[this.props.lang]}
				data-alt={(this.props.media.alt && this.props.media.alt[this.props.lang]) || ''}
			>
				<ZoomButton show={this.props.bloc && this.props.bloc.canFullscreen} click={this.zoomClicked} />
				{this.state.showLoader && <ScreenLoader show={this.state.showLoader} loading={this.state.objectLoadingPerc} emptyTopbar />}
			</div>
		);
	}
}
