import { Component } from 'preact';
import { Text, MarkupText, Localizer } from 'preact-i18n';
import { log, storeItem } from 'Components/common';
import { cipherSecret } from 'Components/settings';
import parse from 'html-react-parser';
import AES from 'crypto-js/aes';
import SaveModalIcon from 'Assets/icons/SaveModalIcon';
import icon from 'Components/font-icon';
import style from './style';
import formStyle from 'Components/basket/form/style';

export default class SaveModal extends Component {
	state = {
		email: '',
		checked: false,
		isChecked: true,
		isValid: false,
		showError: false
	}

	closeModal = () => {
		this.props.toggleSaveModal();
		this.props.toggleMenuModal && this.props.toggleMenuModal();
	}

	/**
	 * Handle checkbox checked or not state
	 */
	toggle = () => {
		let checked = !this.state.checked;
		this.setState({ checked }, this.checkValid);
	}

	resetEmail = () => {
		this.setState({ isValid : false, email: '' });
		this.input.focus();
	}

	/**
	 * Check if the email address is valid and checkbox is checked if mail storage activated
	 */
	checkValid = e => {
		this.props.error && this.props.resetError();
		const email = e ? e.target.value : this.state.email;
		this.setState({ email });
		const isValid = e ? e.target.validity.valid : this.state.isValid;
		if (!this.state.checked) { 
			this.setState({
				isValid,
				isChecked: false,
				showError: true
			}, () => this.callbackScroll());
		}
		else {
			this.setState({ isValid, isChecked: true, showError: false });
		}
	}

	saveSession = ev => {
		ev.preventDefault(); // prevent "send to" default form event

		const storedGameSession = localStorage.getItem('gameSession');
		try {
			let gameSession = JSON.parse(storedGameSession) || {};
			gameSession.cipherText = AES.encrypt(this.state.email, cipherSecret).toString();

			const strGameSession = JSON.stringify(gameSession);
			storeItem('local', 'gameSession', gameSession);
			this.props.saveGameSession(this.props.appInfo.id, strGameSession, this.state.email);
		}
		catch {
			log('Error saving game session from modal');
		}
	}

	callbackScroll = () => {
		const element = document.getElementsByClassName(style.saveContainer)[0];
		element && element.scrollTo(0, element.scrollHeight);
	}

	componentDidMount() {
		log('init game session SAVE modal');
	}

	componentDidUpdate(prevProps) {
		if (prevProps.error !== this.props.error) {
			const element = document.getElementsByClassName(style.saveContainer)[0];
			element && element.scrollTo(0, element.scrollHeight);
		}
	}

	render(props, state) {
		return (
			<div className={style.saveModal}>
				<div className={style.popup}>
					<header className={style.header}>
						<button class={`${style.return} ${icon.icon_arrowLeft}`} onClick={props.toggleSaveModal} type="button">
							<Text id="menu.back" />
						</button>
						{!props.isMobile && <button class={`${style.close} ${icon.icon_close}`} onClick={this.closeModal} type="button" />}
					</header>
					<div className={style.saveContainer}>
						<div className={style.media}>
							{props.medias[props.info.media]
								? <img src={props.medias[props.info.media].url} alt={(props.medias[props.info.media].alt && props.medias[props.info.media].alt[props.appInfo.lang]) || ''} />
								: <SaveModalIcon />
							}
						</div>
						<div className={style.wrapper}>
							<h1 className={style.title}>{props.info.title ? props.info.title : <Text id="gameSession.modals.save.title" />}</h1>
							{props.info.description
								? parse(props.info.description)
								: <Text id="gameSession.modals.save.description" />
							}
						</div>
						<form className={formStyle.container} onsubmit={e => this.saveSession(e)}>
							<div className={formStyle.flexContainer}>
								<div className={formStyle.emailInput}>
									<Localizer>
										<input
											ref={(input) => { this.input = input; }}
											class={formStyle.input}
											placeholder={<Text id="basket.email_address" />}
											type="email"
											pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
											onInput={this.checkValid}
											value={state.email}
											required
										/>
									</Localizer>
									{state.email.length > 0 &&
										<button class={`${formStyle.icon} ${formStyle.iconClose} ${icon.icon_close}`} onClick={this.resetEmail} type="button" />
									}
								</div>
								<button
									class={`${formStyle.submit} ${style.submit} ${props.isMobile && icon.icon_arrowRight}`}
									type="submit"
									disabled={(props.internetIsDisable || !state.isValid || !state.isChecked) && 'disabled'}
								>
									{!props.isMobile &&	<Text id="basket.submit" />}
								</button>
							</div>
							<label class={formStyle.checkContainer}>
								<input
									type="checkbox"
									checked={state.checked}
									onClick={this.toggle}
								/>
								<span class={formStyle.checkmark} />
								<small class={formStyle.text}>
									<MarkupText	id="authentication.mail.conditions"	/>
								</small>
							</label>
							{(!state.isChecked || state.showError || props.error) &&
								<div class={`${formStyle.error} ${icon.icon_error}`}>
									{state.showError
										? <Text id="authentication.mail.notAccepted" />
										: <Text id="gameSession.error" />
									}
								</div>
							}
						</form>
					</div>
				</div>
			</div>
		);
	}
}
