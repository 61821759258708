import { Component } from 'preact';

import PlayIcon from 'Assets/icons/play';
import style from './style';

export default class AudioComponent extends Component {
	state = {
		play: false
	}

	/**
	 * Pause all medias excepts the one to play
	 */
	play = () => {
		const audios = document.getElementsByTagName('audio');
		for (let i = 0, len = audios.length; i < len; i++) {
			if (audios[i] !== this.audio) {
				audios[i].pause();
			}
		}
		const mapExplo = document.getElementById('map-explo');
		const videos = document.getElementsByTagName('video');
		for (let j = 0, len = videos.length; j < len; j++) {
			if (videos[j].id === 'background-video') {
				videos[j].muted = true;
			} else if (mapExplo) {
				const videoExplo = mapExplo.getElementsByTagName('video')[0];
				if (videoExplo) videoExplo.muted = true;
				else videos[j].pause();
			}
			else videos[j].pause();
		}
		this.audio.play();
	}

	pause = () => {
		this.audio && this.audio.pause();
		this.setState({ play: false });
	}

	togglePlay = () => {
		this.setState({ play: !this.state.play }, () => {
			this.state.play && this.audio ? this.play() : this.audio.pause();
		  });
	}
	
	componentWillUnmount() {
		this.pause();
		this.audio = null;
	}

	render(props, state) {
		const thumbnail = props.media.children && props.media.children[0] && props.media.children[0].url !== '%2Fbundles%2Fbiinapi%2Fimg%2Fdefaut-sound-picture.jpg' ? props.media.children[0].url : '';
		return (
			props.url &&
				<div class={style.player}>
					{thumbnail !== '' &&
						<img
							class={style.image}
							src={thumbnail}
							alt={(props.media.children && props.media.children[0] && props.media.children[0].alt && props.media.children[0].alt[props.lang]) || ''}
						/>}
					<audio ref={a => this.audio = a} onPause={this.pause} onEnded={this.pause}>
						<source src={props.url} />
					</audio>
					<div onClick={this.togglePlay} class={state.play ? `${style.overlay} ${style.play}` : style.overlay}>
						<div class={style.iconPlay}>
							<PlayIcon />
						</div>
					</div>
				</div>
		);
	}
}
