import { Component } from 'preact';
import { log } from 'Components/common';
import { Text } from 'preact-i18n';

import PdfIcon from 'Assets/icons/pdf';
import style from './style';

export default class Pdf extends Component {

	showPDF = () => {
		const audios = document.getElementsByTagName('audio');
		for (let i = 0, len = audios.length; i < len; i++) {
			audios[i].pause();
		}
		const mapExplo = document.getElementById('map-explo');
		const videos = document.getElementsByTagName('video');
		for (let j = 0, len = videos.length; j < len; j++) {
			if (videos[j].id === 'background-video') {
				videos[j].muted = true;
			} else if (mapExplo) {
				const videoExplo = mapExplo.getElementsByTagName('video')[0];
				if (videoExplo) videoExplo.muted = true;
				else videos[j].pause();
			}
			else videos[j].pause();
		}
		this.props.showPDF(this.props.media, this.props.canFullscreen);
	}

	componentDidMount() {
		log('init slide pdf');
	}

	render(props) {
		const thumbnail = props.media.children && props.media.children[0] && props.media.children[0].url;
		return (
			<div
				class={`JS_media ${props.style.media} ${style.pdf}`}
				data-type="IMG"
				data-src={thumbnail}
			>
				<img src={thumbnail} class={style.image} alt={(props.media.children && props.media.children[0] && props.media.children[0].alt && props.media.children[0].alt[props.lang]) || ''} />
				<div
					class={style.overlay}
					onClick={this.showPDF}
				>
					<div class={style.pictoContainer}>
						<div class={style.picto}>
							<PdfIcon />
						</div>
						<small class={style.text}>
							<Text id="pdf.open" />
						</small>
					</div>
				</div>
			</div>
		);
	}
}