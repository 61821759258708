import { Component } from 'preact';
import { log } from 'Components/common';

import style from './style';

export default class Title extends Component {

	componentDidMount() {
		log('init title screen');
	}

	render(props) {
		return (
			props.show &&
			<h1 class={style.title}>{props.title}</h1>
		);
	}
}
