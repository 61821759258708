import { Component } from 'preact';
import { log } from 'Components/common';
import { menu } from 'Components/settings';
import { globalVars } from 'Components/globalVars';
import Arrow from 'Components/elements/buttons/arrow';
import CustomButton from 'Components/elements/buttons/custom';
import Media from 'Components/elements/ui/media';
import LanguagesList from './list';
import Slick from 'react-slick';
import CountryFlag from 'react-country-flag';
import parse from 'html-react-parser';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import icon from 'Components/font-icon';
import style from './style';


export default class LanguageModal extends Component {
	state = {
		isLangListOpened: false
	}

	goTo = button => {
		this.props.goTo(button)();
	}

	toggleLanguagesList = () => {
		const isLangListOpened = !this.state.isLangListOpened;
		this.setState({ isLangListOpened });
	}

	getSliderSettings = () => (
		{
			draggable: true,
			swipe: true,
			dots: true,
			infinite: false,
			arrows: true,
			nextArrow: <Arrow side="next" />,
			prevArrow: <Arrow side="prev" />,
			responsive: [{
				breakpoint: globalVars.breakpoint.md,
				settings: {
					arrows: false,
					draggable: false,
					swipe: true
				}
			}]
		}
	)

	componentDidMount() {
		log('init appIntro language');
	}

	render(props, state) {
		return (
			<div class={props.modal.medias.length > 0 ? `${style.content} ${style.noTopPadding}` : style.content}>
				<div className={style.container}>
					{props.modal.medias.length > 0 &&
						<Slick {...this.getSliderSettings()} className={style.slider}>
							{props.modal.medias.map(media => (
								<Media key={media} media={media} lang={props.appInfo.lang} />
							))}
						</Slick>
					}
					<div className={`${style.wrapper} ${props.modal.medias.length > 0 && style.top} ${props.modal.medias.length === 1 && style.noMarginTop}`}>
						{props.modal.title !== '' &&
							<h1 class={style.title}>{props.modal.title}</h1>
						}
						{props.modal.description !== '' && parse(props.modal.description)}
					</div>
				</div>
				{state.isLangListOpened
					? <LanguagesList
						lang={props.appInfo.lang}
						allLang={props.appInfo.allLang}
						appId={props.appInfo.id}
						showLangFlags={this.props.appInfo.showLangFlags}
						showLangNames={this.props.appInfo.showLangNames}
						setLang={props.setLang}
						goTo={this.goTo}
						close={this.toggleLanguagesList}
					  />
					: <div className={`${style.langToggle} ${icon.icon_chevronDown}`} onClick={this.toggleLanguagesList}>
						{props.appInfo.lang === 'buc' && this.props.appInfo.showLangFlags
							? <img src='/assets/images/BUC@2x.png' style={`border-radius: .2rem; width: 2.2rem; height: 1.6rem; margin: 0 .4rem .1rem 0; outline: .1rem solid #ccc; object-fit: unset;`} />
							: this.props.appInfo.showLangFlags &&
								<CountryFlag
									countryCode={props.appInfo.lang === 'zh' ? 'CN' : props.appInfo.lang === 'ja' ? 'JP' : (props.appInfo.lang === 'en' || props.appInfo.lang === 'en-falc') ? 'GB' : props.appInfo.lang.slice(0,2).toUpperCase()}
									style={{
										'border-radius': '.2rem', //TODORADIUS
										width: '2.2rem',
										height: '1.6rem',
										margin: '0 .4rem .1rem 0',
										outline: '.1rem solid #ccc',
										'object-fit': 'unset'
									}}
									title={props.appInfo.lang === 'zh' ? 'CN' : props.appInfo.lang === 'ja' ? 'JP' : (props.appInfo.lang === 'en' || props.appInfo.lang === 'en-falc') ? 'GB' : props.appInfo.lang.slice(0,2).toUpperCase()}
									svg
								/>
						}
						{this.props.appInfo.showLangNames && menu[props.appInfo.lang]}
					  </div>
				}
				<div class={style.buttons}>
					{(props.modal.buttons && props.modal.buttons.length > 0) &&
						props.modal.buttons.map((button, index) => (
							<CustomButton
								key={index}
								appInfo={props.appInfo}
								customStyle={style.custom}
								action={() => this.goTo(button)}
								button={button}
							/>
						))
					}
				</div>
			</div>
		);
	}
}
