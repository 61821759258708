import './style';
import smoothscroll from 'smoothscroll-polyfill';
import { getDynamicManifestURL, getDynamicFaviconURL } from './components/common';
import App from './components/app';

smoothscroll.polyfill();
document.querySelector("link[rel*='manifest']").setAttribute('href', getDynamicManifestURL());

let iOSIcon = document.querySelector("link[rel='apple-touch-icon']") || document.createElement('link');
iOSIcon.href = getDynamicFaviconURL(true);
iOSIcon.rel = 'apple-touch-icon';
iOSIcon.sizes = '180x180';
document.head.appendChild(iOSIcon);

let link = document.querySelector("link[rel='shortcut icon']") || document.querySelector("link[rel='icon']") || document.createElement('link');
link.href = getDynamicFaviconURL();
link.rel = 'shortcut icon';
link.type = 'image/x-icon';
document.head.appendChild(link);

export default App;
