import { Component } from 'preact';
import { Localizer, Text, MarkupText } from 'preact-i18n';
import { log } from 'Components/common';
import Menu from 'Components/menu';
import GameSession from 'Components/gameSession';
import YunowLogo from 'Assets/icons/favicon-32x32.png';
import icons from 'Components/font-icon';
import style from './style';


export default class MenuFlap extends Component {
	isFullyOpen = () => {
		const menuFlap = document.getElementById('menuFlap');
		const menuFlapCss = window.getComputedStyle(menuFlap, null);
		return menuFlapCss.left === '0px';
	}

	isInMenu = el => {
		let e = el;
		let inMenu = false;
		while (e) {
			if (e.id === 'menuFlap') {
				inMenu = true;
				break;
			}
			e = e.parentElement;
		}
		return inMenu;
	}

	componentDidMount() {
		log('init MenuFlap');

		document.onclick = ev => {
			const el = ev.target;
			if (this.isFullyOpen() && !this.isInMenu(el)) this.props.toggleFlap();
		};
	}

	componentWillUnmount() {
		document.onclick = null;
	}

	render(props) {
		const headerMedia = props.medias[props.appInfo.menu.headerImg];
		const footerMedia = props.medias[props.appInfo.menu.footerImg];
		return (
			<div id="menuFlap" class={props.isFlapOpen ? `${style.menuFlap} ${style.open}` : style.menuFlap}>
				<button className={`${style.close} ${icons.icon_arrowLeft} ${props.style}`} onClick={() => props.toggleFlap(null)} />
				<img className={style.headerImg} src={headerMedia && headerMedia.url} alt={(headerMedia && headerMedia.alt && headerMedia.alt[props.appInfo.lang]) || ''} />
				{props.appInfo.gameSession &&
					<GameSession
						onRefGameSession={props.onRefGameSession}
						medias={props.medias}
						appInfo={props.appInfo}
						toggleFlapMenu={props.toggleFlap}
						isMobile={props.isMobile}
						internetIsDisable={props.internetIsDisable}
						showSnackbar={props.showSnackbar}
						gameSessionPercent={props.gameSessionPercent}
						newGameSession={props.newGameSession}
						closeCreateModal={this.props.closeCreateModal}
					/>
				}
				<Menu
					appInfo={props.appInfo}
					medias={props.medias}
					toggleFlap={props.toggleFlap}
					showModal={props.showModal}
					gameSession={props.appInfo.gameSession}
					saveStateOfPage={props.saveStateOfPage}
					headerMedia={headerMedia}
					footerMedia={footerMedia}
				/>
				{props.appInfo.menu.footerImg &&
					<img className={style.footerImg} src={footerMedia && footerMedia.url} alt={(footerMedia && footerMedia.alt && footerMedia.alt[props.appInfo.lang]) || ''} />
				}
				{!props.appInfo.menu.hideYunow && 
					<div className={style.yunowCredits}>
						<Localizer>
							<img className={style.logo} src={YunowLogo} alt={<Text id="app.logo" />} />
						</Localizer>
						<small>
							<MarkupText id="app.credits" />
						</small>
					</div>
				}
			</div>
		);
	}
}
