import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { fitCameraToObject } from './setup';

const onLoad = (c, THREE, material) => object => {
	c.setState({ objectState: 'loaded' });
	// apply texture on each object's mesh
	object.traverse(node => {
		if (node.isMesh) node.material = material;
	});

	fitCameraToObject(THREE, c.camera, object, c.controls);

	c.scene.add(object);

	c.setState({ showLoader: false });
};

const onProgress = c => xhr => {
	const objectLoadingPerc = Math.round(xhr.loaded / xhr.total * 100);
	c.setState({ objectLoadingPerc });
};

const onError = err => {
	console.error('An error happened', err);
};

export const loadOBJ = (c, THREE, texturePath, objectPath) => {
	// create texture loader
	const textureLoader = new THREE.TextureLoader();
	const map = textureLoader.load(texturePath);

	// create material
	const material = new THREE.MeshBasicMaterial({
		map,
		side: THREE.DoubleSide
	});

	// create object loader
	const objLoader = new OBJLoader();

	// load object and material
	objLoader.load(
		objectPath,
		onLoad(c, THREE, material),
		onProgress(c),
		onError
	);
};
