import { Component } from 'preact';
import { log } from 'Components/common';
import { menu } from 'Components/settings';
import { Localizer } from 'preact-i18n';
import { globalVars } from 'Components/globalVars';
import Arrow from 'Components/elements/buttons/arrow';
import parse from 'html-react-parser';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import Slick from 'react-slick';
import Slide from '../../../card/common/slider/slide';
import Legend from '../../../card/common/slider/legend';
import Three from '../../../card/blocks/media/three';
import style from './style';
import icon from 'Components/font-icon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class Zoom extends Component {
	state = {
		show: false,
		// content: null,
		// type: null,
		// thumbnail,
		// legend,
		// subtitles,
		// sliderInfo,
		// index,
		// currentTime,
		// currentVolume, muted
	}

	/**
	 * Init slick slider with carousel content and on the desired slide
	 */
	 initSlider = () => {
		this.setState({ sliderMedia: this.sliderMedia });
		this.sliderMedia && this.sliderMedia.slickGoTo(this.state.index);
		this.setHeights();
	}

	/**
	 * Compute heights of slides and legend blocks
	 */
	setHeights = () => {
		const z = document.getElementById('zoom-wrapper');
		if (z) {
			const slides = z.getElementsByClassName('slick-slide');
			if (slides && slides.length > 0) {
				for (let i = 0; i < slides.length; i++) {
					const legend = document.getElementById(`zoom-slide-legend-${i}`);
					const legendHeight = legend ? (legend.clientHeight / 10) : 0;
					const slide = slides[i].getElementsByClassName(style.slide)[0];
					slide && slide.style.setProperty('height', `calc(100% - 2.2rem - ${legendHeight}rem - ${legendHeight ? '.8rem' : '0rem'})`);
				}
			}
		}
	}

	/**
	 * Show/hide zoom content
	 * @param {HTMLelement} el HTML element
	 * @param {Object} sliderInfo the carousel information if zoom was triggered from one
	 */
	toggle = (el, sliderInfo, callback) => {
		if (this.state.show && this.state.sliderInfo && this.state.currentTime) {
			this.state.callback(this.state.currentTime, this.state.currentVolume, this.state.muted);
		}
		else if (this.state.show && this.player) {
			this.state.callback(this.player.currentTime, this.player.volume, this.player.muted);
		}
		this.setState({
			show: !this.state.show,
			content: el.target.dataset.src,
			type: el.target.dataset.type,
			thumbnail: el.target.dataset.thumbnail,
			legend: el.target.dataset.legend && parse(el.target.dataset.legend),
			subtitles: el.target.dataset.subtitles,
			alt: el.target.dataset.alt,
			index: el.index,
			sliderInfo,
			callback,
			currentTime: el.target.currentTime,
			currentVolume: el.target.volume,
			muted: el.target.muted
		});
	}

	/**
	 * Compute legend block height and substract it from image height
	 */
	setWrapperAndLegendSize = () => {
		const wrapper = document.getElementById('zoom-wrapper');
		const legend = document.getElementById('zoom-legend');
		const zoom = document.getElementById('zoom');
		if (legend) {
			if (legend.offsetHeight / 10 <= 1.7) zoom.style.setProperty('text-align', 'center');
			else zoom.style.setProperty('text-align', 'justify');

			const legendHeight = legend.offsetHeight / 10 >= 8.5 ? 12.5 : 4 + legend.offsetHeight / 10;
			wrapper.style.setProperty('height', 'calc(100% - ' + legendHeight + 'rem');
			if (legendHeight >= 10.1) {
				legend.style.setProperty('overflow-y', 'scroll');
				legend.style.setProperty('height', '8.5rem');
			}
		}
	}

	/**
	 * Pause all medias excepts the one to play
	 */
	pauseAllMedias = () => {
		const audios = document.getElementsByTagName('audio');
		for (let i = 0, len = audios.length; i < len; i++) {
			audios[i].pause();
		}
		const mapExplo = document.getElementById('map-explo');
		const videos = document.getElementsByTagName('video');
		for (let j = 0, len = videos.length; j < len; j++) {
			if (videos[j] !== this.player) {
				if (videos[j].id === 'background-video') {
					videos[j].muted = true;
				} else if (mapExplo) {
					const videoExplo = mapExplo.getElementsByTagName('video')[0];
					if (videoExplo) videoExplo.muted = true;
				}
				else videos[j].pause();
			}
		}
	}

	setCurrentTime = currentTime => {
		this.setState({ currentTime });
	}

	pause = () => {
		const slickZoomContainer = document.getElementById('zoomSlideContainer');
		if (slickZoomContainer) {
			const slickZoomCurrent = slickZoomContainer.getElementsByClassName('slick-current')[0];
			const zoomMedia = slickZoomCurrent.getElementsByClassName('JS_media')[0];
			if (zoomMedia && zoomMedia.dataset.type === 'VIDEO' && !zoomMedia.paused) {
				const zoomOverlay = slickZoomCurrent.querySelector("div[class^='overlay']");
				if (zoomOverlay) zoomOverlay.click(); // pause media
			}
		}
	}

	handleLoadStart = () => {
		if (this.player) {
			this.player.volume = this.state.currentVolume;
			this.player.muted = this.state.muted;
		}
	}

	setCurrentVolume = currentVolume => {
		this.setState({ currentVolume });
	}

	setMuted = muted => {
		this.setState({ muted });
	}

	componentDidMount() {
		log('init zoom');
		window.addEventListener('resize', this.setWrapperAndLegendSize);
		window.addEventListener('resize', () => setTimeout(() => this.setHeights(), 250));
	}

	componentDidUpdate(_prevProps, prevState) {
		if (prevState.legend !== this.state.legend) this.setWrapperAndLegendSize();
		if (prevState.sliderInfo !== this.state.sliderInfo) this.initSlider();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setWrapperAndLegendSize);
		window.removeEventListener('resize', () => setTimeout(() => this.setHeights(), 250));
	}

	render(props, state) {
		let sliderSettings;
		if (state.sliderInfo) {
			sliderSettings = {
				autoplay: false,
				draggable: false,
				swipe: true,
				dots: true,
				infinite: false,
				initialSlide: 0,
				arrows: true,
				nextArrow: <Arrow side="next" />,
				prevArrow: <Arrow side="prev" />,
				responsive: [{
					breakpoint: globalVars.breakpoint.md,
					settings: {
						arrows: false,
						draggable: false,
						swipe: true
					}
				}],
				afterChange: () => this.setHeights(),
				beforeChange: () => this.pause()
			};
		}

		return (
			state.show && (
				<div id="zoom" class={style.zoom}>
					<button class={`${style.close} ${icon.icon_reduce}`} onClick={this.toggle} />
					<div id="zoom-wrapper" class={style.zoomWrapper}>
						{!state.sliderInfo && (state.type === 'IMG' || state.type === 'PDF') &&
							<PinchZoomPan maxScale={8} position={'center'} zoomButtons={false}>
								<img class={style.media} src={state.content} alt={state.alt || ''} />
							</PinchZoomPan>
						}
						{!state.sliderInfo && state.type === 'VIDEO' &&
							<video ref={p => this.player = p} class={style.media} onLoadStart={this.handleLoadStart} currentTime={state.currentTime} autoplay onPlay={this.pauseAllMedias} controls controlsList="nodownload" poster={state.thumbnail} crossorigin="anonymous">
								<source src={state.content} />
								{state.subtitles &&
									<Localizer>
										<track label={menu[props.lang]} kind="subtitles" src={state.subtitles} srclang={props.lang} default />
									</Localizer>
								}
							</video>
						}
						{!state.sliderInfo && state.type === 'AUDIO' &&
							<PinchZoomPan maxScale={8} position={'center'} zoomButtons={false}>
								<img class={style.media} src={state.thumbnail} alt={state.alt || ''} />
							</PinchZoomPan>
						}
						{!this.state.sliderInfo && this.state.type === '3D' &&
							<Three media={JSON.parse(state.content)} />
						}
						{state.sliderInfo &&
							<div id="zoomSlideContainer" className={style.slideContainer}>
								<Slick {...sliderSettings} ref={slider => (this.sliderMedia = slider)}>
									{state.sliderInfo.medias.map((media, index) => (
										<div key={media} className={style.slide}>
											<Slide
												media={props.medias[media]}
												showPDF={props.showPDF}
												style={style.media}
												offer={props.offer}
												lang={props.lang}
												pinchZoomPan
												currentTime={state.currentTime}
												setCurrentTime={this.setCurrentTime}
												currentVolume={this.state.currentVolume}
												setCurrentVolume={this.setCurrentVolume}
												muted={this.state.muted}
												setMuted={this.setMuted}
											/>
											<div id={`zoom-slide-legend-${index}`} className={style.slideLegend}>
												<Legend
													key={media}
													legend={props.medias[media].legend}
													lang={props.lang}
													showSnackbar={props.showSnackbar}
													isMobile={props.isMobile}
												/>
											</div>
										</div>
									))}
								</Slick>
							</div>
						}
					</div>
					{!state.sliderInfo && <small id="zoom-legend" class={style.legend}>{state.legend}</small>}
				</div>
			)
		);
	}
}