import { Component } from 'preact';
import { globalVars } from 'Components/globalVars';
import Arrow from 'Components/elements/buttons/arrow';
import CustomButton from 'Components/elements/buttons/custom';
import Media from 'Components/elements/ui/media';
import Slick from 'react-slick';
import parse from 'html-react-parser';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from './style';

export default class FreeModal extends Component {
	goTo = button => {
		this.props.goTo(button)();
	}

	getSliderSettings = () => (
		{
			draggable: true,
			swipe: true,
			dots: true,
			infinite: false,
			arrows: true,
			nextArrow: <Arrow side="next" />,
			prevArrow: <Arrow side="prev" />,
			responsive: [{
				breakpoint: globalVars.breakpoint.md,
				settings: {
					arrows: false,
					draggable: false,
					swipe: true
				}
			}]
		}
	)

	render(props) {
		return (
			<div className={props.modal.medias.length > 0 ? `${style.content} ${style.noTopPadding}` : style.content}>
				<div className={style.container}>
					{props.modal.medias.length > 0 &&
						<Slick {...this.getSliderSettings()} className={style.slider}>
							{props.modal.medias.map(media => (
								<Media key={media} media={media} lang={props.appInfo.lang} />
							))}
						</Slick>
					}
					<div className={`${style.wrapper} ${props.modal.medias.length > 0 && style.top} ${props.modal.medias.length === 1 && style.noMarginTop}`}>
						{props.modal.title !== '' &&
							<h1 class={style.title}>{props.modal.title}</h1>
						}
						{props.modal.description !== '' && parse(props.modal.description)}
					</div>
				</div>
				<div className={style.buttons}>
					{(props.modal.buttons && props.modal.buttons.length > 0) &&
						props.modal.buttons.map((button, index) => (
							<CustomButton
								key={index}
								appInfo={props.appInfo}
								customStyle={style.custom}
								action={() => this.goTo(button)}
								button={button}
							/>
						))
					}
				</div>
			</div>
		);
	}
}
