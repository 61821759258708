const Pause = () => (
	<svg
		width="2.1rem"
		height="2.4rem"
		viewBox="0 0 21 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-14.000000, -16.000000)" fill="#FFFFFF">
				<g transform="translate(14.000000, 16.000000)">
					<rect
						x="0"
						y="0"
						width="8"
						height="24"
						rx="2"
					/>
					<rect
						x="13"
						y="0"
						width="8"
						height="24"
						rx="2"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default Pause;
