import { Component } from 'preact';
import { log, dynamicSort, customRoute, storeItem } from 'Components/common';
import { Text } from 'preact-i18n';
import Slick from 'react-slick';
import LanguageModal from './language';
import OfflineModal from './offline';
import Free from './free';
import style from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MODALTYPES = { languages: LanguageModal, offline: OfflineModal, free: Free };

export default class AppIntroModals extends Component {
	nextSlide = () => {
		this.saveFreeModal(this.state.filteredModals[this.state.modalIndex]);
		this.setState(prevState => ({ modalIndex: prevState.modalIndex + 1 }));
		this.slider.slickNext();
	}

	/**
	 * Load next/previous modal
	 */
	goTo = button => () => {
		if (!button) {
			this.state.modalIndex + 1 >= this.state.filteredModals.length
				? this.hide()
				: this.nextSlide();
		}
		else {
			switch (button.type) {
				case 'close':
					this.hide();
					break;
				case 'next':
					this.state.modalIndex + 1 >= this.state.filteredModals.length
						? this.hide()
						: this.nextSlide();
					break;
				case 'previous':
					this.slider.slickPrev();
					this.state.modalIndex - 1 >= 0 && this.setState(prevState => ({ modalIndex: prevState.modalIndex - 1 }));
					break;
				case 'goToPage':
					customRoute(`/${this.props.appInfo.lang}/${button.target}`, this.props.appInfo.id);
					setTimeout(() => this.hide(), 750);
					break;
				case 'openContent':
					this.props.showModal(Number(button.target.split('/')[1].split('-')[0]));
					break;
				case 'openURL':
					window.open(button.target);
					break;
				default:
					this.nextSlide();
					break;
			}
		}
	}

	hide = () => {
		this.state.filteredModals.length > 0 && this.saveFreeModal(this.state.filteredModals[this.state.modalIndex]);
		this.props.closeModal();
		this.setState({ show: false });
	}

	/**
	 * Checks if page id is present into local storage
	 * @param {Object} modal modal object
	 */
	isFreeModalSaved = modal => {
		const storedFreeModals = localStorage.getItem('storedFreeModals');
		try {
			const freeModals = JSON.parse(storedFreeModals);
			if (!freeModals || !freeModals[modal.id]) {
				return false;
			}
			const now = Date.now();
			return freeModals[modal.id] >= now;
		}
		catch (e) {
			return false;
		}
	}

	/**
	 * Save page id into local storage for 24h
	 * @param {Object} modal modal object
	 */
	saveFreeModal = modal  => {
		if (this.checkModalStatus(modal)) {
			const now = Date.now();
			const oneDay = 24*60*60*1000;
			const storedFreeModals = localStorage.getItem('storedFreeModals');
			let freeModals;
			try {
				freeModals = JSON.parse(storedFreeModals) || {};
			}
			catch (e) {
				freeModals = {};
			}
			freeModals[modal.id] = now+oneDay;
			storeItem('local', 'storedFreeModals', freeModals);
		}
	}

	/**
	 * Check wether or not to add modal to localstorage
	 * @param {Object} modal modal object
	 */
	 checkModalStatus = modal => {
		if (modal.recurrent) {
			return false;
		}
		else if (this.isFreeModalSaved(modal)) {
			return false;
		}
		return true;
	}

	filterModals = modal => {
		if (modal.recurrent) { // always show if recursive
			return true;
		}
		else if (!this.isFreeModalSaved(modal)) {
			return true;
		}
		return false;
	}

	getSliderSettings = () => (
		{
			draggable: false,
			swipe: false,
			dots: true,
			infinite: false,
			arrows: false
		}
	)

	getModals = () => {
		const sortedModals = this.props.appInfo.appModals && this.props.appInfo.appModals.sort(dynamicSort('position'));
		const filteredModals = sortedModals && sortedModals.filter(modal => this.filterModals(modal));
		return filteredModals;
	}

	state = {
		modalIndex: 0,
		show: true,
		filteredModals: this.getModals()
	}

	componentDidMount() {
		log('init appIntro modals');
		if (!(this.state.filteredModals && this.state.modalIndex < this.state.filteredModals.length)) {
			this.hide();
		}
	}

	render(props, state) {
		return (
			(state.show && state.filteredModals && state.filteredModals.length > 0) &&
			<div class={style.appIntro}>
				<div class={style.popup}>
					<button class={style.skip} onClick={this.hide}>
						<Text id="popup.appIntro.skip" />
					</button>
					<Slick {...this.getSliderSettings()} ref={c => (this.slider = c)} className={style.slider}>
						{state.filteredModals.map(modal => {
							const Modal = MODALTYPES[modal.type] || null;
							return (
								<Modal
									key={modal.id}
									{...props}
									modal={modal}
									goTo={this.goTo}
								/>
							);
						})}
					</Slick>
				</div>
			</div>
		);
	}
}