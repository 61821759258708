import { Component } from 'preact';
import { Text } from 'preact-i18n';
import { log } from 'Components/common';
import Basket from 'Components/basket';

import style from './style';
import icon from 'Components/font-icon';

export default class BasketModal extends Component {
	componentDidMount() {
		log('init BasketModal component');
	}

	render(props) {
		return (
			<div class={style.basketModal}>
				<div class={style.popup}>
					<div className={style.wrapper}>
						<header className={style.header}>
							{props.isMobile
								? <>
									<button class={`${style.return} ${icon.icon_arrowLeft}`} onClick={props.toggleBasket} type="button" />
									<h2 className={style.title}>
										{props.share && props.share.title
											? props.share.title + ` (${props.basketCounter})`
											: <Text id="basket.name" fields={{ count: props.basketCounter }} />
										}
									</h2>
									<button	class={`${style.dropBasket} ${icon.icon_delete}`} onClick={props.dropBasket} />
								</>
								: <>
									<h2 className={style.title}>
										{props.share && props.share.title
											? props.share.title + ` (${props.basketCounter})`
											: <Text id="basket.name" fields={{ count: props.basketCounter }} />
										}
									</h2>
									<button	class={`${style.dropBasket} ${icon.icon_delete}`} onClick={props.dropBasket} />
									<button class={`${style.close} ${icon.icon_close}`} onClick={props.toggleBasket} type="button" />
								</>
							}
						</header>
						<Basket
							basketCounter={props.basketCounter}
							cards={props.cards}
							setBasketCounter={props.setBasketCounter}
							dropBasket={props.dropBasket}
							toggleBasket={props.toggleBasket}
							appInfo={props.appInfo}
							share={props.share}
							pageId={props.pageId}
							showModal={props.showModal}
							setBasketCartState={props.setBasketCartState}
							isMobile={props.isMobile}
							isPreview={props.isPreview}
							internetIsDisable={props.internetIsDisable}
						/>
					</div>
				</div>
			</div>
		);
	}
}
