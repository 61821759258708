import { Component } from 'preact';
import { Text, Localizer } from 'preact-i18n';
import { log } from 'Components/common';
import parse from 'html-react-parser';
import icon from 'Components/font-icon';
import OpenModalIcon from 'Assets/icons/OpenModalIcon';
import style from './style';

export default class OpenModal extends Component {
	state = {
		code: ''
	}

	closeModal = () => {
		this.props.toggleOpenModal();
		this.props.toggleMenuModal();
	}

	handleCodeChange = event => {
		this.setState({ code: event.target.value });
	}

	componentDidMount() {
		log('init game session OPEN modal');
	}

	componentDidUpdate(prevProps) {
		if (prevProps.error !== this.props.error) {
			const element = document.getElementsByClassName(style.error)[0];
			element.scrollIntoView();
		}
	}

	render(props, state) {
		return (
			<div className={style.openModal}>
				<div className={style.popup}>
					<header className={style.header}>
						<button class={`${style.return} ${icon.icon_arrowLeft}`} onClick={props.toggleOpenModal} type="button">
							<Text id="menu.back" />
						</button>
						{!props.isMobile && <button class={`${style.close} ${icon.icon_close}`} onClick={this.closeModal} type="button" />}
					</header>
					<div className={style.container}>
						<div className={style.media}>
							{props.medias[props.info.media]
								? <img src={props.medias[props.info.media].url} alt={(props.medias[props.info.media].alt && props.medias[props.info.media].alt[props.appInfo.lang]) || ''} />
								: <OpenModalIcon />
							}
						</div>
						<div className={style.wrapper}>
							<h1 className={style.title}>{props.info.title ? props.info.title : <Text id="gameSession.modals.open.title" />}</h1>
							{props.info.description
								? parse(props.info.description)
								: <Text id="gameSession.modals.open.description" />
							}
						</div>
						<form class={style.form}>
							<Localizer>
								<input
									class={style.codeInput}
									type="text"
									value={state.code}
									onInput={this.handleCodeChange}
									placeholder={<Text id="gameSession.code.placeholder" />}
								/>
							</Localizer>
							{props.error && <div class={`${style.error} ${icon.icon_error}`}><Text id="gameSession.code.error" /></div>}
						</form>
					</div>
					<button className={style.full} onClick={() => props.openSession(state.code)} disabled={state.code.length <= 0} type="submit">
						<Text id="gameSession.modals.open.button" />
					</button>
				</div>
			</div>
		);
	}
}
