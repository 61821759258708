import { Component } from 'preact';
import { log } from 'Components/common';
import { postCart } from 'Components/settings';
import CartContent from './content';
import CartForm from './form';
import icon from 'Components/font-icon';
import style from './style';

export default class Basket extends Component {
	state = {
		emails: [],
		freeField: '',
		senderField: '',
		sendPending: false,
		isSent: false,
		showErrorMsg: false,
		shouldScroll: false,
		signedUp: false
	}

	/**
	 * Set basket emails
	 */
	setBasketContent = (emails, freeField, senderField, signedUp) => () => {
		this.setState({ emails, freeField, senderField, signedUp });
		if (this.basket && this.basket.scrollHeight > this.basket.clientHeight) {
			setTimeout(() => this.basket.scrollTo({
				left: 0,
				top: this.basket.scrollHeight,
				behavior: 'smooth'
			}), 500);
		}
	}

	/**
	 * Remove basket emails adress
	 */
	emptyBasketContent = () => {
		const emails = [];
		const freeField = '';
		const senderField = '';
		const signedUp = false;
		return this.setState({ emails, freeField, senderField, signedUp });
	}

	resetSendProcess = () => {
		setTimeout(() => {
			this.emptyBasketContent();
			this.props.dropBasket();
			this.setState({ sendPending: false, isSent: false, showErrorMsg: false });
			this.props.toggleBasket();
		}, 2000);
	}

	/**
	 * transform CardsId {array[{Object}]} to {string} with ';' separators
	 * @param {array[{Object}]} cardsIdArr
	 * @returns {string} id1;id2;id3;id4
	 */
	cardsIdArrToStr = cardsIdArr => {
		const sep = ';';
		let cardsId = '';
		cardsIdArr.map((cardId, index) => {
			cardsId += cardId.id;
			if (index !== cardsIdArr.length - 1) cardsId += sep;
		});
		return cardsId;
	}

	emailsArrToStr = arr => {
		const sep = ',';
		let emailsStr = '';
		arr.map((email, index) => {
			emailsStr += email;
			if (index !== arr.length - 1) emailsStr += sep;
		});
		return emailsStr;
	}

	/**
	 * Format the basket content for the Studio API
	 * and call the middleware API to send the basket content with auth
	 * @param {number} pageId id of the current page
	 * @param {Number} appId id of the current app
	 * @param {string} cardsId ids of the basket items
	 * @param {string} emails emails to send the basket
	 * @param {string} freeField free text field
	 * @param {string} senderField the email of the sender
	 * @returns {json} json with success/error message
	 */
	sendBasketContent = async (appId, pageId, cardsId, emails, freeField, senderField, signedUp, isPreview) => {
		try {
			const response = await postCart(appId, Math.floor(Date.now() / 1000), pageId, emails, cardsId, freeField, senderField, signedUp, isPreview);
			const json = await response.json();
			if (json.error) return Promise.reject(Error(json.message));
			return json;
		}
		catch (error) {
			return Promise.reject(error);
		}
	}

	/**
	 * Get the basket form value and send them
	 * @param {Event} ev "send to" default form event
	 */
	sendBasket = ev => {
		ev.preventDefault(); // prevent "send to" default form event

		this.setState({ sendPending: true });
		this.setState({ showErrorMsg: false });

		this.sendBasketContent(
			this.props.appInfo.id,
			this.props.pageId,
			this.cardsIdArrToStr(this.props.cards),
			this.emailsArrToStr(this.state.emails),
			this.state.freeField,
			this.state.senderField,
			this.state.signedUp,
			this.props.isPreview
		)
			.then(() => {
				setTimeout(() => this.setState({ sendPending: false, isSent: true }, this.resetSendProcess), 2000);
			}).catch(() => {
				this.setState({ sendPending: false });
				this.setState({ showErrorMsg: true });
			});
	};

	checkScroll = e => {
		e.preventDefault();
		if (this.basket && this.scrollButton && this.basket.scrollHeight > this.basket.clientHeight) {
			if (this.basket.scrollTop === 0) {
				this.scrollButton.style.setProperty('display', 'flex');
			}
			else this.scrollButton.style.setProperty('display', 'none');
		}
	}

	scroll = e => {
		const scrollButton = e.target;
		scrollButton.style.setProperty('display', 'none');
		if (this.basket) {
			this.basket.scrollTo({
				left: 0,
				top: this.basket.scrollHeight,
				behavior: 'smooth'
			});
		}
	}

	componentDidMount() {
		log('init basket');
		// eslint-disable-next-line react/no-did-mount-set-state
		this.setState({ shouldScroll: this.basket && this.basket.scrollHeight > this.basket.clientHeight });
	}

	componentDidUpdate() {
		if (this.scrollButton) {
			if (this.basket && this.basket.scrollHeight > this.basket.clientHeight) this.scrollButton.style.setProperty('display', 'flex');
			else this.scrollButton.style.setProperty('display', 'none');
		}
	}

	render(props, state) {
		return (
			<div id="basket" className={style.basket} ref={b => this.basket = b} onScroll={e => this.checkScroll(e)}>
				<CartContent
					cartCounter={props.basketCounter}
					cards={props.cards}
					setBasketCounter={props.setBasketCounter}
					setBasketCartState={props.setBasketCartState}
				/>
				<CartForm
					cartCounter={props.basketCounter}
					setBasketContent={this.setBasketContent}
					sendBasket={this.sendBasket}
					isSent={state.isSent}
					sendPending={state.sendPending}
					showErrorMsg={state.showErrorMsg}
					emails={state.emails}
					share={props.share}
					isMobile={props.isMobile}
					internetIsDisable={props.internetIsDisable}
				/>
				<button style={'display: none;'} className={`${style.scroll} ${icon.icon_chevronDown}`} onClick={e => this.scroll(e)} ref={b => this.scrollButton = b} />
			</div>
		);
	}
}
