import { Component } from 'preact';
import { Router, route } from 'preact-router';
import * as Device from 'react-device-detect';
import {OktaAuth} from '@okta/okta-auth-js';
import { Security, LoginCallback } from "@okta/okta-react";
import {
	log,
	addInternetConnectionListener,
	removeInternetConnectionListener,
	isDevEnv,
	getAppLangFromUrl,
	getJsonData,
	getJsonName,
	dynamicColor,
	trimRGBA,
	getIdsFromPreviewUrl,
	getDevAppId,
	addUserInteractionsListener,
	removeUserInteractionsListener,
	isMobilePreview,
	checkOfflineDataToBeSent,
	customRoute,
	isStudioPreview,
	generateMediaUrl,
	formatFontawesomeName,
	storeItem,
	captureAllClickEvents,
	getAppLangFromOriginalUri
} from './common';
import { globalVars } from './globalVars';
import { font, customFonts, postDeviceInfo, getContentPreviewJSON } from 'Components/settings';

import TopBar from 'Components/elements/ui/topbar';

// not async component (to use child functions)
import Zoom from 'Components/elements/ui/zoom';
import AppLoader from 'Components/elements/ui/appLoader';
import Snackbar from 'Components/elements/ui/snackbar';
import AuthenticationCode from 'Components/elements/ui/authentication/code';
import AuthenticationMail from 'Components/elements/ui/authentication/mail';
import AuthenticationGeoloc from 'Components/elements/ui/authentication/geolocation';
import AppIntroModals from './popup/appIntro';
import Offline from 'Components/offline';

// i18n module and components
import { IntlProvider } from 'preact-i18n';
import fr from 'App/i18n/fr.json';
import en from 'App/i18n/en.json';
import de from 'App/i18n/de.json';
import es from 'App/i18n/es.json';
import zh from 'App/i18n/zh.json';
import ja from 'App/i18n/ja.json';
import buc from 'App/i18n/buc.json';
import it from 'App/i18n/it.json';

// i18n matching between JSON and Preact component (JSON: PREACT)
const I18N = {
	fr,
	en,
	de,
	es,
	zh,
	ja,
	buc,
	it
};

// import webfontloader only in a browser (to use window element), not server-side
export const WebFont = typeof window !== 'undefined' ? require('webfontloader') : undefined;

let basketCartReferences = {};
let library;

// Code-splitting is automated for routes
import Home from 'Routes/home';
import Page from 'Routes/page';
import Stats from 'Routes/stats';

export default class App extends Component {
	state = {
		// appInfo: {
		//  s3Folder,
		// 	id,
		// 	lang,
		//	initLang,
		//	allLang,
		//  langSet,
		// 	slug,
		// 	defaultPageSlug,
		// 	pages,
		//  offer,
		//	name,
		//	logo,
		//	menu,
		//  forceOffline,
		//  forceOfflinePopup
		//  codeAuth,
		//  emailAuth,
		//	geolocAuth,
		//  addToHomescreen,
		//  accessibility,
		//  gameSession,
		//  appTimer,
		//  versionTimestamp
		// },
		//i18n: en,
		//medias: null,
		internetIsDisable: null,
		isMobile: true,
		appIntroModalClosed: false,
		createModalClosed: false,
		colorsStyle: null,
		fontStyle: null,
		radiiStyle: null,
		isPreview: false,
		isContentPreview: false,
		connectionAllowed: false,
		connectionAllowedByOkta: false,
		showAppLoader: true
	}


	/** Link the Basket Child component to childBasket var
	 * @param {Object} c Preact component
	 */
	refBasket = c => this.childBasket = c;

	/**
	 * Open cart called from widget/nav buttons
	 */
	onOpenCart = () => this.childBasket && this.childBasket.toggleBasket();

	/** Link the page Child component to childPage var
	 * @param {Object} c Preact component
	 */
	refPage = c => this.childPage = c;

	/** Link the gameSession Child component to childGameSession var
	 * @param {Object} c Preact component
	 */
	refGameSession = c => this.childGameSession = c;

	/** Link the navigation menu button Child component to childMenuButton var
	 * @param {Object} c Preact component
	 */
	refMenuButton = c => this.childMenuButton = c;

	/**
	 * Trigger action of the navigation menu button called from widget/nav buttons
	 */
	onOpenMenu = () => this.childMenuButton && this.childMenuButton.triggerMenuAction();

	/** Link the lang controller Child component to childLangController var
	 * @param {Object} c Preact component
	 */
	refLangController = c => this.childLangController = c;

	/**
	 * Toggle lang flap menu called from widget/nav buttons
	 */
	onOpenLang = () => this.childLangController && this.childLangController.toggleFlap();

	/** Promise to set the state of card array and number of item in the basket
	 * after fetching the basket in database (child functions)
	 */
	onSetBasketCounter = () => this.childBasket && this.childBasket.setBasketCounter();

	/**
	 * Set the state of topbar for fastShare and page title
	 * @param {Object} page the page json object
	 */
	onSetTopbarComponents = page => {
		const showShare = page.components.share && page.templateId !== 14 && page.templateId !== 4;
		this.topbar && this.topbar.setComponents(showShare, page.components.share, page.components.fastShare, page.customization.showTitle, page.title, page.id);
	}


	/** Toggle zoom (child functions)
	 * @param {HTMLelement} el HTML element
	 */
	onToggleZoom = (e, sliderInfo, callback) => this.childZoom.toggle(e, sliderInfo, callback);

	/** Show the modal (child functions)
	 * @param {Number} itemId Id of the card/item to show
	 */
	onShowModal = (itemId, options) => this.childPage && this.childPage.showCardModal(itemId, options);

	onSaveStateOfPage = (itemId, logHistory) => this.childPage && this.childPage.saveStateOfPage(itemId, logHistory);

	/**
	 * Show the child snackbar component and update its text
	 * @param {String} msg the translated string we want to show
	 */
	onShowSnackbar = (msg, direction) => this.snackbar && this.snackbar.show(msg, direction);

	getOrCreateRef = (c, id) => {
		if (c !== null) {
			basketCartReferences[id] = c;
		}
		return basketCartReferences[id];
	}

	onSetBasketState = (isActive, id) => {
		const childBasketCart = this.getOrCreateRef(null, id);
		childBasketCart && childBasketCart.setBasketState(isActive);
	}

	closeAppIntroModal = () => this.setState({ appIntroModalClosed: true });

	closeCreateModal = shouldClose => this.setState({ createModalClosed: shouldClose });

	/** Gets fired when the route changes.
	 * @param {Object} event		"change" event from [preact-router](https://github.com/preactjs/preact-router)
	 * @param {String} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	/**
	 * Update the percentage of game session in the child component inside topbar
	 */
	onUpdateGameSessionPercent = () => this.childGameSession && this.childGameSession.updatePercentage(true);

	openGameSessionMenu = () => this.childGameSession && this.childGameSession.toggleMenuModal();

	/**
	 * Reset app if new game session is requested
	 */
	onNewGameSession = () => {
		this.childBasket && this.childBasket.dropBasket();
		let appInfo = this.state.appInfo;
		const callArgs = this.getCallArgs(appInfo.lang);
		getJsonData(callArgs.type, callArgs.app)
		.then(json => {
			// reset appInfo state by setting it to null, then callback to set new state to force re-render
			this.setState({ appInfo: null, appIntroModalClosed: false }, () => {
				this.setAppData(json, true, null);
				customRoute(`/${appInfo.lang}`, appInfo.id, true);
				//this.onShowSnackbar(<Text id="gameSession.snackbar.opened" />);
			});
		})
		.catch(error => {
			console.error('SetAppData error setting new game session : ', error.message);
			return error;
		});
	}

	/** Set the language for i18n and JSON
	 * @param {Sting} lang localization code
	 */
	setLang = lang => () => {
		let appInfo = this.state.appInfo;
		if (lang !== appInfo.lang) { // no need to reload app if same language
			//drop the basket content
			this.childBasket && this.childBasket.dropBasket();

			this.setState({ appInfo: null, medias: null },  () => {
				this.getInitialData(this.getCallArgs(lang, true, appInfo.languages[lang]), true);
			});
		}
	}

	/** Set the fonts
	 * @param {Sting} jsonTitleFont title font defined in Studio
	 * @param {Sting} jsonTextFont text font defined in Studio
	 */
	setFonts = (jsonTitleFont, jsonTextFont) => {
		if (Object.keys(customFonts).includes(jsonTextFont) && Object.keys(customFonts).includes(jsonTitleFont)) {
			import(`../style/customFonts/${jsonTextFont}.scss`).then(() =>
				WebFont.load({
					custom: {
						families: [customFonts[jsonTextFont].param]
					},
					classes: false,
					events: false
				})
			);
			globalVars.textFont = customFonts[jsonTextFont].family;
			globalVars.titleFont = customFonts[jsonTitleFont].family;
		}
		else if (Object.keys(customFonts).includes(jsonTextFont)) {
			import(`../style/customFonts/${jsonTextFont}.scss`).then(() =>
				WebFont.load({
					custom: {
						families: [customFonts[jsonTextFont].param]
					},
					google: {
						families: [font[jsonTitleFont].param]
					},
					classes: false,
					events: false
				})
			);
			globalVars.textFont = customFonts[jsonTextFont].family;
			globalVars.titleFont = font[jsonTitleFont].family;
		}
		else if (Object.keys(customFonts).includes(jsonTitleFont)) {
			import(`../style/customFonts/${jsonTitleFont}.scss`).then(() =>
				WebFont.load({
					custom: {
						families: [customFonts[jsonTitleFont].param]
					},
					google: {
						families: [font[jsonTextFont].param]
					},
					classes: false,
					events: false
				})
			);
			globalVars.textFont = font[jsonTextFont].family;
			globalVars.titleFont = customFonts[jsonTitleFont].family;
		}
		else {
			WebFont.load({
				google: {
					families: [font[jsonTextFont].param, font[jsonTitleFont].param]
				},
				classes: false,
				events: false
			});
			globalVars.textFont = font[jsonTextFont].family;
			globalVars.titleFont = font[jsonTitleFont].family;
		}

		const fontStyle = `--textFontFamily: ${globalVars.textFont}; --titleFontFamily: ${globalVars.titleFont};`;
		this.setState({ fontStyle });
	}

	/** Set the colors
	 * @param {Object} colors the colors
	 */
	setColors = colors => {
		globalVars.color.primary = colors.principal;
		globalVars.color.primaryTrimed = trimRGBA(colors.principal);
		globalVars.color.secondary = colors.secondary;
		globalVars.color.secondaryTrimed = trimRGBA(colors.secondary);
		globalVars.color.primaryText = dynamicColor(colors.principal);
		globalVars.color.secondaryText = dynamicColor(colors.secondary);
		globalVars.color.titleColor = colors.title;
		globalVars.color.titleColorTrimed = trimRGBA(colors.title);
		globalVars.color.titleDynamicColor = dynamicColor(colors.title);
		globalVars.color.textColor = colors.text;
		globalVars.color.textColorTrimed = trimRGBA(colors.text);
		globalVars.color.modalColor = colors.modal;
		globalVars.color.itemColor = colors.item;
		globalVars.color.itemDynamicColor = dynamicColor(colors.item);
		globalVars.color.topBar = colors.topBar;
		globalVars.color.topBarTrimed = trimRGBA(colors.topBar);
		globalVars.color.topBarText = dynamicColor(colors.topBar);
		globalVars.color.slider = colors.slider;
		globalVars.color.menu = colors.menu;
		globalVars.color.menuText = dynamicColor(colors.menu);
		globalVars.color.pdf = colors.pdf || 'rgba(255,255,255,1)';
		const colorsStyle = `--${Object.entries(globalVars.color).map(([k, v]) => `${k}:${v}`).join('; --')};`;
		this.setState({ colorsStyle });
	}

	/**
	 * Change the font-size on html tag to enlarge/reduce all DOM elements size
	 */
	setAppFontSize = () => {
		const htmlTagStyle = document.getElementsByTagName('html')[0].style;
		if (htmlTagStyle.fontSize === '93.75%')	htmlTagStyle.fontSize = '62.5%';
		else htmlTagStyle.fontSize = '93.75%';
	}

	setAppRadii = radiusRatio => {
		const radiiStyle = '--radiusRatio: ' + radiusRatio + ';';
		this.setState({ radiiStyle });
	}

	getDeviceType = () => {
		if (self.innerWidth < globalVars.breakpoint.md || isMobilePreview()) {
			this.setState({ isMobile: true });
		}
		else {
			this.setState({ isMobile: false });
		}
	}

	logDeviceType = appId => {
		let navLang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
		navLang !== undefined ? navLang = navLang.slice(0, 2) : null;

		const storedLogInfo = localStorage.getItem('logInfo');
		let logInfo = JSON.parse(storedLogInfo);
		if (!logInfo) { // no log info, so we log because new user
			postDeviceInfo(appId, Device.deviceType, Device.osName, Device.browserName, navLang, true, false);
			logInfo = {
				date: Date.now(),
				loggedToday: false
			};
			storeItem('local', 'logInfo', logInfo);
		}
		else if (logInfo.date) { // log info exists so check if new day or not
			const loggedDate = new Date(logInfo.date).getDate();
			const nowDate = new Date().getDate();
			if (Date.now() - logInfo.date > 24 * 60 * 60 * 1000) { // more than 24h elapsed so this is a new day
				postDeviceInfo(appId, Device.deviceType, Device.osName, Device.browserName, navLang, true, false);
				logInfo = {
					date: Date.now(),
					loggedToday: false
				};
				storeItem('local', 'logInfo', logInfo);
			}
			else if (loggedDate !== nowDate) { // not more than 24h elapsed. But check if same day or not. If not, it's a new day
				postDeviceInfo(appId, Device.deviceType, Device.osName, Device.browserName, navLang, true, false);
				logInfo = {
					date: Date.now(),
					loggedToday: false
				};
				storeItem('local', 'logInfo', logInfo);
			}
			else if (!logInfo.loggedToday) { // if not already logged, we log, else do nothing
				postDeviceInfo(appId, Device.deviceType, Device.osName, Device.browserName, navLang, false, true);
				logInfo = {
					date: Date.now(),
					loggedToday: true
				};
				storeItem('local', 'logInfo', logInfo);
			}
			else {
				postDeviceInfo(appId, Device.deviceType, Device.osName, Device.browserName, navLang, false, false);
			}
		}
		else {
			postDeviceInfo(appId, Device.deviceType, Device.osName, Device.browserName, navLang, false, false);
		}
	}

	/**
	 * Get saved app lang if present into local storage of appLang popups
	 * @param {Number} appId application Id
	 * @returns {String} the lang saved if one
	 */
	getSavedAppLang = appId  => {
		const storedAppLangPopups = localStorage.getItem('storedAppLangPopups');
		try {
			const appLangPopups = JSON.parse(storedAppLangPopups);
			if (!appLangPopups || !appLangPopups[appId]) {
				return null;
			}
			if (appLangPopups[appId].date >= Date.now()) return appLangPopups[appId].language;
			return null;
		}
		catch (e) {
			return null;
		}
	}

	getCallArgs = (lang, isFirst = false, App_ID = undefined) => {
		let callArgs = {
			type: '',
			app: '',
			media: ''
		};
		const isStudio = isStudioPreview();
		const ID_APP = getIdsFromPreviewUrl();
		if (ID_APP || isDevEnv() || isStudio) {
			const appLangId = this.state.appInfo && this.state.appInfo.languages[lang];
			const appId = (appLangId || ID_APP || App_ID || getDevAppId()) + '/';
			callArgs.type = 'api';
			callArgs.app = appId + (lang ? lang + '/' : '');
			callArgs.media = appId + 'medias/';
			if (ID_APP || isStudio) this.setState({ isPreview: true });
		}
		else {
			callArgs.type = 'app';
			callArgs.app = getJsonName(lang, false);
			callArgs.media = getJsonName('', true);
		}

		if (isFirst) {
			const appLangId = this.state.appInfo && this.state.appInfo.languages[lang];
			let appId = (App_ID || appLangId || ID_APP || getDevAppId());
			if (!Number(appId)) appId = appId.split('-')[0]; // appId is from getIdsFromPreviewUrl with format appID-contentID
			appId = appId + '/';
			callArgs.type = isDevEnv() || ID_APP || isStudio ? 'first' : 'app';
			callArgs.app = isDevEnv() || ID_APP || isStudio ? appId + (lang ? lang + '/' : '') : getJsonName(lang, false, true)
		}
		return callArgs;
	}

	getInitialData = (callArgs, langChanged = false) => {
		getJsonData(callArgs.type, callArgs.app)
		.then(data => {
			this.setInitialData(data, false, langChanged)
		})
		.catch(error => {
			console.error('getInitialData error : ', error.message);
			return error;
		});
	};

	setInitialData = async (data, langSet, langChanged = false) => {
		const studioPreview = isStudioPreview();
		const s3Folder = data.s3Folder;
		const id = data.id;
		const initLang = data.currentLanguage;
		const languages = data.languages || {};
		const allLang = Object.keys(data.languages);
		const isSoloLang = allLang && allLang.length === 1; // if only one language, no need at all to show popup
		const autoDetectLang = data.appModals && !data.appModals.some(modal => modal.type === 'languages');
		const appModals = data.appModals;

		if (appModals) {
			appModals.map(modal => {
				modal.medias && modal.medias.map(media => {
					media.url = generateMediaUrl(media.url, s3Folder);
					media.children?.map(child => {
						child.url = generateMediaUrl(child.url, s3Folder);
					});
					media.subtitles && Object.entries(languages).map(lang => {
						if (lang[0] && media.subtitles[lang[0]]) {
							media.subtitles[lang[0]] = generateMediaUrl(media.subtitles[lang[0]], s3Folder);
						}
					});
				});
				modal.buttons && modal.buttons.map(button => {
					if (button.media) {
						button.media.url = generateMediaUrl(button.media.url, s3Folder);
					}
				});
			});
		}

		const slug = id + '-' + data.slug;
		const name = data.title;
		const logo = data.logo;
		if (data.logo){
			logo.url = generateMediaUrl(logo.url, s3Folder)
		}
		const forceOffline = data.forceOffline;
		const showLangFlags = data.showLangFlags;
		const showLangNames = data.showLangNames;
		const oktaAuth = !studioPreview && data.oktaAuth;
		const codeAuth = data.codeAuth;
		const emailAuth = data.emailAuth;
		const geolocAuth = data.geolocAuth;


		if (emailAuth && emailAuth.modal && emailAuth.modal.medias.length > 0) {
			emailAuth.modal.medias.map(media => {
				media.url = generateMediaUrl(media.url, s3Folder);
				media.children?.map(child => {
					child.url = generateMediaUrl(child.url, s3Folder);
				});
				media.subtitles && Object.entries(languages).map(lang => {
					if (lang[0] && media.subtitles[lang[0]]) {
						media.subtitles[lang[0]] = generateMediaUrl(media.subtitles[lang[0]], s3Folder);
					}
				});
			});
		}
		if (codeAuth && codeAuth.modal && codeAuth.modal.medias.length > 0) {
			codeAuth.modal.medias.map(media => {
				media.url = generateMediaUrl(media.url, s3Folder);
				media.children?.map(child => {
					child.url = generateMediaUrl(child.url, s3Folder);
				});
				media.subtitles && Object.entries(languages).map(lang => {
					if (lang[0] && media.subtitles[lang[0]]) {
						media.subtitles[lang[0]] = generateMediaUrl(media.subtitles[lang[0]], s3Folder);
					}
				});
			});
		}
		if (geolocAuth && geolocAuth.modal && geolocAuth.modal.medias.length > 0) {
			geolocAuth.modal.medias.map(media => {
				media.url = generateMediaUrl(media.url, s3Folder);
				media.children?.map(child => {
					child.url = generateMediaUrl(child.url, s3Folder);
				});
				media.subtitles && Object.entries(languages).map(lang => {
					if (lang[0] && media.subtitles[lang[0]]) {
						media.subtitles[lang[0]] = generateMediaUrl(media.subtitles[lang[0]], s3Folder);
					}
				});
			});
		}

		const versionTimestamp = studioPreview || isDevEnv() ? Math.floor(Date.now() / 1000) : data.timestamp;

		// set the fonts dynamically
		this.setFonts(data.fonts.title, data.fonts.text);

		// set the colors dynamically
		this.setColors(data.colors);

		this.setAppRadii(data.styles.borderRadius);

		let currentLang = initLang;
		let isLangSet = isSoloLang;
		if (!langSet) {
			// if auto detect language activated
			if (autoDetectLang) {
				let navLang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
				navLang !== undefined ? navLang = navLang.slice(0, 2).toUpperCase() : null;
				if (navLang !== null && allLang.some(lang => lang === navLang)) {
					currentLang = navLang;
					isLangSet = true;
				}
			}
			else if (!isSoloLang && appModals && !appModals.some(modal => (modal.type === 'languages' && modal.recurrent))){
				// TODO : si pas récurrent, la modale ne doit pas s'afficher. Et si on atteint l'app par l'url de base ou depuis
				// une autre langue, il faut router l'app vers l'URL correspondante
				let savedLang = this.getSavedAppLang(id);
				if (savedLang) {
					isLangSet = true;
					currentLang = savedLang;
				}
			}
		}
		else isLangSet = langSet;

		/******************
		***		OKTA	***
		******************/
		let oktaAuthInstance = null;
		let restoreOriginalUri = null;

		if (oktaAuth) {
			oktaAuthInstance = new OktaAuth({
				issuer: oktaAuth.issuer,
				clientId: oktaAuth.clientID,
				redirectUri: window.location.origin + '/login/callback',
				// https://github.com/okta/okta-auth-js?tab=readme-ov-file#transformauthstate
				// Fix for PKCE authSdkError ?
				transformAuthState: async (oktaAuth, authState) => {
					if (!authState.isAuthenticated) {
						return authState;
					}
					// extra requirement: user must have valid Okta SSO session
					const user = await oktaAuth.token.getUserInfo();
					authState.isAuthenticated = !!user;
					authState.users = user;
					return authState;
				}
			});

			localStorage.setItem("appID", JSON.stringify(data.id));

			const currentUri = langChanged ? `/${data.currentLanguage}` : window.location.pathname;
			if (currentUri !== localStorage.getItem("originalUri") && !currentUri.includes("login")){
				localStorage.setItem("originalUri", JSON.stringify(currentUri));
			}

			restoreOriginalUri = async () => {
				const storedUri = localStorage.getItem("originalUri");
				if (storedUri) {
					const uri = JSON.parse(storedUri);
					route(uri.replace(/"/g, ''));
				}
			};
		}
		/**********************
		***		END OKTA	***
		**********************/

		// change browser title
		document.title = name;

		!studioPreview && this.logDeviceType(id);

		data.customPlugins && eval(data.customPlugins);

		// return appInfo in State
		this.setState({
			appInfo: {
				s3Folder,
				id,
				languages,
				initLang,
				lang: currentLang,
				allLang,
				langSet: isLangSet,
				appModals,
				slug,
				name,
				logo,
				forceOffline,
				showLangFlags,
				showLangNames,
				codeAuth,
				oktaAuth,
				emailAuth,
				geolocAuth,
				codeCheck: langChanged,
				emailCheck: langChanged,
				geolocCheck: langChanged,
				versionTimestamp,
				oktaAuthInstance,
				restoreOriginalUri,
			},
			i18n: I18N[currentLang.replace('-falc', '')]
		}, () => {
			if (langChanged){
				customRoute(`/${data.currentLanguage}`, this.state.appInfo.id, true);
			}
			this.checkAllowedConnectionByAuth();
		});
	}


	checkAllowedConnectionByAuth =  () => {
		if (this.state.connectionAllowed) {
			this.updateAllowedConnection();
			return;
		} else if (!this.state.appInfo){
			return;
		}

		this.setState(prevState => {
			const updatedAppInfo = { ...prevState.appInfo };

			if (!updatedAppInfo.codeAuth) {
				updatedAppInfo.codeCheck = true;
			}

			if (!updatedAppInfo.emailAuth) {
				updatedAppInfo.emailCheck = true;
			}

			if (!updatedAppInfo.geolocAuth) {
				updatedAppInfo.geolocCheck = true;
			}

			return { appInfo: updatedAppInfo };
		}, () => {
			this.updateAllowedConnection();
		});

	}

	updateAllowedConnection = () => {
		if (this.state.connectionAllowed){
			this.getAppData();
		}

		if (!this.state.connectionAllowed && this.state.appInfo.codeCheck && this.state.appInfo.emailCheck && this.state.appInfo.geolocCheck && this.state.connectionAllowedByOkta) {
			this.setState({ connectionAllowed: true }, this.getAppData());
		}
	}

	updateAuthentificationCheck = (type) => {
		if (type === 'code') {
			this.setState(prevState => ({
				appInfo: { ...prevState.appInfo, codeCheck: true }
			}));
			this.checkAllowedConnectionByAuth()
		}
		if (type === 'email') {
			this.setState(prevState => ({
				appInfo: { ...prevState.appInfo, emailCheck: true }
			}));
			this.checkAllowedConnectionByAuth()
		}
		if (type === 'geoloc') {
			this.setState(prevState => ({
				appInfo: { ...prevState.appInfo, geolocCheck: true }
			}));
			this.checkAllowedConnectionByAuth()
		}
	}

	getRemoteAppData = callArgs => {
		const ID_APP = getIdsFromPreviewUrl();
		const callArgsType = isDevEnv() || ID_APP  || isStudioPreview() ? 'api' : 'app';
		getJsonData(callArgsType, callArgs.app)
			.then(data => {
				this.setAppData(data, false, callArgs, this.state.appInfo.s3Folder, this.state.appInfo.languages);
			})
			.catch(error => {
				console.error('SetAppData error : ', error.message);
				return error;
			});
	}

	sortFontawesomeIcon = menu => {
		let icons = {
			solid: [],
			regular: [],
			thin: []
		};
		if (menu.entries && menu.entries.some(entry => entry.icon)) {
			const solidIconsEntries = menu.entries.filter(entry => entry.icon && entry.icon.match(/fa-solid/g));
			solidIconsEntries.map(siEntry => {
				icons.solid.push(formatFontawesomeName(siEntry.icon));
			});

			const regularIconsEntries = menu.entries.filter(entry => entry.icon && entry.icon.match(/fa-regular/g));
			regularIconsEntries.map(siEntry => {
				icons.regular.push(formatFontawesomeName(siEntry.icon));
			});

			const thinIconsEntries = menu.entries.filter(entry => entry.icon && entry.icon.match(/fa-thin/g));
			thinIconsEntries.map(siEntry => {
				icons.thin.push(formatFontawesomeName(siEntry.icon));
			});
		}

		if (menu.icon) {
			/fa-solid/g.test(menu.icon) && icons.solid.push(formatFontawesomeName(menu.icon));
			/fa-regular/g.test(menu.icon) && icons.regular.push(formatFontawesomeName(menu.icon));
			/fa-thin/g.test(menu.icon) && icons.thin.push(formatFontawesomeName(menu.icon));
		}

		if (icons.solid.length > 0 || icons.regular.length > 0 || icons.thin.length > 0) {
			import('@fortawesome/fontawesome-svg-core')
			.then(mod => {
				library = mod.library;
				if (icons.solid.length > 0) {
					import('@fortawesome/pro-solid-svg-icons')
					.then(mod => {
						icons.solid.map(icon => library.add(mod[icon]));
						this.setState({ library });
					});
				}
				if (icons.regular.length > 0) {
					import('@fortawesome/pro-regular-svg-icons')
					.then(mod => {
						icons.regular.map(icon => library.add(mod[icon]));
						this.setState({ library });
					});
				}
				if (icons.thin.length > 0) {
					import('@fortawesome/pro-thin-svg-icons')
					.then(mod => {
						icons.thin.map(icon => library.add(mod[icon]));
						this.setState({ library });
					});
				}
			});
		}
	}

	setAppData = (json, langSet, callArgs, s3Folder, languages) => {

		const firstPage = json.pages && json.pages[0];
		const defaultPageSlug = firstPage && firstPage.id + '-' + firstPage.slug;
		const pages = json.pages;
		const offer = json.offer;
		const menu = json.menu;
		menu && this.sortFontawesomeIcon(menu);
		const addToHomescreen = json.addToHomescreen;
		const accessibility = json.accessibility;
		const gameSession = json.gameSession;
		const appTimer = json.appTimer;

		// add inactivity delay to reset app (back to first page)
		if (json.autoReset) {
			this.timerTimeout = null;
			this.timeout = json.autoReset;
			addUserInteractionsListener(this);
		}

		// Preloads fonts used in board template
		const boardPages = pages.filter(page => page.templateId === 18 && page.components.text?.fonts);
		const fonts = [...new Set(boardPages.flatMap(page => page.components.text?.fonts))];
		
		if (fonts && fonts.length > 0) {
			WebFont.load({
				google: {
					families: fonts.map(fontName => font[fontName].param)
				},
				classes: false,
				events: false
			});
		}

		json.customPlugins && eval(json.customPlugins);

		// return appInfo in State
		this.setState( prevState => ({
			appInfo: {
				...prevState.appInfo,
				defaultPageSlug,
				pages,
				offer,
				menu,
				addToHomescreen,
				accessibility,
				gameSession,
				appTimer,
			},
			createModalClosed: !gameSession
		}), this.getMediasCallback(callArgs, s3Folder, languages));
	}

	getMediasCallback = (callArgs, s3Folder, languages) => {
		if (!callArgs) return;

		const ID_APP = getIdsFromPreviewUrl();
		const callArgsType = isDevEnv() || ID_APP || isStudioPreview() ? 'api' : 'app';

		getJsonData(callArgsType, callArgs.media)
		.then(medias => {
			Object.values(medias).map(media => {
				media.url = generateMediaUrl(media.url, s3Folder);
				media.children && media.children.map(child => {
					child.url = generateMediaUrl(child.url, s3Folder);
				});
				media.subtitles && Object.entries(languages).map(lang => {
					if (lang[0] && media.subtitles[lang[0]]) {
						media.subtitles[lang[0]] = generateMediaUrl(media.subtitles[lang[0]], s3Folder);
					}
				});
			});
			this.setState({ medias, showAppLoader: false });
		})
		.catch(error => {
			console.error('Get and generate medias error : ', error.message);
			return error;
		});
	}

	getAppData = () => {
		const ids = getIdsFromPreviewUrl();

		if (!Number(ids) && ids && ids.split('-').length > 1) {
			getContentPreviewJSON(ids.split('-')[0], ids.split('-')[1])
				.then(response => response.json()
					.then(data => {
						this.setState({isContentPreview: true});
						this.setAppData(data, false, {type: 'api', media: `${ids.split('-')[0]}/medias/`}, this.state.appInfo.s3Folder, this.state.appInfo.languages);
					})
				)
		} else {
			checkOfflineDataToBeSent();
			this.getDeviceType();
			if (window.location.pathname.split("/").includes('login')) {
				this.getRemoteAppData(this.getCallArgs(getAppLangFromOriginalUri(), ids));
			} else {
				this.getRemoteAppData(this.getCallArgs(getAppLangFromUrl(), ids));
			}

		}
	}

	setIsAppAllowedByOkta = (boolean) => {
		this.setState({ connectionAllowedByOkta: boolean });
		this.checkAllowedConnectionByAuth();
	}

	componentDidMount() {
		log('init app');
		const App_ID_From_LocalStorage = JSON.parse(localStorage.getItem("appID"));
		const formatedAppID = App_ID_From_LocalStorage ? App_ID_From_LocalStorage.toString().replace(/"/g, '') : null;

		if (window.location.pathname.split("/").includes('login')) {
			this.getInitialData(this.getCallArgs(getAppLangFromOriginalUri(), true, formatedAppID));
		} else {
			this.getInitialData(this.getCallArgs(getAppLangFromUrl(), true, formatedAppID));
		}

		this.checkAllowedConnectionByAuth()

		if (this.state.connectionAllowed) {
			this.getAppData();
		}

		addInternetConnectionListener(this);
		captureAllClickEvents(this);
	}

	componentWillUpdate() {
		this.setState({ internetIsDisable: !navigator.onLine });
	}

	componentWillUnmount() {
		removeInternetConnectionListener(this);
		removeUserInteractionsListener(this);
	}

	render(_props, state) {
		const appStyle = `${state.colorsStyle} ${state.fontStyle} ${state.radiiStyle}`;
		return (
			<IntlProvider definition={state.i18n}>
				<div id="app" style={appStyle}>
				{this.state.showAppLoader && <AppLoader color={`rgb(${globalVars.color.primaryText})`} />}
					{this.state.connectionAllowed && state.appInfo && state.medias &&
						<Zoom
							ref={c => this.childZoom = c}
							lang={state.appInfo.lang}
							medias={state.medias}
							offer={state.offer}
							showSnackbar={this.onShowSnackbar}
							isMobile={state.isMobile}
						/>
					}
					{this.state.connectionAllowed && !this.state.isContentPreview && state.appInfo && state.medias &&
						<TopBar
							ref={t => this.topbar = t}
							onRefMenuButton={this.refMenuButton}
							onRefLangController={this.refLangController}
							onRefBasket={this.refBasket}
							onRefTitle={this.refTitle}
							setBasketCartState={this.onSetBasketState}
							appInfo={state.appInfo}
							setLang={this.setLang}
							showModal={this.onShowModal}
							changeAppSize={this.setAppFontSize}
							medias={state.medias}
							isMobile={state.isMobile}
							isPreview={state.isPreview}
							internetIsDisable={state.internetIsDisable}
							showSnackbar={this.onShowSnackbar}
							onRefGameSession={this.refGameSession}
							saveStateOfPage={this.onSaveStateOfPage}
							appIntroClosed={state.appIntroModalClosed}
							createModalClosed={this.state.createModalClosed}
							closeCreateModal={this.closeCreateModal}
							newGameSession={this.onNewGameSession}
						/>
					}
					{this.state.appInfo && this.state.appInfo.oktaAuth && this.state.appInfo.oktaAuthInstance && !this.state.isPreview

						? <Security oktaAuth={this.state.appInfo.oktaAuthInstance} restoreOriginalUri={this.state.appInfo.restoreOriginalUri}>

							<Router onChange={this.handleRoute}>

								<LoginCallback path="/login/callback" />

									<Home path="/:lang?"
										appInfo={state.appInfo}
										setIsConnectionAllowedByOkta={this.setIsAppAllowedByOkta}
									/>

									<Stats path="/:lang/stats"
										appInfo={state.appInfo}
										closeModal={this.closeAppIntroModal}
										isMobile={state.isMobile}
										connectionAllowed={this.state.connectionAllowed}
										setIsConnectionAllowedByOkta={this.setIsAppAllowedByOkta}
									/>

									<Page path="/:lang/:pageSlug/:param?/:param2?"
										onRef={this.refPage}
										setBasketCounter={this.onSetBasketCounter}
										setTopbarComponents={this.onSetTopbarComponents}
										internetIsDisable={state.internetIsDisable}
										onToggleZoom={this.onToggleZoom}
										appInfo={state.appInfo}
										isMobile={state.isMobile}
										setBasketCartState={this.onSetBasketState}
										onRefBasketCart={this.getOrCreateRef}
										closeAppIntro={this.closeAppIntroModal}
										appIntroClosed={state.appIntroModalClosed}
										medias={state.medias}
										showSnackbar={this.onShowSnackbar}
										updateGameSessionPercent={this.onUpdateGameSessionPercent}
										changeAppSize={this.setAppFontSize}
										openMenu={this.onOpenMenu}
										openLang={this.onOpenLang}
										openCart={this.onOpenCart}
										toggleMenuModal={this.openGameSessionMenu}
										isPreview={state.isPreview}
										forceOffline={state.appInfo.forceOffline}
										isContentPreview={this.state.isContentPreview}
										connectionAllowed={this.state.connectionAllowed}
										setIsConnectionAllowedByOkta={this.setIsAppAllowedByOkta}
									/>

							</Router>
						</Security>
						: this.state.appInfo && !state.appInfo.oktaAuth &&
						<Router onChange={this.handleRoute}>

							<Home path="/:lang?"
								appInfo={state.appInfo}
								setIsConnectionAllowedByOkta={this.setIsAppAllowedByOkta}
							/>

							<Stats path="/:lang/stats"
								appInfo={state.appInfo}
								closeModal={this.closeAppIntroModal}
								isMobile={state.isMobile}
								connectionAllowed={this.state.connectionAllowed}
								setIsConnectionAllowedByOkta={this.setIsAppAllowedByOkta}
							/>

							<Page path="/:lang/:pageSlug/:param?/:param2?"
								onRef={this.refPage}
								setBasketCounter={this.onSetBasketCounter}
								setTopbarComponents={this.onSetTopbarComponents}
								internetIsDisable={state.internetIsDisable}
								onToggleZoom={this.onToggleZoom}
								appInfo={state.appInfo}
								isMobile={state.isMobile}
								setBasketCartState={this.onSetBasketState}
								onRefBasketCart={this.getOrCreateRef}
								closeAppIntro={this.closeAppIntroModal}
								appIntroClosed={state.appIntroModalClosed}
								medias={state.medias}
								showSnackbar={this.onShowSnackbar}
								updateGameSessionPercent={this.onUpdateGameSessionPercent}
								changeAppSize={this.setAppFontSize}
								openMenu={this.onOpenMenu}
								openLang={this.onOpenLang}
								openCart={this.onOpenCart}
								toggleMenuModal={this.openGameSessionMenu}
								isPreview={state.isPreview}
								forceOffline={state.appInfo.forceOffline}
								isContentPreview={this.state.isContentPreview}
								connectionAllowed={this.state.connectionAllowed}
								setIsConnectionAllowedByOkta={this.setIsAppAllowedByOkta}
							/>

						</Router>
					}
					{this.state.connectionAllowed && !this.state.isContentPreview && state.appInfo && state.medias && !state.appIntroModalClosed && state.appInfo.pages &&
						<AppIntroModals
							setLang={this.setLang}
							appInfo={state.appInfo}
							closeModal={this.closeAppIntroModal}
							showModal={this.onShowModal}
							medias={state.medias}
						/>
					}
					{!this.state.isContentPreview && this.state.appInfo && this.state.connectionAllowedByOkta && this.state.appInfo.codeAuth && !this.state.appInfo.codeCheck &&
						<AuthenticationCode
							appInfo={state.appInfo} updateAuthentificationCheck={this.updateAuthentificationCheck} connectionAllowed={this.state.connectionAllowed}
						/>
					}
					{!this.state.isContentPreview && this.state.appInfo && this.state.connectionAllowedByOkta && this.state.appInfo.emailAuth && !this.state.appInfo.emailCheck &&
						<AuthenticationMail
							appInfo={state.appInfo} updateAuthentificationCheck={this.updateAuthentificationCheck} connectionAllowed={this.state.connectionAllowed}
						/>
					}
					{!this.state.isContentPreview && this.state.appInfo && this.state.connectionAllowedByOkta &&  this.state.appInfo.geolocAuth && !this.state.appInfo.geolocCheck &&
						<AuthenticationGeoloc
							appInfo={state.appInfo} updateAuthentificationCheck={this.updateAuthentificationCheck} connectionAllowed={this.state.connectionAllowed}
						/>
					}
					{!this.state.isContentPreview && state.appInfo && state.medias && state.appInfo.forceOffline && state.appInfo.pages && // TODO : in v3.x, add fullscreen modal
						<Offline
							currentLang={state.appInfo.lang}
							languages={state.appInfo.allLang}
							medias={state.medias}
							pages={state.appInfo.pages}
						/>
					}
					{this.state.connectionAllowed && !this.state.isContentPreview && state.appInfo && state.medias &&
						<Snackbar
							ref={c => this.snackbar = c}
						/>
					}
				</div>
			</IntlProvider>
		);
	}
}
