import { Component } from 'preact';
import { log, onTwoDigits } from 'Components/common';
import style from './style';

export default class AppTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: props.time,
            hurryUp: false,
            stopped: false
        };
        this.countdown = null;
    }

	/**
	 * Start the countdown
	 */
	startCountdown = () => {
		if (!this.countdown) { // prevent double start if start modal exists but without recursive property
			this.countdown = setInterval(() => {
				this.setState({ time: this.state.time -= 1 });
			}, 1000);
		}
	}

	/**
	 * Stop the countdown
	 */
	stopCountdown = () => {
		clearInterval(this.countdown);
		this.countdown = null;
		this.setState({
			hurryUp: false,
			stopped: true
		});
		return this.state.time;
	}

	componentDidMount() {
		log('init global app timer');
		this.props.appIntroClosed && this.props.createModalClosed && this.startCountdown();
	}

	componentWillReceiveProps(newProps) {
        if (newProps.appIntroClosed && newProps.createModalClosed) {
			this.setState({ stopped : false });
			this.startCountdown();
        }
	}

	componentWillUpdate(_nextProps, nextState) {
		if (nextState.time === 10 && !this.state.stopped) {
			nextState.hurryUp = true;
		}

		if (nextState.time === 0) {
			if (!this.state.stopped) {
                this.props.showAppTimeoutModal();
            }
			clearInterval(this.countdown);
			nextState.hurryUp = false;
			nextState.stopped = true;
		}
	}

	componentWillUnmount() {
		this.stopCountdown();
	}

	render() {
        const hours = Math.floor(this.state.time / 3600);
		const minutes = Math.floor((this.state.time - hours * 3600) / 60);
		const seconds = this.state.time - hours * 3600 - minutes * 60;
		const time = `${hours ? onTwoDigits(hours) + ':' : ''}${onTwoDigits(minutes)}:${onTwoDigits(seconds)}`;

		return (
			this.props.time &&
            <div className={`${style.timer} ${this.state.stopped ? style.stopped : ''} ${hours ? style.wider : ''}`}>
                <h2 className={this.state.hurryUp ? style.hurryUp : ''}>
                    {time}
                </h2>
            </div>
		);
	}
}
